import { ProjectItemViewModel, ReportSectionCode } from '@/interfaces'
import { Text, View } from '@react-pdf/renderer'
import React, { useEffect, useState } from 'react'
import Table from './Table'

interface Props {
  project?: ProjectItemViewModel
  styles?: any
  texts?: any
  setPages?: any
  printSections: number[]
}

const ReportMaterialCompositionPage: React.FC<Props> = ({
  project,
  styles,
  texts,
  setPages,
  printSections
}) => {
  const [page, setPage] = useState<number | undefined>()
  useEffect(() => {
    page && setPages({ [ReportSectionCode.MaterialComposition]: page })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])
  return (
    <View>
      <View render={({ pageNumber }) => setPage(pageNumber)} />
      <Text id="materialcomposition" style={styles.pageTitle}>
        {printSections
          .sort()
          .findIndex(value => value === ReportSectionCode.MaterialComposition) +
          1}
        . Material composition
      </Text>
      <Text style={styles.paragraph}>
        {texts?.report.materialComposition.top}
      </Text>
      <Text style={styles.paragraph}>
        {texts?.report.materialComposition.bottom}
      </Text>
      <Table name="Component weights" description={project?.turbineName} />
    </View>
  )
}
export default ReportMaterialCompositionPage
