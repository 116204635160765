import React from 'react'
import {
  OCol,
  Option,
  ORow,
  OTab,
  OTabs
} from '@dnvgl-onefoundation/onedesign-react'
import { ContentSection, SpinIndicator } from '@/components/layout'
import {
  BalanceOfPlant,
  Input,
  InputValue,
  WindFarmTotalMaterialBreakdown
} from '@/components/helpers'
import { config, helper } from '@/utils'
import { useProjectMaterialBreakdown, useTurbineOptions } from '@/hooks'
import MaterialBreakdown from './MaterialBreakdown'
import { TabIndex } from '@/hooks/useProjectMaterialBreakdown'
import {
  MaterialsBreakdownsViewModel,
  ProjectItemViewModel,
  ProjectType
} from '@/interfaces'

interface Props {
  isUpdating?: boolean
  isLoadingMaterialBreakdown?: boolean
  project: ProjectItemViewModel
  manufacturerOptions?: Option[]
  turbineOptions: Option[]
  turbineMaterialBreakdown?: MaterialsBreakdownsViewModel | null
  onUpdate: (propertyName: string, propertyValue: any) => void
}

const { toHtmlDate } = helper
const { icons, defaultSelectOption } = config

const ProjectMaterialBreakdownSection = ({
  isUpdating,
  isLoadingMaterialBreakdown,
  project,
  turbineOptions,
  turbineMaterialBreakdown,
  onUpdate
}: Props) => {
  const { turbineApplication } = useTurbineOptions()
  const { isLoadingPlantWeights, plantWeights, selectedTab, setSelectedTab } =
    useProjectMaterialBreakdown(
      project.id,
      project.turbineId,
      project.projectType
    )

  const application = turbineApplication?.find(
    i => i.value === project?.turbineApplication
  )?.text

  return (
    <ContentSection
      title="Material breakdown"
      icon={icons.materialBreakdown}
      isLoading={isUpdating}
      underlineTitle>
      <ORow>
        <OCol md="12">
          <OTabs
            className={[
              'w-100',
              'tabs-bar-fix',
              'display-block',
              isUpdating ? 'is-disabled' : undefined
            ].join(' ')}
            selectedTab={selectedTab}
            onTabSelect={id => setSelectedTab(id as TabIndex)}>
            <OTab title="Turbine">
              <h3 className="mt-0">Turbine</h3>
              <ORow>
                {project?.turbineId ? (
                  <OCol md="4">
                    <Input
                      propertyName="turbineId"
                      label="Name"
                      value={project?.turbineId}
                      type="select"
                      options={turbineOptions}
                      disabled={isUpdating}
                      onChange={(n, v) => onUpdate(n, v)}
                    />
                  </OCol>
                ) : (
                  <OCol md="12">
                    <Input
                      propertyName="turbineId"
                      label="Name"
                      type="select"
                      value={0}
                      options={[
                        { ...defaultSelectOption, value: 0 },
                        ...turbineOptions
                      ]}
                      disabled={isUpdating}
                      isValueMissing
                      onChange={(n, v) => onUpdate(n, v)}
                    />
                  </OCol>
                )}
                {!!project?.turbineId && (
                  <>
                    <OCol md="4">
                      <InputValue
                        label="Model"
                        value={project?.turbineModelNumber}
                      />
                    </OCol>
                    <OCol md="4">
                      <InputValue
                        label="Manufacturer"
                        value={project?.turbineManufacturer}
                      />
                    </OCol>
                    <OCol md="4">
                      <Input
                        propertyName="installationDate"
                        label="Installation date"
                        defaultValue={toHtmlDate(
                          `${project?.installationDate}`
                        )}
                        type="date"
                        disabled={isUpdating}
                        onChange={(n, v) => !!v && onUpdate(n, v)}
                      />
                    </OCol>
                    <OCol md="4">
                      <Input
                        propertyName="numberOfTurbines"
                        label="Number of turbines"
                        defaultValue={`${project?.numberOfTurbines}`}
                        type="number"
                        disabled={isUpdating}
                        min={0}
                        onBlur={(n, v) => onUpdate(n, v || 1)}
                      />
                    </OCol>
                    <OCol md="4">
                      <InputValue label="Application" value={application} />
                    </OCol>
                    <OCol md="12" className="border-top mpt-2">
                      <MaterialBreakdown
                        projectType={project?.projectType}
                        turbineId={project?.turbineId}
                        isLoading={isLoadingMaterialBreakdown}
                        data={turbineMaterialBreakdown}
                      />
                    </OCol>
                  </>
                )}
              </ORow>
            </OTab>
            <OTab
              title="Balance of plant"
              disabled={
                !project?.turbineId ||
                project.projectType === ProjectType.OffshoreFloating
              }>
              <h3 className="mt-0">Balance of plant</h3>
              {isLoadingPlantWeights ? (
                <SpinIndicator />
              ) : (
                <BalanceOfPlant data={plantWeights} project={project} />
              )}
            </OTab>
            <OTab
              title="Total wind farm"
              disabled={
                !project?.turbineId ||
                project.projectType === ProjectType.OffshoreFloating
              }>
              <h3 className="mt-0">Total wind farm</h3>
              {isLoadingPlantWeights ? (
                <SpinIndicator />
              ) : (
                <WindFarmTotalMaterialBreakdown
                  layout="vertical"
                  legendPosition="bottom"
                  data={plantWeights?.totalWindFarmWeights}
                  height={400}
                  width={400}
                />
              )}
            </OTab>
          </OTabs>
        </OCol>
      </ORow>
    </ContentSection>
  )
}

export default React.memo(ProjectMaterialBreakdownSection)
