import { useState, useEffect } from 'react'
import {
  showConfirm,
  showError,
  showPrompt,
  showSuccess
} from '@dnvgl-onefoundation/onedesign-react'
import { useNavigate, useParams } from 'react-router-dom'
import { api, config, helper } from '@/utils'
import {
  useLocalStorage,
  useMaterialBreakdown,
  useProjects,
  useScenarioState,
  useScenarioUpdate,
  useTurbineItem
} from '@/hooks'

const { urls, messages } = config
const { isValidGuid, genericErrorHandler, waitaSecond } = helper

const useScenario = () => {
  const { id } = useParams()
  const {
    get: getProjects,
    items: projectItems,
    isLoading: isLoadingProjects,
    isLoaded: isProjectsLoaded
  } = useProjects()
  const navigate = useNavigate()
  const { onPropertyUpdate } = useScenarioUpdate()
  const { turbineItem, get: getTurbineModel } = useTurbineItem()
  const { turbineMaterialBreakdown, get: getMaterialBreakdown } =
    useMaterialBreakdown()
  const {
    isLoading,
    isUpdating,
    isNotFound,
    scenario,
    get: getScenario,
    dispatchNotFound
  } = useScenarioState()
  const [, setScenariosActiveTab] = useLocalStorage<number>('ScenariosPage')
  const [selectedProject, setSelectedProject] = useState<any>(null)

  const allProvidersSelected =
    scenario?.providers?.metal?.provider?.id &&
    scenario?.providers?.blade?.provider?.id &&
    scenario?.providers?.concrete?.provider?.id

  useEffect(() => {
    if (id) getProjects()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    if (id && isProjectsLoaded) {
      if (isValidGuid(id)) getScenario(`${id}`)
      else {
        showError('The scenario ID is invalid!', 'Please check the URL')
        dispatchNotFound()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProjectsLoaded])

  useEffect(() => {
    if (isValidGuid((selectedProject as any)?.turbineId)) {
      getMaterialBreakdown((selectedProject as any)?.turbineId)

      if (selectedProject?.turbineId)
        getTurbineModel(selectedProject?.turbineId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject])

  useEffect(() => {
    if (selectedProject?.id !== scenario?.project?.id && projectItems?.length)
      setSelectedProject(
        projectItems?.find((i: any) => i.id === scenario?.project?.id) || null
      )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scenario, projectItems, isProjectsLoaded])

  const onUpdateSelectedProject = (projectId?: string) =>
    isValidGuid(projectId) && onPropertyUpdate('projectId', projectId, `${id}`)

  const [selectedStep, setSelectedStep] = useLocalStorage(
    `useScenario-selectedStep-${id}`,
    1
  )

  const onRemove = () =>
    showConfirm(
      scenario?.isDraft ? 'Discard draft' : 'Remove scenario',
      `Please confirm removing the "${scenario?.name}" scenario. ${messages.infoWillBeLost}`
    ).then((confirmed: boolean) => {
      if (confirmed)
        api.scenarios
          .delete(`${id}`)
          .then(() => {
            showSuccess('Scenario removed.', scenario?.name)
            waitaSecond(() => navigate(urls.scenarios))
          })
          .catch(genericErrorHandler)
    })

  const onRename = () =>
    showPrompt('Rename', scenario?.name).then(
      i => i && onPropertyUpdate?.('name', i, `${id}`)
    )

  const onLockChange = (isLocked: boolean) =>
    onPropertyUpdate?.('isLocked', isLocked, `${id}`)

  const save = (isDraft = false, isNavigate = false) => {
    waitaSecond(() => {
      onPropertyUpdate?.('isDraft', isDraft, `${id}`)
      if (isNavigate) waitaSecond(() => navigate(urls.scenarios))
    })
  }

  const onStepChanged = (tab: any) => setSelectedStep(tab?.id || 1)

  const onSave = () => save(false, false)

  const onSaveToDrafts = () => {
    save(true, true)
    setScenariosActiveTab(1)
  }

  const onSaveAndClose = () => {
    save(false, true)
    setScenariosActiveTab(0)
  }

  return {
    scenarioId: id,
    scenario,
    projectItems,
    isLoadingProjects,
    selectedProject,
    turbineItem,
    selectedStep,
    isLoading,
    isUpdating,
    isNotFound,
    allProvidersSelected,
    turbineMaterialBreakdown,
    dispatchNotFound,
    onPropertyUpdate,
    onUpdateSelectedProject,
    onRemove,
    onRename,
    onLockChange,
    onStepChanged,
    onSave,
    onSaveToDrafts,
    onSaveAndClose
  }
}

export default useScenario
