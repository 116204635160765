import React from 'react'
import { OTooltip, Placement } from '@dnvgl-onefoundation/onedesign-react'

interface Props {
  message?: string
  className?: string
  placement?: Placement
}

const TooltipHelpText = ({ message, className, placement = 'top' }: Props) =>
  message ? (
    <OTooltip content={message || ''} placement={placement}>
      <i className={`opaque-on-hover fas fa-question-circle ${className}`} />
    </OTooltip>
  ) : null

export default React.memo(TooltipHelpText)
