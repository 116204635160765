import React from 'react'
import { TooltipItem } from 'chart.js'

import { helper } from '@/utils'

import { Bar } from 'react-chartjs-2'

interface Props {
  data?: any
  multiplier?: number
  currencySymbol?: string
  small?: boolean
}

const { formatFinancialShortcut } = helper

const buildLabels = (context: TooltipItem<'bar'>) => {
  const label = context.dataset.label
  const value =
    (context as any).raw?.[0] !== undefined ||
    (context as any).raw?.[1] !== undefined
      ? (context as any).raw[1] - (context as any).raw[0]
      : context.raw
  return `${label}: ${Math.round(value as number)}`
}

const CostCalculationChart = ({
  data,
  multiplier = 1,
  currencySymbol,
  small
}: Props) => {
  const multiplyDataValues = (data: any) => {
    if (
      multiplier !== undefined &&
      multiplier !== 1 &&
      data?.datasets?.length
    ) {
      const clone = JSON.parse(JSON.stringify(data))
      clone.datasets = clone.datasets?.map?.((dataset: any) => {
        dataset.data = dataset.data.map((i: any) =>
          i && typeof i === 'number' ? multiplier * i : i
        )
        return dataset
      })
      return clone
    } else return data
  }

  return data ? (
    <Bar
      className="cost-calculation-chart m-auto w-100"
      height={small ? 600 : 900}
      width={small ? 600 : 900}
      options={
        {
          responsive: false,
          maintainAspectRatio: false,
          indexAxis: 'y',
          plugins: {
            annotation: {
              annotations: {
                lineAtZero: {
                  type: 'line',
                  xMin: 0,
                  xMax: 0,
                  borderColor: 'darkGrey',
                  borderWidth: 1
                }
              }
            },
            tooltip: {
              mode: 'nearest',
              intersect: false,
              position: 'nearest',
              callbacks: {
                label: (context: any) => {
                  if (
                    context.raw === 0 ||
                    (context.raw[0] === 0 && context.raw[1] === 0)
                  )
                    return null

                  return buildLabels(context)
                }
              }
            },
            legend: {
              display: false
            },
            valueLabelPlugin: {
              enabled: true
            }
          },
          scales: {
            x: {
              stacked: true,
              ticks: {
                callback: (value: string) =>
                  value
                    ? `${formatFinancialShortcut(parseInt(value))} ${currencySymbol}`
                    : value // Add currency sign to the values
              }
            },
            y: {
              stacked: true,
              ticks: {
                callback: (i: number) =>
                  data?.labels[i] ? `${data.labels[i]}` : i
              }
            }
          }
        } as any
      }
      data={multiplyDataValues(data)}
    />
  ) : null
}

export default React.memo(CostCalculationChart)
