import { useState, useEffect } from 'react'
import { config, helper, tooltips } from '@/utils'
import { buildDayRates } from '@/utils/calculation'

import useScenarioCostCalculation from './useScenarioCostCalculation'
import { Indicator, TooltipHelpText } from '@/components/helpers'

import { ProjectType } from '@/interfaces'

const { calculations } = config
const { addPluralS, toLocaleDate, isNotNull } = helper

const labels = {
  [ProjectType.Onshore]: [
    'Disassembly cost',
    'Removal cost',
    'Material disposal cost',
    'Blade disposal cost',
    'Foundations removal cost',
    'Cable removal cost',
    'Removal of other on-site constructions',
    'Access roads removal cost',
    'Crane pads removal cost',
    'Reinstate cost',
    'Project overhead cost',
    'Scrap value',
    'Total',
    'Net Total'
  ],
  [ProjectType.OffshoreBottomFixed]: [
    'Dismantling and Transportation',
    'Removal of array cables',
    'Preparation for removal of foundations',
    'Removal of foundations',
    'Removal of export cables',
    'Removal of offshore substation',
    'Overhead costs',
    'Scrap value',
    'Total',
    'Net Total'
  ],
  [ProjectType.OffshoreFloating]: [
    'Removal, Transportation, Disassembly',
    'Removal of array cables',
    'Mooring system and anchor removal',
    'Removal of export cables',
    'Removal of offshore substation',
    'Overhead costs',
    'Scrap value',
    'Total',
    'Net Total'
  ]
}

const getFilterdLabels = (
  projectType: ProjectType,
  includeScrapValue: boolean
) =>
  includeScrapValue
    ? labels[projectType].filter(l => l !== 'Total')
    : labels[projectType]
        .filter(l => l !== 'Scrap value')
        .filter(l => l !== 'Net Total')

const useCostScenarioBreakdown = (
  isSimplifiedView: boolean,
  includeScrapValue = true
) => {
  const {
    isLoading: isCalculationLoading,
    onshoreCalculation,
    offshoreFloatingCalculation,
    offshoreFixedCalculation,
    get: getCalculationData
  } = useScenarioCostCalculation()

  const [costScenarioBreakdownTableItems, setCostScenarioBreakdownTableItems] =
    useState<any[]>([])
  const [costScenarioBreakdownChartData, setCostScenarioBreakdownChartData] =
    useState<any>()
  const [
    costScenarioBreakdownSummaryData,
    setCostScenarioBreakdownSummaryData
  ] = useState<any>()
  const [updatedAt, setUpdatedAt] = useState<string | null>(null)
  const [isManual, setIsManual] = useState<boolean>(false)

  const getCostScenarioBreakdown = (
    scenarioId: string,
    projectType: ProjectType
  ) => {
    setCostScenarioBreakdownTableItems([])
    setCostScenarioBreakdownChartData(null)
    setCostScenarioBreakdownSummaryData(null)
    getCalculationData(scenarioId, projectType)
  }

  const buildOffshoreSectionValues = (title: string, section: any) => {
    const header = {
      text: title,
      value: 'header'
    }

    const subTotal = {
      text: 'Subtotal',
      textNode: (
        <span>
          Subtotal
          <TooltipHelpText
            message={tooltips.TotalsRounding}
            placement="right"
            className="ml-1"
          />
        </span>
      ),
      value: [
        section?.total?.absolute,
        section?.total?.perTurbine,
        section?.total?.perMW
      ],
      isBold: true
    }

    return isSimplifiedView
      ? [header, subTotal]
      : [
          header,
          {
            text: 'Mobilisation',
            value: [
              section?.offshoreCostViewModel?.mobilisation?.absolute,
              section?.offshoreCostViewModel?.mobilisation?.perTurbine,
              section?.offshoreCostViewModel?.mobilisation?.perMW
            ]
          },
          {
            text: 'Demobilisation',
            value: [
              section?.offshoreCostViewModel?.demobilisation?.absolute,
              section?.offshoreCostViewModel?.demobilisation?.perTurbine,
              section?.offshoreCostViewModel?.demobilisation?.perMW
            ]
          },
          {
            textNode: (
              <span>
                Operating duration
                <TooltipHelpText
                  message="This is a reasonable estimate of duration considering uncertainties"
                  placement="right"
                  className="ml-1"
                />
              </span>
            ),
            text: 'Operating duration',
            value: [
              `${
                section?.offshoreCostViewModel?.operatingDuration || 0
              } day${addPluralS(
                section?.offshoreCostViewModel?.operatingDuration || 0
              )}`,
              `${
                section?.offshoreCostViewModel?.operatingDuration || 0
              } day${addPluralS(
                section?.offshoreCostViewModel?.operatingDuration || 0
              )}`,
              `${
                section?.offshoreCostViewModel?.operatingDuration || 0
              } day${addPluralS(
                section?.offshoreCostViewModel?.operatingDuration || 0
              )}`
            ]
          },
          ...buildDayRates(section),
          subTotal
        ]
  }

  const buildOffshoreTotals = (calculation: any, includeScrapValue = false) =>
    [
      {
        text: 'Overhead costs',
        value: 'header'
      },
      {
        text: 'Subtotal',
        textNode: (
          <span>
            Subtotal
            <TooltipHelpText
              message={tooltips.TotalsRounding}
              placement="right"
              className="ml-1"
            />
          </span>
        ),
        value: [
          calculation?.totalOverheadCosts?.absolute,
          calculation?.totalOverheadCosts?.perTurbine,
          calculation?.totalOverheadCosts?.perMW
        ],
        isBold: true
      },
      includeScrapValue
        ? {
            text: 'Scrap value',
            value: 'header'
          }
        : null,
      includeScrapValue
        ? {
            text: 'Metal scrap value',
            value: [
              0 - calculation?.metalScrapValue?.absolute,
              0 - calculation?.metalScrapValue?.perTurbine,
              0 - calculation?.metalScrapValue?.perMW
            ],
            isBold: true
          }
        : null,
      {
        text: 'Total costs',
        value: 'header'
      },
      {
        text: includeScrapValue ? 'Net Total' : 'Total',
        textNode: (
          <span>
            {includeScrapValue ? 'Net Total' : 'Total'}
            <TooltipHelpText
              message={tooltips.TotalsRounding}
              placement="right"
              className="ml-1"
            />
          </span>
        ),
        value: includeScrapValue
          ? [
              calculation?.netTotalCosts?.absolute,
              calculation?.netTotalCosts?.perTurbine,
              calculation?.netTotalCosts?.perMW
            ]
          : [
              calculation?.totalCosts?.absolute,
              calculation?.totalCosts?.perTurbine,
              calculation?.totalCosts?.perMW
            ],
        isBold: true
      }
    ].filter(isNotNull)

  const buildChartItem = (
    projectType: ProjectType,
    label: string,
    value: [number, number] | number,
    bg: string
  ) => {
    const data = getFilterdLabels(projectType, includeScrapValue).map(
      (l: string) => (l === label ? value : [0, 0])
    )

    return {
      label,
      data,
      backgroundColor: bg
    }
  }

  const buildWaterflowCostItems = (projectType: ProjectType, items: any[]) => {
    let sum = 0
    return items?.map?.(item => {
      return buildChartItem(
        projectType,
        item.label,
        [sum, (sum += item.value)],
        item.bg
      )
    })
  }

  // Onshore

  useEffect(() => {
    setIsManual(!!onshoreCalculation?.isManual)

    setCostScenarioBreakdownSummaryData({
      totalCosts: onshoreCalculation?.totalCosts?.absolute,
      totalPerMW: onshoreCalculation?.totalCosts?.perMW,
      totalPerTurbine: onshoreCalculation?.totalCosts?.perTurbine,
      netCosts: onshoreCalculation?.netTotalCosts?.absolute,
      netPerMW: onshoreCalculation?.netTotalCosts?.perMW,
      netPerTurbine: onshoreCalculation?.netTotalCosts?.perTurbine
    })
    setCostScenarioBreakdownTableItems([
      {
        textNode: (
          <>
            <Indicator
              colorCode={calculations.chartColors.disassemblyCost}
              className="mr-2"
            />
            Turbine disassembly cost
          </>
        ),
        text: 'Turbine disassembly cost',
        value: [
          onshoreCalculation?.disassemblyCost?.absolute,
          onshoreCalculation?.disassemblyCost?.perTurbine,
          onshoreCalculation?.disassemblyCost?.perMW
        ]
      },
      {
        textNode: (
          <>
            <Indicator
              colorCode={calculations.chartColors.removalCost}
              className="mr-2"
            />
            Turbine removal cost
          </>
        ),
        text: 'Turbine removal cost',
        value: [
          onshoreCalculation?.removalCost?.absolute,
          onshoreCalculation?.removalCost?.perTurbine,
          onshoreCalculation?.removalCost?.perMW
        ]
      },
      {
        textNode: (
          <>
            <Indicator
              colorCode={calculations.chartColors.materialDisposalCost}
              className="mr-2"
            />
            Material disposal cost
          </>
        ),
        text: 'Material disposal cost',
        value: [
          onshoreCalculation?.materialDisposalCost?.absolute,
          onshoreCalculation?.materialDisposalCost?.perTurbine,
          onshoreCalculation?.materialDisposalCost?.perMW
        ]
      },
      {
        textNode: (
          <>
            <Indicator
              colorCode={calculations.chartColors.bladeDisposalCost}
              className="mr-2"
            />
            Turbine blade disposal cost
          </>
        ),
        text: 'Turbine blade disposal cost',
        value: [
          onshoreCalculation?.bladeDisposalCost?.absolute,
          onshoreCalculation?.bladeDisposalCost?.perTurbine,
          onshoreCalculation?.bladeDisposalCost?.perMW
        ]
      },
      {
        textNode: (
          <>
            <Indicator
              colorCode={
                calculations.chartColors.turbineFoundationsDisposalCost
              }
              className="mr-2"
            />
            Turbine foundations removal cost
          </>
        ),
        text: 'Turbine foundations removal cost',
        value: [
          onshoreCalculation?.turbineFoundationsDisposalCost?.absolute,
          onshoreCalculation?.turbineFoundationsDisposalCost?.perTurbine,
          onshoreCalculation?.turbineFoundationsDisposalCost?.perMW
        ]
      },
      {
        textNode: (
          <>
            <Indicator
              colorCode={calculations.chartColors.cableRemovalCost}
              className="mr-2"
            />
            Cable removal cost
          </>
        ),
        text: 'Cable removal cost',
        value: [
          onshoreCalculation?.cableRemovalCost?.absolute,
          onshoreCalculation?.cableRemovalCost?.perTurbine,
          onshoreCalculation?.cableRemovalCost?.perMW
        ]
      },
      {
        textNode: (
          <>
            <Indicator
              colorCode={
                calculations.chartColors.removalOfOtherOnSiteConstructions
              }
              className="mr-2"
            />
            Removal of other on-site constructions
          </>
        ),
        text: 'Removal of other on-site constructions',
        value: [
          onshoreCalculation?.removalOfOtherOnSiteConstructions?.absolute,
          onshoreCalculation?.removalOfOtherOnSiteConstructions?.perTurbine,
          onshoreCalculation?.removalOfOtherOnSiteConstructions?.perMW
        ]
      },
      {
        textNode: (
          <>
            <Indicator
              colorCode={calculations.chartColors.accessRoadsRemovalCost}
              className="mr-2"
            />
            Access roads removal cost
          </>
        ),
        text: 'Access roads removal cost',
        value: [
          onshoreCalculation?.accessRoadsRemovalCost?.absolute,
          onshoreCalculation?.accessRoadsRemovalCost?.perTurbine,
          onshoreCalculation?.accessRoadsRemovalCost?.perMW
        ]
      },
      {
        textNode: (
          <>
            <Indicator
              colorCode={calculations.chartColors.cranePadsRemovalCost}
              className="mr-2"
            />
            Crane pads removal cost
          </>
        ),
        text: 'Crane pads removal cost',
        value: [
          onshoreCalculation?.cranePadsRemovalCost?.absolute,
          onshoreCalculation?.cranePadsRemovalCost?.perTurbine,
          onshoreCalculation?.cranePadsRemovalCost?.perMW
        ]
      },
      {
        textNode: (
          <>
            <Indicator
              colorCode={calculations.chartColors.reinstateCost}
              className="mr-2"
            />
            Reinstate cost
          </>
        ),
        text: 'Reinstate cost',
        value: [
          onshoreCalculation?.reinstateCost?.absolute,
          onshoreCalculation?.reinstateCost?.perTurbine,
          onshoreCalculation?.reinstateCost?.perMW
        ]
      },
      {
        textNode: (
          <>
            <Indicator
              colorCode={calculations.chartColors.projectOverheadCost}
              className="mr-2"
            />
            Project overhead cost
          </>
        ),
        text: 'Project overhead cost',
        value: [
          onshoreCalculation?.projectOverheadCost?.absolute,
          onshoreCalculation?.projectOverheadCost?.perTurbine,
          onshoreCalculation?.projectOverheadCost?.perMW
        ]
      },
      includeScrapValue
        ? {
            textNode: (
              <>
                <Indicator
                  colorCode={calculations.chartColors.scrap}
                  className="mr-2"
                />
                Turbine metal scrap value
              </>
            ),
            text: 'Turbine metal scrap value',
            value: [
              typeof onshoreCalculation?.metalScrapValue?.absolute === 'number'
                ? 0 - onshoreCalculation?.metalScrapValue?.absolute
                : onshoreCalculation?.metalScrapValue?.absolute,
              typeof onshoreCalculation?.metalScrapValue?.perTurbine ===
              'number'
                ? 0 - onshoreCalculation?.metalScrapValue?.perTurbine
                : onshoreCalculation?.metalScrapValue?.perTurbine,
              typeof onshoreCalculation?.metalScrapValue?.perMW === 'number'
                ? 0 - onshoreCalculation?.metalScrapValue?.perMW
                : onshoreCalculation?.metalScrapValue?.perMW
            ]
          }
        : null,
      {
        textNode: (
          <>
            <Indicator
              colorCode={calculations.chartColors.total}
              className="mr-2"
            />
            <span>
              {includeScrapValue
                ? 'Net Total decommissioning costs'
                : 'Total decommissioning costs'}
              <TooltipHelpText
                message={tooltips.TotalsRounding}
                placement="right"
                className="ml-1"
              />
            </span>
          </>
        ),
        text: includeScrapValue
          ? 'Net Total decommissioning costs'
          : 'Total decommissioning costs',
        value: includeScrapValue
          ? [
              onshoreCalculation?.netTotalCosts?.absolute,
              onshoreCalculation?.netTotalCosts?.perTurbine,
              onshoreCalculation?.netTotalCosts?.perMW
            ]
          : [
              onshoreCalculation?.totalCosts?.absolute,
              onshoreCalculation?.totalCosts?.perTurbine,
              onshoreCalculation?.totalCosts?.perMW
            ],
        isHighlighted: true
      }
    ])

    setCostScenarioBreakdownChartData({
      labels: getFilterdLabels(ProjectType.Onshore, includeScrapValue),
      datasets: [
        ...buildWaterflowCostItems(ProjectType.Onshore, [
          {
            label: 'Disassembly cost',
            value: onshoreCalculation?.disassemblyCost?.absolute,
            bg: calculations.chartColors.disassemblyCost
          },
          {
            label: 'Removal cost',
            value: onshoreCalculation?.removalCost?.absolute,
            bg: calculations.chartColors.removalCost
          },
          {
            label: 'Material disposal cost',
            value: onshoreCalculation?.materialDisposalCost?.absolute,
            bg: calculations.chartColors.materialDisposalCost
          },
          {
            label: 'Blade disposal cost',
            value: onshoreCalculation?.bladeDisposalCost?.absolute,
            bg: calculations.chartColors.bladeDisposalCost
          },
          {
            label: 'Foundations removal cost',
            value: onshoreCalculation?.turbineFoundationsDisposalCost?.absolute,
            bg: calculations.chartColors.turbineFoundationsDisposalCost
          },
          {
            label: 'Cable removal cost',
            value: onshoreCalculation?.cableRemovalCost?.absolute,
            bg: calculations.chartColors.cableRemovalCost
          },
          {
            label: 'Removal of other on-site constructions',
            value:
              onshoreCalculation?.removalOfOtherOnSiteConstructions?.absolute,
            bg: calculations.chartColors.removalOfOtherOnSiteConstructions
          },
          {
            label: 'Access roads removal cost',
            value: onshoreCalculation?.accessRoadsRemovalCost?.absolute,
            bg: calculations.chartColors.accessRoadsRemovalCost
          },
          {
            label: 'Crane pads removal cost',
            value: onshoreCalculation?.cranePadsRemovalCost?.absolute,
            bg: calculations.chartColors.cranePadsRemovalCost
          },
          {
            label: 'Reinstate cost',
            value: onshoreCalculation?.reinstateCost?.absolute,
            bg: calculations.chartColors.reinstateCost
          },
          {
            label: 'Project overhead cost',
            value: onshoreCalculation?.projectOverheadCost?.absolute,
            bg: calculations.chartColors.projectOverheadCost
          }
        ]),
        includeScrapValue
          ? buildChartItem(
              ProjectType.Onshore,
              'Scrap value',
              [
                onshoreCalculation?.netTotalCosts?.absolute || 0,
                onshoreCalculation?.totalCosts?.absolute || 0
              ],
              calculations.chartColors.scrap
            )
          : null,
        buildChartItem(
          ProjectType.Onshore,
          includeScrapValue ? 'Net Total' : 'Total',
          [
            includeScrapValue
              ? onshoreCalculation?.netTotalCosts?.absolute || 0
              : onshoreCalculation?.totalCosts?.absolute || 0,
            0
          ],
          calculations.chartColors.total
        )
      ]?.filter(isNotNull)
    })

    setUpdatedAt(
      onshoreCalculation?.updatedAt
        ? toLocaleDate(`${onshoreCalculation?.updatedAt}`)
        : null
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onshoreCalculation])

  // Offshore Floating

  useEffect(() => {
    setIsManual(!!offshoreFloatingCalculation?.isManual)
    setCostScenarioBreakdownSummaryData({
      totalCosts: offshoreFloatingCalculation?.totalCosts?.absolute,
      totalPerMW: offshoreFloatingCalculation?.totalCosts?.perMW,
      totalPerTurbine: offshoreFloatingCalculation?.totalCosts?.perTurbine,
      netCosts: offshoreFloatingCalculation?.netTotalCosts?.absolute,
      netPerMW: offshoreFloatingCalculation?.netTotalCosts?.perMW,
      netPerTurbine: offshoreFloatingCalculation?.netTotalCosts?.perTurbine
    })
    setCostScenarioBreakdownTableItems([
      ...buildOffshoreSectionValues(
        'Removal, Transportation, Disassembly',
        offshoreFloatingCalculation?.removingTurbineUnitTransportationToPortDissasembly
      ),
      ...buildOffshoreSectionValues(
        'Removal of array cables',
        offshoreFloatingCalculation?.removalOfArrayCables
      ),
      ...buildOffshoreSectionValues(
        'Mooring system and anchor removal',
        offshoreFloatingCalculation?.mooringSystemAndAnchorRemoval
      ),
      ...buildOffshoreSectionValues(
        'Removal of export cables',
        offshoreFloatingCalculation?.removalOfExportCable
      ),
      ...buildOffshoreSectionValues(
        'Removal of offshore substation',
        offshoreFloatingCalculation?.removalOfOffshoreSubstation
      ),
      ...buildOffshoreTotals(offshoreFloatingCalculation, includeScrapValue)
    ])

    setCostScenarioBreakdownChartData({
      labels: getFilterdLabels(ProjectType.OffshoreFloating, includeScrapValue),
      datasets: [
        ...buildWaterflowCostItems(ProjectType.OffshoreFloating, [
          {
            label: 'Removal, Transportation, Disassembly',
            value:
              offshoreFloatingCalculation
                ?.removingTurbineUnitTransportationToPortDissasembly?.total
                ?.absolute,
            bg: calculations.chartColors.removalCost
          },
          {
            label: 'Removal of array cables',
            value:
              offshoreFloatingCalculation?.removalOfArrayCables?.total
                ?.absolute,
            bg: calculations.chartColors.removalOfArrayCables
          },
          {
            label: 'Mooring system and anchor removal',
            value:
              offshoreFloatingCalculation?.mooringSystemAndAnchorRemoval?.total
                ?.absolute,
            bg: calculations.chartColors.bladeDisposalCost
          },
          {
            label: 'Removal of export cables',
            value:
              offshoreFloatingCalculation?.removalOfExportCable?.total
                ?.absolute,
            bg: calculations.chartColors.removalOfExportCable
          },
          {
            label: 'Removal of offshore substation',
            value:
              offshoreFloatingCalculation?.removalOfOffshoreSubstation?.total
                ?.absolute,
            bg: calculations.chartColors.disassembly
          },
          {
            label: 'Overhead costs',
            value: offshoreFloatingCalculation?.totalOverheadCosts?.absolute,
            bg: calculations.chartColors.projectOverheadCost
          }
        ]),
        includeScrapValue
          ? buildChartItem(
              ProjectType.OffshoreFloating,
              'Scrap value',
              [
                offshoreFloatingCalculation?.totalCosts?.absolute || 0,
                offshoreFloatingCalculation?.netTotalCosts?.absolute || 0
              ],
              calculations.chartColors.scrap
            )
          : null,
        buildChartItem(
          ProjectType.OffshoreFloating,
          includeScrapValue ? 'Net Total' : 'Total',
          includeScrapValue
            ? offshoreFloatingCalculation?.netTotalCosts?.absolute || 0
            : offshoreFloatingCalculation?.totalCosts?.absolute || 0,
          calculations.chartColors.total
        )
      ]?.filter(isNotNull)
    })

    setUpdatedAt(
      offshoreFloatingCalculation?.updatedAt
        ? toLocaleDate(`${offshoreFloatingCalculation?.updatedAt}`)
        : null
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offshoreFloatingCalculation])

  // Offshore Fixed

  useEffect(() => {
    setIsManual(!!offshoreFixedCalculation?.isManual)
    setCostScenarioBreakdownSummaryData({
      totalCosts: offshoreFixedCalculation?.totalCosts?.absolute,
      totalPerMW: offshoreFixedCalculation?.totalCosts?.perMW,
      totalPerTurbine: offshoreFixedCalculation?.totalCosts?.perTurbine,
      netCosts: offshoreFixedCalculation?.netTotalCosts?.absolute,
      netPerMW: offshoreFixedCalculation?.netTotalCosts?.perMW,
      netPerTurbine: offshoreFixedCalculation?.netTotalCosts?.perTurbine
    })
    setCostScenarioBreakdownTableItems([
      ...buildOffshoreSectionValues(
        'Dismantling and Transportation',
        offshoreFixedCalculation?.dismantlingAndTransportationOfOffshoreWindTurbines
      ),
      ...buildOffshoreSectionValues(
        'Removal of array cables',
        offshoreFixedCalculation?.removalOfArrayCables
      ),
      ...buildOffshoreSectionValues(
        'Preparation for removal of foundations',
        offshoreFixedCalculation?.preparationForRemovalOfFoundations
      ),
      ...buildOffshoreSectionValues(
        'Removal of foundations',
        offshoreFixedCalculation?.removalOfFoundations
      ),
      ...buildOffshoreSectionValues(
        'Removal of export cables',
        offshoreFixedCalculation?.removalOfExportCable
      ),
      ...buildOffshoreSectionValues(
        'Removal of offshore substation',
        offshoreFixedCalculation?.removalOfOffshoreSubstation
      ),
      ...buildOffshoreTotals(offshoreFixedCalculation, includeScrapValue)
    ])

    setCostScenarioBreakdownChartData({
      labels: getFilterdLabels(
        ProjectType.OffshoreBottomFixed,
        includeScrapValue
      ),
      datasets: [
        ...buildWaterflowCostItems(ProjectType.OffshoreBottomFixed, [
          {
            label: 'Dismantling and Transportation',
            value:
              offshoreFixedCalculation
                ?.dismantlingAndTransportationOfOffshoreWindTurbines?.total
                ?.absolute || 0,
            bg: calculations.chartColors.removalCost
          },
          {
            label: 'Removal of array cables',
            value:
              offshoreFixedCalculation?.removalOfArrayCables?.total?.absolute ||
              0,
            bg: calculations.chartColors.removalOfArrayCables
          },
          {
            label: 'Preparation for removal of foundations',
            value:
              offshoreFixedCalculation?.preparationForRemovalOfFoundations
                ?.total?.absolute || 0,
            bg: calculations.chartColors.bladeDisposalCost
          },
          {
            label: 'Removal of foundations',
            value:
              offshoreFixedCalculation?.removalOfFoundations?.total?.absolute ||
              0,
            bg: calculations.chartColors.cranePadsRemovalCost
          },
          {
            label: 'Removal of export cables',
            value:
              offshoreFixedCalculation?.removalOfExportCable?.total?.absolute ||
              0,
            bg: calculations.chartColors.removalOfExportCable
          },
          {
            label: 'Removal of offshore substation',
            value:
              offshoreFixedCalculation?.removalOfOffshoreSubstation?.total
                ?.absolute || 0,
            bg: calculations.chartColors.disassembly
          },
          {
            label: 'Overhead costs',
            value: offshoreFixedCalculation?.totalOverheadCosts?.absolute || 0,
            bg: calculations.chartColors.projectOverheadCost
          }
        ]),
        includeScrapValue
          ? buildChartItem(
              ProjectType.OffshoreBottomFixed,
              'Scrap value',
              [
                offshoreFixedCalculation?.totalCosts?.absolute || 0,
                offshoreFixedCalculation?.netTotalCosts?.absolute || 0
              ],
              calculations.chartColors.scrap
            )
          : null,
        buildChartItem(
          ProjectType.OffshoreBottomFixed,
          includeScrapValue ? 'Net Total' : 'Total',
          includeScrapValue
            ? offshoreFixedCalculation?.netTotalCosts?.absolute || 0
            : offshoreFixedCalculation?.totalCosts?.absolute || 0,
          calculations.chartColors.total
        )
      ]?.filter(isNotNull)
    })
    setUpdatedAt(
      offshoreFixedCalculation?.updatedAt
        ? toLocaleDate(`${offshoreFixedCalculation?.updatedAt}`)
        : null
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offshoreFixedCalculation])

  return {
    isCalculationLoading,
    costScenarioBreakdownTableItems:
      costScenarioBreakdownTableItems?.filter(isNotNull),
    costScenarioBreakdownChartData,
    updatedAt,
    isManual,
    getCostScenarioBreakdown,
    costScenarioBreakdownSummaryData
  }
}

export default useCostScenarioBreakdown
