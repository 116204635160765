import { useEffect, useState } from 'react'
import InputValue from './InputValue'

import { helper } from '@/utils'
import { LatLng } from '@/interfaces'
import { OCol, ORow } from '@dnvgl-onefoundation/onedesign-react'
import Input from './Input'

const range = {
  lat: {
    min: -90,
    max: 90
  },
  lng: {
    min: -180,
    max: 180
  }
}

const { formatCoordinates } = helper

const getCorrectValue = (value: number, min: number, max: number) => {
  if (value <= min) value = min
  if (value >= max) value = max
  return value
}

interface Props {
  isEditable?: boolean
  lngLat?: LatLng
  onLocationUpdate?: (e: any) => void
}

const CoordinatesValue = ({ isEditable, lngLat, onLocationUpdate }: Props) => {
  const [lng, setLng] = useState(lngLat?.lng ?? 0)
  const [lat, setLat] = useState(lngLat?.lat ?? 0)

  useEffect(() => {
    if (lngLat?.lng) setLng(lngLat?.lng)
    if (lngLat?.lat) setLat(lngLat?.lat)
  }, [lngLat])

  if (!isEditable)
    return <InputValue label="Coordinates" value={formatCoordinates(lngLat)} />

  const onChange = (n: string, v: number) => {
    if (n === 'lng') setLng(getCorrectValue(v, range.lng.min, range.lng.max))
    if (n === 'lat') setLat(getCorrectValue(v, range.lat.min, range.lat.max))
  }

  const onBlur = () => {
    if (!lng || !lat) return
    if (lngLat?.lng !== lng || lngLat?.lat !== lat)
      onLocationUpdate?.({ lngLat: { lng, lat } })
  }

  return (
    <ORow>
      <OCol md="6">
        <Input
          propertyName="lat"
          label="Latitude, °N"
          value={lat}
          type="number"
          onChange={(n, v) => onChange?.(n, parseFloat(v))}
          onBlur={() => onBlur()}
        />
      </OCol>
      <OCol md="6">
        <Input
          propertyName="lng"
          label="Longitude, °E"
          value={lng}
          type="number"
          onChange={(n, v) => onChange?.(n, parseFloat(v))}
          onBlur={() => onBlur()}
        />
      </OCol>
    </ORow>
  )
}

export default CoordinatesValue
