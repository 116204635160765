import {
  HttpMethod,
  CreateUserGroup,
  UpdatePropertyViewModel,
  UpdateUserRole,
  AppUser,
  UserGroupItem,
  UserIdentity,
  UserRole,
  PaginatedListOfProjectItemViewModel,
  ProjectItemViewModel,
  PaginatedListOfTurbineItemViewModel,
  TurbineItemViewModel,
  Manufacturer,
  MaterialsBreakdownsViewModel,
  CountryViewModel,
  CreateProviderViewModel,
  PaginatedListOfProviderItemViewModel,
  ProviderItemViewModel,
  PaginatedListOfScenarioItemViewModel,
  ScenarioItemViewModel,
  CreateScenarioViewModel,
  CircularityRateViewModel,
  ProjectType,
  OnshoreCostCalculationViewModel,
  NotificationViewModel,
  OffshoreFixedCostCalculationViewModel,
  OffshoreFloatingCostCalculationViewModel,
  UpdateOnshoreCostCalculationViewModel,
  SubscriptionTier,
  CostCalculationConfigurationRegisterViewModel,
  ParameterUpdateModel,
  CountryWithConfigurationsViewModel,
  PlantWeightsViewModel,
  ConfigurationType,
  UpdateMaterialsBreakdownViewModel,
  SaveOnboardingModel,
  CurrencyViewModel,
  CostCalculationOnshoreConfigurationRegisterViewModel,
  CostCalculationOffshoreFixedConfigurationRegisterViewModel,
  CostCalculationOffshoreFloatingConfigurationRegisterViewModel,
  CurrencyInformationViewModel,
  SubstationViewModel
} from '@/interfaces'

import config from './config'
import helper from './helper'

const { endpoints: e } = config
const { isValidGuid } = helper

// HINT: To differentiate from human requests that redirects
// to login page instead of returning 401.
const ajaxHeader = {
  'X-Requested-With': 'XMLHttpRequest'
}
const jsonContentType = 'application/json'
const jsonContentTypeHeader = {
  accept: jsonContentType,
  'content-type': jsonContentType,
  ...ajaxHeader
}

const objectToURLParams = (params?: any) => {
  if (!params) return ''
  return (
    '?' +
    Object.keys(params)
      .map(k => `${encodeURIComponent(k)}=${params[k]}`)
      .join('&')
  )
}

const getAntiforgeryHeader = () => {
  const requestVerificationToken =
    document?.cookie
      ?.split('; ')
      ?.find(row => row.startsWith('RequestVerificationToken='))
      ?.split('=')[1] ?? ''

  return { RequestVerificationToken: requestVerificationToken }
}

const fetchFactory = (method: HttpMethod) => {
  const getHeaders = ['POST', 'PUT', 'PATCH', 'DELETE'].find(x => x === method)
    ? (isFormData: boolean) =>
        isFormData
          ? { ...getAntiforgeryHeader(), ...ajaxHeader }
          : { ...getAntiforgeryHeader(), ...jsonContentTypeHeader }
    : (isFormData: boolean) => (isFormData ? ajaxHeader : jsonContentTypeHeader)

  return async function fetch<TInput = any | FormData, TOutput = any>(
    path: string,
    input?: TInput
  ): Promise<TOutput> {
    const isFormData = input instanceof FormData
    const response = await window.fetch(path, {
      method,
      body: isFormData ? input : JSON.stringify(input),
      headers: getHeaders(isFormData)
    })

    const output = response.headers
      .get('content-type')
      ?.includes(jsonContentType)
      ? await response.json()
      : undefined

    return response.ok
      ? output
      : Promise.reject({
          response: {
            status: response.status,
            data:
              output ??
              (response.status === 401 ? undefined : await response.json())
          }
        })
  }
}

const postFiles = (path: string, files: File[], propName = 'files') => {
  const form = new FormData()
  const lenght = files?.length
  for (var i = 0; i < lenght; i++) form.append(propName, files[i])
  return post<FormData, void>(path, form)
}

const get: <TOutput = any>(path: string) => Promise<TOutput> =
    fetchFactory('GET'),
  post = fetchFactory('POST'),
  put = fetchFactory('PUT'),
  patch = fetchFactory('PATCH'),
  del: (path: string) => Promise<void> = fetchFactory('DELETE')

const defineCostCalculationBasePath = (projectType: ProjectType) => {
  let basePath = e.onshoreCostCalculations
  if (projectType === ProjectType.OffshoreBottomFixed)
    basePath = e.offshoreBottomFixedCostCalculations
  if (projectType === ProjectType.OffshoreFloating)
    basePath = e.offshoreFloatingCostCalculations
  return basePath
}

const fetchReadableStream = async (url: string) => {
  const response = await fetch(url)
  if (!response.ok)
    throw new Error(
      `Failed to fetch: ${response.status} ${response.statusText}`
    )

  const readableStream = response.body
  return new Response(readableStream)
}

const api = {
  users: {
    fetchCurrentUser: () => get<AppUser | undefined>(e.currentUser),
    fetchByGroup: (groupId: string | undefined) => {
      const path = groupId
        ? `${e.groups}/GetUsers/${groupId}/Users`
        : `${e.userAdministration}/UnassignedUsers`
      return get<UserIdentity[]>(path)
    },
    fetchNotificationsCount: () => get<number>(`${e.my}/messages/count`)
  },
  userAdministration: {
    fetchRoles: () => get<UserRole[]>(`${e.userAdministration}/Roles`),
    patchRole: (payload: UpdateUserRole) =>
      patch<UpdateUserRole, void>(`${e.userAdministration}/Role`, payload),
    findVeracityUser: (email: string) =>
      post<string, UserIdentity[]>(
        `${e.userAdministration}/FindVeracityUser`,
        email
      ),
    addVeracityUser: (userId: string) =>
      post<void, void>(`${e.userAdministration}/AddVeracityUser/${userId}`),
    inviteUser: (email: string) =>
      post<string, string>(`${e.userAdministration}/InviteUser`, email),
    fetchInternalUsers: () =>
      get<UserIdentity[]>(`${e.userAdministration}/InternalUsers`)
  },
  groups: {
    getAll: () => get<UserGroupItem[]>(`${e.groups}/GetUserGroups`),
    getGroupUsers: (groupId: string) =>
      get<AppUser[]>(`${e.groups}/GetUsers/${groupId}/Users`),
    post: (payload: CreateUserGroup) =>
      post<CreateUserGroup, string>(`${e.groups}/Add`, payload),
    addUser: (groupId: string, userId: string) =>
      put<void, void>(`${e.groups}/AddUser/${groupId}/Users/${userId}`),
    addUsers: (groupId: string, userIds: string[]) =>
      put<string[], void>(`${e.groups}/AddUsers/${groupId}/Users`, userIds),
    inviteUser: (groupId: string, email: string) =>
      put<void, void>(`${e.groups}/AddUser/${groupId}/Users/${email}`),
    deleteUser: (groupId: string, userId: string) =>
      del(`${e.groups}/DeleteUser/${groupId}/Users/${userId}`),
    setSubscriptionTier: (
      groupId: string,
      userId: string,
      tier: SubscriptionTier
    ) => patch(`${e.groups}/SetSubscription/${groupId}/Users/${userId}/${tier}`)
  },
  groupsAdministration: {
    patch: (groupId: string, payload: UpdatePropertyViewModel) =>
      patch<UpdatePropertyViewModel, UserGroupItem>(
        `${e.groupsAdmin}/${groupId}`,
        payload
      )
  },
  projects: {
    readAll: () => get<PaginatedListOfProjectItemViewModel>(e.projects),
    read: (id: string) => get<ProjectItemViewModel>(`${e.projects}/${id}`),
    create: (payload: any) => post(e.projects, payload),
    update: (
      id: string,
      { propertyName, propertyValue }: UpdatePropertyViewModel
    ) =>
      patch(`${e.projects}/${id}`, {
        [propertyName]: propertyValue
      }),
    delete: (id: string) => del(`${e.projects}/${id}`),
    getSubstations: (id: string) =>
      get<SubstationViewModel>(`${e.projects}/${id}/substations`),
    updateSubstations: (
      id: string,
      type: string,
      { propertyName, propertyValue }: UpdatePropertyViewModel,
      offshoreType?: string
    ) =>
      patch<SubstationViewModel>(`${e.projects}/${id}/substations`, {
        [type]: !!offshoreType
          ? { [offshoreType]: { [propertyName]: propertyValue } }
          : { [propertyName]: propertyValue }
      })
  },
  providers: {
    readAll: () => get<PaginatedListOfProviderItemViewModel>(e.providers),
    read: (id: string) => get<ProviderItemViewModel>(`${e.providers}/${id}`),
    create: (payload: CreateProviderViewModel) => post(e.providers, payload),
    update: (
      id: string,
      { propertyName, propertyValue }: UpdatePropertyViewModel
    ) =>
      patch(`${e.providers}/${id}`, {
        [propertyName]: propertyValue
      }),
    delete: (id: string) => del(`${e.providers}/${id}`),
    upload: (file: File) =>
      postFiles(`${e.providers}/Upload`, [file], 'FormFile')
  },
  scenarios: {
    readAll: () => get<PaginatedListOfScenarioItemViewModel>(e.scenarios),
    read: (id: string) => get<ScenarioItemViewModel>(`${e.scenarios}/${id}`),
    create: (payload: CreateScenarioViewModel) => post(e.scenarios, payload),
    updateProperty: (
      id: string,
      { propertyName, propertyValue }: UpdatePropertyViewModel
    ) =>
      patch(`${e.scenarios}/${id}`, {
        [propertyName]: propertyValue
      }),
    updateMultipleProperties: (id: string, payload: any) =>
      patch(`${e.scenarios}/${id}`, payload),
    delete: (id: string) => del(`${e.scenarios}/${id}`)
  },
  costCalculations: {
    read: (scenarioId: string, projectType: ProjectType) =>
      get<OnshoreCostCalculationViewModel>(
        [defineCostCalculationBasePath(projectType), 'Get', scenarioId].join(
          '/'
        )
      ),
    update: (
      scenarioId: string,
      projectType: ProjectType,
      payload:
        | UpdateOnshoreCostCalculationViewModel
        | OffshoreFixedCostCalculationViewModel
        | OffshoreFloatingCostCalculationViewModel
    ) =>
      put(
        [defineCostCalculationBasePath(projectType), 'Update', scenarioId].join(
          '/'
        ),
        payload
      ),
    delete: (scenarioId: string, projectType: ProjectType) =>
      del(
        [defineCostCalculationBasePath(projectType), 'Delete', scenarioId].join(
          '/'
        )
      )
  },
  turbines: {
    readAll: () => get<PaginatedListOfTurbineItemViewModel>(e.turbines),
    getUserTurbines: () => get<TurbineItemViewModel[]>(e.userTurbines),
    readAllbyManufacturer: (Manufacturer: Manufacturer) =>
      get<TurbineItemViewModel[]>(
        `${e.turbines}/GetTurbineModelsByManufacturer/${Manufacturer}`
      ),
    read: (id: string) => get<TurbineItemViewModel>(`${e.turbines}/${id}`),
    readMaterialBreakdown: (id: string) =>
      get<MaterialsBreakdownsViewModel>(`${e.turbines}/${id}/Breakdown`),
    create: (payload: any) =>
      post<any, string>([e.turbines, 'Create'].join('/'), payload),
    update: (
      id: string,
      { propertyName, propertyValue }: UpdatePropertyViewModel
    ) =>
      patch(`${e.turbines}/${id}`, {
        [propertyName]: propertyValue
      }),
    updateMaterialBreakdown: (
      turbineId: string,
      payload: UpdateMaterialsBreakdownViewModel
    ) => patch([e.turbines, turbineId, 'Breakdown'].join('/'), payload),
    delete: (id: string) => del(`${e.turbines}/${id}`),
    upload: (file: File, params?: any) =>
      postFiles(
        `${e.turbines}/Upload${objectToURLParams(params)}`,
        [file],
        'FormFile'
      )
  },
  circularity: {
    readScenarioCircularityRate: (scenarioId: string) =>
      get<CircularityRateViewModel>(
        [e.circularity, 'GetWithTotalWeights', scenarioId].join('/')
      ),
    readTurbineRateForDetails: (turbineId: string) =>
      get<CircularityRateViewModel>(
        [e.circularity, 'GetTurbineRateWithoutBlades', turbineId].join('/')
      ),
    readTurbineRateForScenario: (scenarioId: string) =>
      get<CircularityRateViewModel>(
        [e.circularity, 'GetTurbineRateForScenario', scenarioId].join('/')
      ),
    readPlantWeightsForProject: (projectId: string) =>
      get<PlantWeightsViewModel>(
        [e.circularity, 'GetPlantWeights', projectId].join('/')
      )
  },
  teamsNotifications: {
    notify: (message: string, url?: string) =>
      post<NotificationViewModel>(`${e.teamsNotifications}/Notify`, {
        message,
        url
      })
  },
  configurations: {
    readCountries: () =>
      get<CountryWithConfigurationsViewModel[]>(
        [e.configurations, 'countries'].join('/')
      ),
    readGlobal: () =>
      get<CostCalculationConfigurationRegisterViewModel>(
        [e.configurations, 'default'].join('/')
      ),
    read: (id: string, type: ConfigurationType) =>
      get<CostCalculationConfigurationRegisterViewModel>(
        [e.configurations, type, id, 'configurationRegister'].join('/')
      ),
    readScenarioCostConfiguration: (scenarioId?: string) =>
      get<
        | CostCalculationOnshoreConfigurationRegisterViewModel
        | CostCalculationOffshoreFixedConfigurationRegisterViewModel
        | CostCalculationOffshoreFloatingConfigurationRegisterViewModel
      >(
        [
          e.configurations,
          'scenario',
          scenarioId,
          'configurationRegister'
        ].join('/')
      ),
    updateConfigurationParameters: (
      id: string | null,
      type: ConfigurationType | null,
      payload: ParameterUpdateModel[]
    ) => {
      const path =
        id !== null && isValidGuid(id)
          ? [e.configurations, type, id, 'configurationSet']
          : [e.configurations, 'default']

      return patch(path.join('/'), payload)
    },
    reset: (id: string, type: ConfigurationType) =>
      patch([e.configurations, type, id, 'revertToDefaults'].join('/'))
  },
  countries: {
    readAll: () => get<CountryViewModel[]>(e.countries)
  },
  currencies: {
    getAll: () => get<CurrencyViewModel[]>(e.currencies),
    getInfo: (id: string) =>
      get<CurrencyInformationViewModel>([e.scenarioCurrency, id].join('/'))
  },
  onboarding: {
    status: () =>
      get<boolean>([e.onboarding, 'IsOnboardingSetForCurrentUser'].join('/')),
    submit: (payload: SaveOnboardingModel) =>
      post<any, string>(e.onboarding, payload)
  },
  files: {
    readJson: (name: string) => get([e.files, 'json', `${name}.json`].join('/'))
  },
  mapbox: {
    getGeoCoding: (query: string, limit = 1) =>
      fetchReadableStream(
        [
          config.mapbox.apiUrl,
          'geocoding',
          'v5',
          'mapbox.places',
          `${encodeURIComponent(query)}.json?access_token=${
            config.mapbox.publicAccessToken
          }&limit=${limit}`
        ].join('/')
      )
  }
}

export default api
