import React, { useEffect } from 'react'
import { OCol, FieldType } from '@dnvgl-onefoundation/onedesign-react'
import { useCountries, useProjects } from '@/hooks'
import { ContentSection, Page, SpinIndicator } from '../layout'
import { AddItem, ItemsCollection } from '../helpers'
import { helper, projects, config } from '@/utils'

const { isNotDraft, buildItemLink } = helper
const { projectTypeOptions } = projects
const { defaultSelectOption, urls } = config

const ProjectsPage = () => {
  const { countryOptions, defaultCountry, setDefaultCountry } = useCountries()
  const {
    get: getProjects,
    defaultType,
    isLoading,
    items,
    alreadyAvailableNames,
    addProjectDraft,
    addProject
  } = useProjects()

  useEffect(() => {
    getProjects()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page
      title="Projects"
      actions={
        <AddItem
          type="project"
          disabled={isLoading}
          alreadyAvailableNames={alreadyAvailableNames}
          fields={[
            { heading: 'Name *', id: 'name', isRequired: true },
            {
              heading: 'Type',
              id: 'projectType',
              defaultValue: defaultType,
              type: FieldType.Radio,
              options: projectTypeOptions
            },
            {
              heading: 'Location',
              id: 'countryId',
              defaultValue: defaultCountry,
              type: FieldType.Select,
              options: defaultCountry
                ? countryOptions
                : [defaultSelectOption, ...countryOptions],
              onChange: value => setDefaultCountry(value)
            }
          ]}
          onOK={addProject}
          onCancel={addProjectDraft}
        />
      }>
      <>
        {isLoading ? (
          <OCol md="12">
            <SpinIndicator />
          </OCol>
        ) : (
          <>
            {!items?.length && !isLoading && (
              <ContentSection>
                <OCol md="12" className="text-center m-4">
                  <p>The list is empty.</p>
                </OCol>
              </ContentSection>
            )}
            {!!items?.length && (
              <ItemsCollection
                id="ProjectsPage"
                isLoading={isLoading}
                items={items}
                itemName="project"
                fields={[
                  {
                    name: 'name',
                    heading: 'Project',
                    sortable: true,
                    formatter: (name: string) =>
                      buildItemLink(
                        [
                          urls.projects,
                          items?.find?.((item: any) => item.name === name)?.id
                        ].join('/'),
                        name
                      )
                  },
                  {
                    name: 'projectTypeName',
                    heading: 'Type',
                    sortable: true
                  },
                  {
                    name: 'countryName',
                    heading: 'Location',
                    sortable: true
                  },
                  {
                    name: 'numberOfTurbines',
                    heading: 'Number of turbines',
                    sortable: true
                  },
                  {
                    name: 'turbineName',
                    heading: 'Turbine',
                    sortable: true
                  },
                  {
                    name: 'companyName',
                    heading: 'Company',
                    sortable: true
                  }
                ]}
                tabs={[
                  { title: 'Saved projects', filter: isNotDraft },
                  { title: 'Drafts', filter: (i: any) => i?.isDraft }
                ]}
                filterKeys={[
                  'name',
                  'projectTypeName',
                  'countryName',
                  'turbineType',
                  'companyName'
                ]}
              />
            )}
          </>
        )}
      </>
    </Page>
  )
}

export default React.memo(ProjectsPage)
