import React from 'react'
import { ContentSection } from '@/components/layout'
import {
  Input,
  RadioToggle,
  SuperscriptUnit,
  TooltipHelpText
} from '@/components/helpers'
import { config } from '@/utils'
import { OCol, ORow } from '@dnvgl-onefoundation/onedesign-react'
import {
  OnshoreFoundationSpecificationViewModel,
  ProjectItemViewModel,
  SubstationViewModel
} from '@/interfaces'
import ProjectPageOnshoreSubstation from '@/components/projects/ProjectPageOnshoreSubstation'
import FoundationSpecification from './FoundationSpecification'

interface Props {
  project: ProjectItemViewModel | null
  projectSubstations: SubstationViewModel | null
  onUpdate: (propertyName: string, propertyValue: any) => void
  onUpdateSubstation: (
    propertyName: string,
    propertyValue: any,
    type: string
  ) => void
  onUpdateFoundationSpecification: (
    propertyName?: string,
    propertyValue?: any
  ) => void
  disabled?: boolean
}

const { icons, defaultSelectOption, projects, showNewSubstations } = config

const ProjectPageDetailsOnshore = ({
  project,
  onUpdate,
  projectSubstations,
  onUpdateSubstation,
  onUpdateFoundationSpecification,
  disabled
}: Props) => {
  const onshoreFoundations =
    project?.foundationSpecification as OnshoreFoundationSpecificationViewModel
  return (
    <ContentSection
      title="Site infrastructure"
      padding="0"
      icon={icons.infrastructure}
      contentClassName="p-2"
      underlineTitle>
      <ORow>
        <OCol md="12" className="font-weight-bold">
          Access roads
        </OCol>
        <OCol md="4">
          <Input
            propertyName="accessRoadLengthKm"
            label="Access road length"
            helpText="km"
            type="number"
            defaultValue={project?.accessRoadLengthKm}
            isValueMissing={project?.accessRoadLengthKm === null}
            min={0}
            onBlur={(n, v) => onUpdate(n, v)}
          />
        </OCol>
        <OCol md="4">
          <Input
            propertyName="accessRoadWidthM"
            label="Access road width"
            helpText="m"
            type="number"
            defaultValue={project?.accessRoadWidthM}
            isValueMissing={project?.accessRoadWidthM === null}
            min={0}
            max={100}
            onBlur={(n, v) => onUpdate(n, v)}
          />
        </OCol>
        <OCol md="4">
          <Input
            propertyName="accessRoadDepthM"
            label={
              <>
                Access road depth
                <TooltipHelpText
                  className="ml-1"
                  message="Min: 0.3m, Max: 10m, the standard is usually between 0.3 and 0.5m"
                  placement="left"
                />
              </>
            }
            helpText="m"
            type="number"
            defaultValue={project?.accessRoadDepthM}
            isValueMissing={project?.accessRoadDepthM === null}
            min={0.3}
            max={10}
            onBlur={(n, v) => onUpdate(n, v)}
          />
        </OCol>
        <OCol md="12" className="border-bottom mb-2" />
        <FoundationSpecification
          foundationSpecification={onshoreFoundations}
          isOptional={true}
          onUpdate={onUpdateFoundationSpecification}
          disabled={disabled}>
          <Input
            propertyName="foundationVolume"
            label={
              <>
                Foundation volume
                <TooltipHelpText
                  className="ml-1"
                  message="Foundation volume for a single turbine"
                  placement="left"
                />
              </>
            }
            helpText={(<SuperscriptUnit unit="m" power={3} />) as any}
            type="number"
            defaultValue={onshoreFoundations?.foundationVolume}
            isValueMissing={onshoreFoundations?.foundationVolume === null}
            min={0}
            onBlur={onUpdateFoundationSpecification}
            disabled={disabled}
          />
        </FoundationSpecification>
        <OCol md="12" className="border-bottom mb-2" />
        <OCol md="12" className="font-weight-bold">
          Crane pads
        </OCol>
        <OCol md="4">
          <Input
            propertyName="cranePadLengthM"
            label="Crane pad length"
            helpText="m"
            type="number"
            defaultValue={project?.cranePadLengthM}
            isValueMissing={project?.cranePadLengthM === null}
            min={0}
            max={1000}
            onBlur={(n, v) => onUpdate(n, v)}
          />
        </OCol>
        <OCol md="4">
          <Input
            propertyName="cranePadWidthM"
            label="Crane pad width"
            helpText="m"
            type="number"
            defaultValue={project?.cranePadWidthM}
            isValueMissing={project?.cranePadWidthM === null}
            min={0}
            max={1000}
            onBlur={(n, v) => onUpdate(n, v)}
          />
        </OCol>
        <OCol md="4">
          <Input
            propertyName="cranePadDepthM"
            label={
              <>
                Crane pad depth
                <TooltipHelpText
                  className="ml-1"
                  message="Min: 0.3m, Max: 10m, the standard is usually between 0.3 and 0.5m"
                  placement="left"
                />
              </>
            }
            helpText="m"
            type="number"
            defaultValue={project?.cranePadDepthM}
            isValueMissing={project?.cranePadDepthM === null}
            min={0}
            max={100}
            onBlur={(n, v) => onUpdate(n, v)}
          />
        </OCol>
        <OCol md="12" className="border-bottom mb-2" />
        <OCol md="12" className="font-weight-bold">
          Cables
        </OCol>
        <OCol md="6">
          <Input
            propertyName="arrayCableLengthKm"
            label="Array cable length"
            helpText="km"
            type="number"
            defaultValue={project?.arrayCableLengthKm}
            isValueMissing={project?.arrayCableLengthKm === null}
            min={0}
            onBlur={(n, v) => onUpdate(n, v)}
          />
        </OCol>
        <OCol md="6">
          <Input
            propertyName="exportCableLengthKm"
            label="Export cable length"
            helpText="km"
            type="number"
            defaultValue={project?.exportCableLengthKm}
            isValueMissing={project?.arrayCableLengthKm === null}
            min={0}
            onBlur={(n, v) => onUpdate(n, v)}
          />
        </OCol>
        <OCol md="6">
          <Input
            propertyName="arrayCableSizeKV"
            label="Array cable size"
            helpText="kV"
            type="select"
            value={project?.arrayCableSizeKV}
            options={projects.selectOptions.cableSize}
            onChange={(n, v) => onUpdate(n, parseInt(v))}
          />
        </OCol>
        <OCol md="6">
          <Input
            propertyName="exportCableSizeKV"
            label="Export cable size"
            helpText="kV"
            type="select"
            value={project?.exportCableSizeKV}
            options={projects.selectOptions.cableSize}
            onChange={(n, v) => onUpdate(n, parseInt(v))}
          />
        </OCol>
        <OCol md="6">
          <Input
            propertyName="arrayCableMaterial"
            label="Array cable core material"
            type="select"
            value={project?.arrayCableMaterial}
            options={
              project?.arrayCableMaterial === null
                ? [defaultSelectOption, ...projects.selectOptions.cableMaterial]
                : projects.selectOptions.cableMaterial
            }
            onChange={(n, v) => onUpdate(n, parseInt(v))}
          />
        </OCol>
        <OCol md="6">
          <Input
            propertyName="exportCableMaterial"
            label="Export cable core material"
            type="select"
            value={project?.exportCableMaterial}
            options={
              project?.exportCableMaterial === null
                ? [defaultSelectOption, ...projects.selectOptions.cableMaterial]
                : projects.selectOptions.cableMaterial
            }
            onChange={(n, v) => onUpdate(n, parseInt(v))}
          />
        </OCol>
        <OCol md="12" className="border-bottom mb-2" />
        <OCol md="12" className="font-weight-bold">
          Met Masts
        </OCol>
        <OCol md="6">
          <Input
            propertyName="metMastNumber"
            label="Met mast number"
            type="number"
            defaultValue={project?.metMastNumber}
            isValueMissing={project?.metMastNumber === null}
            min={0}
            max={100}
            onBlur={(n, v) => onUpdate(n, v)}
          />
        </OCol>
        <OCol md="12" className="border-bottom mb-2" />
        <OCol md="12" className="font-weight-bold">
          Substations
        </OCol>
        {showNewSubstations ? (
          <ProjectPageOnshoreSubstation
            project={project}
            onUpdate={onUpdate}
            onUpdateSubstations={onUpdateSubstation}
            projectSubstations={projectSubstations}
          />
        ) : (
          <OCol md="6">
            <RadioToggle
              label="Is there an on-site substation?"
              value={project?.hasOnshoreSubstation}
              onToggle={() =>
                onUpdate('hasOnshoreSubstation', !project?.hasOnshoreSubstation)
              }
            />
          </OCol>
        )}
      </ORow>
    </ContentSection>
  )
}

export default React.memo(ProjectPageDetailsOnshore)
