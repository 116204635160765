import { FoundationSpecificationViewModel } from '@/interfaces'
import { Input, InputValue, RadioToggle } from '../helpers'
import { config, tooltips } from '@/utils'
import { ReactNode, useState } from 'react'
import { OCol } from '@dnvgl-onefoundation/onedesign-react'

interface Props {
  foundationSpecification: FoundationSpecificationViewModel | undefined
  isOptional?: boolean
  children?: ReactNode
  onUpdate?: (
    propertyName?: string,
    propertyValue?: string | number | boolean
  ) => void
  disabled?: boolean
}

const FoundationSpecification = ({
  foundationSpecification,
  isOptional,
  children,
  onUpdate,
  disabled
}: Props) => {
  const [showOptional, setShowOptional] = useState(
    !isOptional || (foundationSpecification?.totalWeight ?? 0) > 0
  )

  return (
    <>
      <OCol md="12" className="font-weight-bold">
        Foundations
      </OCol>
      <OCol md="6">{children}</OCol>
      <OCol md="6">
        {isOptional && (
          <RadioToggle
            label="Input foundation weight"
            value={showOptional}
            onToggle={() => {
              setShowOptional(!showOptional)
              if (showOptional) {
                onUpdate?.()
              }
            }}
            tooltip={tooltips.project.FoundationWeights}
            disabled={disabled}
          />
        )}
        {showOptional && (
          <>
            <Input
              propertyName="steel"
              label="Foundation steel weight"
              helpText="tonnes"
              type="number"
              defaultValue={foundationSpecification?.steel}
              min={0}
              onBlur={(n, v) => onUpdate?.(n, parseFloat(v))}
              disabled={disabled}
            />
            <Input
              propertyName="concrete"
              label="Foundation concrete weight"
              helpText="tonnes"
              defaultValue={foundationSpecification?.concrete}
              type="number"
              min={0}
              onBlur={(n, v) => onUpdate?.(n, parseFloat(v))}
            />
            <Input
              propertyName="other"
              label="Foundation other weight"
              helpText="tonnes"
              defaultValue={foundationSpecification?.other}
              type="number"
              min={0}
              onBlur={(n, v) => onUpdate?.(n, parseInt(v))}
              disabled={disabled}
            />
            <InputValue
              label="Foundation total weight"
              value={
                foundationSpecification?.totalWeight
                  ? `${foundationSpecification?.totalWeight} tonnes`
                  : config.missingValueCharacter
              }
            />
          </>
        )}
      </OCol>
    </>
  )
}

export default FoundationSpecification
