import React, { useState } from 'react'
import {
  OBadge,
  OButton,
  OCol,
  ORow,
  OToggleSwitch,
  OTooltip
} from '@dnvgl-onefoundation/onedesign-react'
import { Link } from 'react-router-dom'
import {
  useIsSuperAdministrator,
  useProtectedContent,
  useTeamsRequest,
  useTurbine,
  useTurbineMaterialBreakdownUpdate,
  useTurbineOptions
} from '@/hooks'
import { ContentSection, Page } from '@/components/layout'
import {
  BreadcrumbsTitle,
  Input,
  ModificationInfo,
  LockButton,
  RadioToggle,
  RequestToTeamsCalculationWrapper,
  SaveButton,
  TooltipHelpText
} from '@/components/helpers'
import { MaterialBreakdown } from '@/components/projects'
import { helper, config } from '@/utils'
import { ApplicationType, ComponentType } from '@/interfaces'

const { addPluralS, toggle, isDnvUser } = helper
const { icons, urls, messages } = config

const TurbinePage = () => {
  const isSuperAdministrator = useIsSuperAdministrator()
  const { currentUser } = useProtectedContent()
  const [
    isEditableMaterialBreakdownAllowed,
    setIsEditableMaterialBreakdownAllowed
  ] = useState<boolean>(true)
  const {
    turbineId,
    isLoading,
    isUpdating,
    isRemoveAllowed,
    turbineItem,
    turbineMaterialBreakdown,
    onUpdate,
    onSave,
    onSaveToDrafts,
    onSaveAndClose,
    onRemove,
    isInternalTurbine
  } = useTurbine()

  const {
    isRequesting: isRequestingMaterialBreakdown,
    timestamp: requestTimestamp,
    sendRequest: requestMaterialBreakdown
  } = useTeamsRequest(`TurbinePage-material-breakdown-request-${turbineId}`)

  const { windClass, bladeSparMaterial, generatorType, turbineApplication } =
    useTurbineOptions()

  const { manufacturers } = useTurbineOptions()
  const {
    isEditableMaterialBreakdown,
    setIsEditableMaterialBreakdown,
    updateTurbineMaterialBreakdown
  } = useTurbineMaterialBreakdownUpdate()

  const removeLabel = turbineItem?.isDraft ? 'Discard draft' : 'Remove turbine'
  const isEditDisabledForExternalUser =
    isInternalTurbine && !isDnvUser(currentUser)
  const showSaveButton =
    (isInternalTurbine && isDnvUser(currentUser)) || !isInternalTurbine

  return (
    <Page
      isLoading={isLoading && !turbineItem}
      title={
        <BreadcrumbsTitle
          disabled={isLoading}
          isDraft={turbineItem?.isDraft}
          title={turbineItem?.name}
          backLink={urls.turbines}
          backName="Turbines"
        />
      }
      isH1Title={false}
      actions={
        !isLoading &&
        !!turbineItem && (
          <>
            {isRemoveAllowed && !turbineItem?.isLocked && (
              <OTooltip
                content={
                  !!turbineItem?.projects?.length
                    ? `The "${removeLabel}" button is currently unavailable as the turbine is actively being used in multiple projects`
                    : removeLabel
                }>
                <OButton
                  onClick={onRemove}
                  iconClass="fas fa-trash text-danger"
                  variant="flat"
                  disabled={isLoading || !!turbineItem?.projects?.length}>
                  {removeLabel}
                </OButton>
              </OTooltip>
            )}
            {showSaveButton && (
              <LockButton
                isLoading={isLoading}
                isLocked={turbineItem?.isLocked}
                onClick={() => onUpdate('isLocked', !turbineItem?.isLocked)}
              />
            )}
            {showSaveButton && !turbineItem?.isLocked && (
              <SaveButton
                onSave={onSave}
                onSaveAndClose={onSaveAndClose}
                onSaveToDrafts={onSaveToDrafts}
              />
            )}
          </>
        )
      }>
      {!!turbineItem && (
        <>
          <ModificationInfo
            createdAt={turbineItem?.createdAt}
            createdBy={turbineItem?.createdBy}
            updatedAt={turbineItem?.updatedAt}
            updatedBy={turbineItem?.updatedBy}
          />
          <ORow>
            <OCol md="6">
              <ContentSection
                title="General"
                icon={icons.info}
                underlineTitle
                isLoading={isUpdating}>
                <ORow>
                  <OCol md="6">
                    <Input
                      propertyName="name"
                      label={
                        <>
                          <span>Name</span>
                          <TooltipHelpText
                            className="ml-1"
                            message={messages.turbineNameHelpText}
                            placement="right"
                          />
                        </>
                      }
                      defaultValue={turbineItem?.name}
                      disabled={isUpdating}
                      onBlur={(n, v) => onUpdate?.(n, v)}
                      showOnlyValue={isEditDisabledForExternalUser}
                    />
                  </OCol>
                  <OCol md="6">
                    <Input
                      propertyName="manufacturer"
                      label="Manufacturer"
                      type="select"
                      value={turbineItem?.manufacturer}
                      options={manufacturers}
                      disabled={isUpdating}
                      onChange={(n, v) => onUpdate(n, parseInt(v))}
                      showOnlyValue={isEditDisabledForExternalUser}
                    />
                  </OCol>
                  <OCol md="6">
                    <Input
                      propertyName="modelNumber"
                      label="Model"
                      defaultValue={turbineItem?.modelNumber}
                      disabled={isUpdating}
                      showOnlyValue={isEditDisabledForExternalUser}
                      onBlur={(n, v) => onUpdate?.(n, v)}
                    />
                  </OCol>
                  <OCol md="6">
                    <RadioToggle
                      label="Application"
                      value={!!turbineItem?.application}
                      yesLabel={turbineApplication[0]?.text}
                      noLabel={turbineApplication[1]?.text}
                      isLabelBold={isEditDisabledForExternalUser}
                      disabled={isEditDisabledForExternalUser}
                      onToggle={(i?: boolean) => {
                        onUpdate(
                          'application',
                          !!i
                            ? ApplicationType.Offshore
                            : ApplicationType.Onshore
                        )
                      }}
                    />
                  </OCol>
                </ORow>
              </ContentSection>
              <ContentSection
                title="Details"
                icon={icons.info}
                underlineTitle
                isLoading={isUpdating}>
                {!!turbineItem && (
                  <ORow>
                    <OCol md="6">
                      <Input
                        propertyName="ratedPower"
                        label="Turbine rated power (MW)"
                        defaultValue={turbineItem?.ratedPower}
                        disabled={isUpdating}
                        type="number"
                        min={0}
                        onBlur={(n, v) => onUpdate?.(n, v)}
                        showOnlyValue={isEditDisabledForExternalUser}
                      />
                    </OCol>
                    <OCol md="6">
                      <Input
                        propertyName="rotorDiameter"
                        label="Rotor diameter (m)"
                        defaultValue={turbineItem?.rotorDiameter}
                        disabled={isUpdating}
                        type="number"
                        min={0}
                        onBlur={(n, v) => onUpdate?.(n, v)}
                        showOnlyValue={isEditDisabledForExternalUser}
                      />
                    </OCol>
                    <OCol md="6">
                      <Input
                        propertyName="hubHeight"
                        label="Hub height (m)"
                        defaultValue={turbineItem?.hubHeight}
                        disabled={isUpdating}
                        type="number"
                        min={0}
                        onBlur={(n, v) => onUpdate?.(n, v)}
                        showOnlyValue={isEditDisabledForExternalUser}
                      />
                    </OCol>
                    <OCol md="6">
                      <Input
                        propertyName="windClass"
                        label="Wind class"
                        value={turbineItem?.windClass}
                        type="select"
                        options={windClass}
                        disabled={isUpdating}
                        onChange={(n, v) => onUpdate(n, parseInt(v))}
                        showOnlyValue={isEditDisabledForExternalUser}
                      />
                    </OCol>
                    <OCol md="6">
                      <Input
                        propertyName="bladeSparMaterial"
                        label={
                          <>
                            <span>Blade spar material</span>
                            <TooltipHelpText
                              className="ml-1"
                              message={messages.bladeSparMaterial}
                              placement="right"
                            />
                          </>
                        }
                        value={turbineItem?.bladeSparMaterial}
                        type="select"
                        options={bladeSparMaterial}
                        disabled={isUpdating}
                        onChange={(n, v) => onUpdate(n, parseInt(v))}
                        showOnlyValue={isEditDisabledForExternalUser}
                      />
                    </OCol>
                    <OCol md="6">
                      <Input
                        propertyName="generatorType"
                        label="Generator type"
                        value={turbineItem?.generatorType}
                        type="select"
                        options={generatorType}
                        disabled={isUpdating}
                        onChange={(n, v) => onUpdate(n, parseInt(v))}
                        showOnlyValue={isEditDisabledForExternalUser}
                      />
                    </OCol>
                  </ORow>
                )}
              </ContentSection>
              {!!turbineItem?.projects?.length && (
                <ContentSection
                  title={`Project${addPluralS(turbineItem?.projects?.length)}`}
                  icon={icons.info}
                  underlineTitle
                  isLoading={isUpdating}>
                  <ORow>
                    <OCol md="12">
                      {turbineItem?.projects?.map(p => (
                        <Link
                          to={`${urls.projects}/${p.id}`}
                          key={p.id}
                          className="mr-1 d-inline-block">
                          <OBadge outline variant="secondary" className="mb-1">
                            {p.name}
                          </OBadge>
                        </Link>
                      ))}
                    </OCol>
                  </ORow>
                </ContentSection>
              )}
            </OCol>
            <OCol md="6">
              <ContentSection>
                <RequestToTeamsCalculationWrapper
                  alwaysDisplayContent
                  isRequesting={isRequestingMaterialBreakdown}
                  isRequested={requestTimestamp}>
                  <OButton
                    iconClass="fal fa-envelope"
                    variant="primary"
                    onClick={() =>
                      requestMaterialBreakdown?.('requested material breakdown')
                    }>
                    Request material breakdown for this turbine
                  </OButton>
                </RequestToTeamsCalculationWrapper>
              </ContentSection>
              <ContentSection
                title="Material Breakdown"
                icon={icons.materialBreakdown}
                actions={
                  isEditableMaterialBreakdownAllowed && (
                    <div className="d-flex">
                      <OToggleSwitch
                        textLocation="hidden"
                        checked={isEditableMaterialBreakdown}
                        onChange={() => setIsEditableMaterialBreakdown(toggle)}
                        className="pointer">
                        <span
                          className={
                            isEditableMaterialBreakdown
                              ? undefined
                              : 'is-dimmed'
                          }>
                          Edit values
                        </span>
                      </OToggleSwitch>
                    </div>
                  )
                }
                underlineTitle>
                <MaterialBreakdown
                  data={turbineMaterialBreakdown}
                  turbineId={`${turbineItem?.id}`}
                  isEditable={isEditableMaterialBreakdown}
                  setIsEditable={i => setIsEditableMaterialBreakdown(!!i)}
                  onUpdate={updateTurbineMaterialBreakdown}
                  onComponentSelected={(type: ComponentType | null) => {
                    setIsEditableMaterialBreakdownAllowed(
                      // Allow updates only for parts (not total) by super administrators
                      (isSuperAdministrator && type !== null) ||
                        (!isInternalTurbine && type !== null)
                    )
                  }}
                />
              </ContentSection>
            </OCol>
          </ORow>
        </>
      )}
    </Page>
  )
}

export default React.memo(TurbinePage)
