import React, { useEffect, useState } from 'react'
import {
  Font,
  Image,
  Page,
  Document,
  StyleSheet,
  Text
} from '@react-pdf/renderer'
import { helper } from '@/utils'
import ReportHeader from './ReportHeader'
import ReportMeta from './ReportMeta'
import ReportIntroductionPage from './ReportIntroductionPage'
import ReportFooter from './ReportFooter'
import ReportDisclaimerPage from './ReportDisclaimerPage'
import ReportProjectSitePage from './ReportProjectSitePage'
import ReportMaterialCompositionPage from './ReportMaterialCompositionPage'
import ReportTurbineDetailsPage from './ReportTurbineDetailsPage'
import ReportMaterialCompositionTablePage from './ReportMaterialCompositionTablePage'
import ReportBalanceOfPlantPage from './ReportBalanceOfPlantPage'
import ReportWindFarmTotalMB from './ReportWindFarmTotalMB'
import ReportMaterialProcessingPage from './ReportMaterialProcessingPage'
import ReportSupplyChainMapPage from './ReportSupplyChainMapPage'
import ReportCircularityPage from './ReportCircularityPage'
import ReportCircularityTablePage from './ReportCircularityTablePage'
import ReportDecommissioningCostsPage from './ReportDecommissioningCostsPage'
import ReportDecommissioningScope from './ReportDecommissioningScope'
import ReportCostAssumptions from './ReportCostAssumptions'
import ReportMethodologyPage from './ReportMethodologyPage'
import ReportCalculationResultsPage from './ReportCalculationResultsPage'
import ReportTableOfContents from './ReportTableOfContents'
import ReportExecutiveSummaryPage from './ReportExecutiveSummaryPage'
import ReportTurbineSvg from './ReportTurbineSvg'
import {
  CircularityRateViewModel,
  ProjectItemViewModel,
  ProjectType,
  ScenarioItemViewModel,
  MaterialsBreakdownsViewModel,
  TocNavItem,
  ReportSectionCode,
  CostCalculationOnshoreConfigurationRegisterViewModel,
  CostCalculationOffshoreFixedConfigurationRegisterViewModel,
  CostCalculationOffshoreFloatingConfigurationRegisterViewModel,
  TotalWindFarmWeightsViewModel
} from '@/interfaces'
import { ProviderInfo } from '@/store/slices/scenarioProviders'

Font.register({
  family: 'Avenir Regular',
  src: '/fonts/avenir-regular-lat-ext.ttf'
})

Font.register({
  family: 'Avenir Bold',
  src: '/fonts/avenir-bold.ttf'
})

Font.register({
  family: 'DNV Display Medium',
  src: '/fonts/dnv-display-medium-webfont.ttf'
})

const styles = StyleSheet.create({
  body: {
    paddingTop: 30,
    paddingLeft: '2cm',
    paddingBottom: 65,
    paddingRight: '2cm',
    fontFamily: 'Avenir Regular'
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
    fontFamily: 'Avenir Bold'
  },
  subTitle: {
    fontSize: 12,
    marginBottom: 18,
    fontFamily: 'Avenir Bold'
  },
  pageTitle: {
    fontSize: 13,
    marginBottom: 18,
    fontFamily: 'Avenir Bold'
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey'
  },
  paragraph: {
    fontSize: 11,
    marginBottom: 10
  },
  paragraphSmall: {
    fontSize: 10,
    marginBottom: 2
  },
  container: {
    display: 'flex',
    flexDirection: 'row'
  },
  halfWidth: {
    width: '50%'
  },
  graphHeader: {
    width: '100%',
    backgroundColor: '#0f204b',
    fontSize: 9,
    marginBottom: 10,
    color: '#fff',
    padding: 4
  }
})

const pageSize = 'A4'

const { formatTonnes } = helper

const buildTableOfContents = (sections: ReportSectionCode[], page?: any) => {
  const items: TocNavItem[] = []
  sections.forEach(section => {
    if (section === ReportSectionCode.ExecutiveSummary)
      items.push({
        title: 'Executive Summary',
        id: 'executiveSummary',
        page: page[ReportSectionCode.ExecutiveSummary]
      })
    if (section === ReportSectionCode.Introduction)
      items.push({
        title: 'Introduction',
        id: 'introduction',
        page: page[ReportSectionCode.Introduction]
      })
    if (section === ReportSectionCode.MaterialComposition)
      items.push({
        title: 'Material composition',
        id: 'materialcomposition',
        page: page[ReportSectionCode.MaterialComposition]
      })
    if (section === ReportSectionCode.BalanceOfPlant)
      items.push({
        title: 'Balance of plant',
        id: 'balanceofplant',
        page: page[ReportSectionCode.BalanceOfPlant]
      })
    if (section === ReportSectionCode.Circularity)
      items.push({
        title: 'Circularity',
        id: 'circularity',
        page: page[ReportSectionCode.Circularity]
      })
    if (section === ReportSectionCode.SupplyChainMap)
      items.push({
        title: 'Supply chain map',
        id: 'supplychainmap',
        page: page[ReportSectionCode.SupplyChainMap]
      })
    if (section === ReportSectionCode.DecommissioningCosts)
      items.push({
        title: 'Decommissioning costs',
        id: 'decommissioningcosts',
        page: page[ReportSectionCode.DecommissioningCosts]
      })
  })
  return items
}

type Props = {
  project?: ProjectItemViewModel
  scenario?: ScenarioItemViewModel
  texts?: any
  turbineDetails?: any
  turbineMaterialBreakdown?: MaterialsBreakdownsViewModel | null
  turbineRatedPower?: number
  scenarioCircularityRate?: CircularityRateViewModel | null
  windfarmMaterialBreakdown?: TotalWindFarmWeightsViewModel
  metalProcessingProvider?: ProviderInfo | null
  bladeProcessingProvider?: ProviderInfo | null
  concreteProcessingProvider?: ProviderInfo | null
  decommissioningScope?: any[]
  scenarioCostCalcationConfiguration:
    | CostCalculationOnshoreConfigurationRegisterViewModel
    | CostCalculationOffshoreFixedConfigurationRegisterViewModel
    | CostCalculationOffshoreFloatingConfigurationRegisterViewModel
    | null
  costScenarioBreakdownTableItems?: any[]
  costScenarioBreakdownSummaryData: any
  printSections?: ReportSectionCode[]
  projectSiteSrc?: string
  matrialCompositionSrc?: string
  supplyChainMapSrc?: string
  calcBreakdownSrc?: string
}

const ReportPDF: React.FC<Props> = ({
  project,
  scenario,
  texts,
  turbineDetails,
  turbineMaterialBreakdown,
  turbineRatedPower,
  scenarioCircularityRate,
  windfarmMaterialBreakdown,
  metalProcessingProvider,
  bladeProcessingProvider,
  concreteProcessingProvider,
  decommissioningScope,
  scenarioCostCalcationConfiguration,
  costScenarioBreakdownTableItems,
  costScenarioBreakdownSummaryData,
  printSections,
  projectSiteSrc,
  matrialCompositionSrc,
  supplyChainMapSrc,
  calcBreakdownSrc
}) => {
  const [tocItems, setTocItems] = useState<TocNavItem[]>([])
  const [pages, setPages] = useState([])

  useEffect(() => {
    setTocItems(buildTableOfContents(printSections || [], pages))
  }, [printSections, pages])

  return (
    <Document>
      {/* Title page */}
      <Page size={pageSize} style={styles.body}>
        <ReportHeader />
        <ReportMeta scenario={scenario} texts={texts} />
        <ReportFooter project={project} />
      </Page>
      <Page size={pageSize} style={styles.body}>
        <ReportTableOfContents items={tocItems} styles={styles} />
        <ReportFooter project={project} />
      </Page>

      {/* Executive Summary page */}
      {printSections?.includes(ReportSectionCode.ExecutiveSummary) && (
        <Page size={pageSize} style={styles.body}>
          <ReportExecutiveSummaryPage
            project={project}
            styles={styles}
            texts={texts}
            setPages={(f: any) => setPages({ ...pages, ...f })}
            turbineRatedPower={turbineRatedPower}
            scenario={scenario}
            scenarioCircularityRate={scenarioCircularityRate}
            printSections={printSections}
            costScenarioBreakdownSummaryData={costScenarioBreakdownSummaryData}
          />
          <ReportFooter project={project} />
        </Page>
      )}

      {/* Introduction page */}
      {printSections?.includes(ReportSectionCode.Introduction) && (
        <Page size={pageSize} style={styles.body}>
          <ReportIntroductionPage
            project={project}
            styles={styles}
            texts={texts}
            setPages={(f: any) => setPages({ ...pages, ...f })}
            printSections={printSections}
          />
          <ReportFooter project={project} />
        </Page>
      )}

      {/* Project site page */}
      {printSections?.includes(ReportSectionCode.Introduction) && (
        <Page size={pageSize} style={styles.body} wrap={false}>
          <ReportProjectSitePage
            project={project}
            scenario={scenario}
            styles={styles}
            texts={texts}
            setPages={(f: any) => setPages({ ...pages, ...f })}
            printSections={printSections}
          />
          {!!projectSiteSrc && (
            <Image
              source={projectSiteSrc}
              style={{
                height: '250px',
                objectFit: 'cover'
              }}
            />
          )}
          <ReportFooter project={project} />
        </Page>
      )}

      {printSections?.includes(ReportSectionCode.MaterialComposition) && (
        <>
          {/* Material composition page */}
          <Page size={pageSize} style={styles.body}>
            <ReportMaterialCompositionPage
              project={project}
              styles={styles}
              texts={texts}
              setPages={(f: any) => setPages({ ...pages, ...f })}
              printSections={printSections}
            />
            <ReportTurbineSvg
              blades={formatTonnes(turbineMaterialBreakdown?.blades?.total)}
              nacelle={formatTonnes(turbineMaterialBreakdown?.nacelle?.total)}
              hub={formatTonnes(turbineMaterialBreakdown?.hub?.total)}
              tower={formatTonnes(turbineMaterialBreakdown?.tower?.total)}
            />
            <ReportFooter project={project} />
          </Page>
          {/* Turbine details page */}
          <Page size={pageSize} style={styles.body}>
            <ReportTurbineDetailsPage
              turbineName={project?.turbineName}
              turbineDetails={turbineDetails}
            />
            <ReportFooter project={project} />
          </Page>
          {/* Material composition table page */}
          <Page size={pageSize} style={styles.body}>
            <ReportMaterialCompositionTablePage
              turbineMaterialBreakdown={turbineMaterialBreakdown}
              turbineName={project?.turbineName}
            />
            {!!matrialCompositionSrc && (
              <Image source={matrialCompositionSrc} />
            )}
            <ReportFooter project={project} />
          </Page>
        </>
      )}

      {/* Balance of plant page */}
      {printSections?.includes(ReportSectionCode.BalanceOfPlant) && (
        <Page size={pageSize} style={styles.body}>
          <ReportBalanceOfPlantPage
            project={project}
            styles={styles}
            texts={texts}
            scenarioCircularityRate={scenarioCircularityRate}
            setPages={(f: any) => setPages({ ...pages, ...f })}
            printSections={printSections}
          />
          <ReportWindFarmTotalMB
            windfarmMaterialBreakdown={windfarmMaterialBreakdown}
            styles={styles}
            texts={texts}
          />
          <ReportFooter project={project} />
        </Page>
      )}

      {/* Circularity page */}
      {printSections?.includes(ReportSectionCode.Circularity) && (
        <>
          <Page size={pageSize} style={styles.body}>
            <Text style={styles.pageTitle}>
              {printSections
                .sort()
                .findIndex(value => value === ReportSectionCode.Circularity) +
                1}
              . Circularity
            </Text>
            <ReportCircularityPage
              styles={styles}
              texts={texts}
              setPages={(f: any) => setPages({ ...pages, ...f })}
              printSections={printSections}
            />
            <ReportFooter project={project} />
          </Page>
          {/* Circularity table page */}
          <Page size={pageSize} style={styles.body}>
            <ReportCircularityTablePage
              project={project}
              scenarioCircularityRate={scenarioCircularityRate}
              styles={styles}
              texts={texts}
            />
            <ReportFooter project={project} />
          </Page>
          <Page size={pageSize} style={styles.body}>
            <ReportMaterialProcessingPage
              metalProcessingProvider={metalProcessingProvider}
              bladeProcessingProvider={bladeProcessingProvider}
              concreteProcessingProvider={concreteProcessingProvider}
              styles={styles}
              texts={texts}
              setPages={(f: any) => setPages({ ...pages, ...f })}
              printSections={printSections}
            />
            <ReportFooter project={project} />
          </Page>
        </>
      )}

      {/* Supply chain map page */}
      {printSections?.includes(ReportSectionCode.SupplyChainMap) && (
        <Page size={pageSize} style={styles.body}>
          <ReportSupplyChainMapPage
            styles={styles}
            texts={texts}
            setPages={(f: any) => setPages({ ...pages, ...f })}
            printSections={printSections}
          />
          {!!supplyChainMapSrc && <Image source={supplyChainMapSrc} />}
          <ReportFooter project={project} />
        </Page>
      )}

      {printSections?.includes(ReportSectionCode.DecommissioningCosts) && (
        <>
          {/* Decommissioning costs page */}
          <Page size={pageSize} style={styles.body}>
            <ReportDecommissioningCostsPage
              project={project}
              includeScrap={scenario?.includeScrapValue}
              styles={styles}
              texts={texts}
              setPages={(f: any) => setPages({ ...pages, ...f })}
              printSections={printSections}
            />
            <ReportDecommissioningScope
              decommissioningScope={decommissioningScope}
            />
            <ReportFooter project={project} />
          </Page>
          {/* Cost assumptions page only  */}
          {!!scenarioCostCalcationConfiguration && (
            <Page size={pageSize} style={styles.body}>
              <ReportCostAssumptions
                projectType={project?.projectType}
                includeScrap={scenario?.includeScrapValue}
                scenarioCostCalcationConfiguration={
                  scenarioCostCalcationConfiguration
                }
                texts={texts}
                styles={styles}
              />
              <ReportFooter project={project} />
            </Page>
          )}
          {/* Methodology page, currently available only for Onshore projects */}
          {project?.projectType === ProjectType.Onshore && (
            <Page size={pageSize} style={styles.body}>
              <ReportMethodologyPage
                projectType={project?.projectType}
                styles={styles}
                texts={texts}
                currency={scenario?.currency}
              />
              <ReportFooter project={project} />
            </Page>
          )}
          {/* Calculation results page */}
          <Page size={pageSize} style={styles.body}>
            <ReportCalculationResultsPage
              costScenarioBreakdownTableItems={costScenarioBreakdownTableItems}
              calcBreakdownSrc={calcBreakdownSrc}
              styles={styles}
              texts={texts}
              currencyInformation={scenario?.currency.currencyInformation}
              scenario={scenario}
            />
            <ReportFooter project={project} />
          </Page>
        </>
      )}

      {/* Disclaimer page */}
      <Page size={pageSize} style={styles.body}>
        <ReportDisclaimerPage styles={styles} texts={texts} />
        <ReportFooter project={project} />
      </Page>
    </Document>
  )
}
export default React.memo(ReportPDF)
