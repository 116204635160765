import { showSuccess } from '@dnvgl-onefoundation/onedesign-react'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { api, helper } from '@/utils'
import {
  Manufacturer,
  PaginatedListOfTurbineItemViewModel,
  MaterialsBreakdownsViewModel,
  TurbineItemViewModel,
  UpdatePropertyViewModel,
  UpdateMaterialsBreakdownWithType
} from '@/interfaces'
import { getErrorHandler } from './users'
import { ActionCreator } from '@/store'

const { isValidGuid } = helper

export interface TurbinesState {
  isLoading: boolean
  isUpdating: boolean
  turbines: PaginatedListOfTurbineItemViewModel | null
  userTurbines: TurbineItemViewModel[] | null
  availableManufacturers: string[]
  manufacturerTurbines: { [key: string]: TurbineItemViewModel[] }
  turbineItem: TurbineItemViewModel | null
  turbineMaterialBreakdown: MaterialsBreakdownsViewModel | null
}

const initialState: TurbinesState = {
  isLoading: false,
  isUpdating: false,
  turbines: null,
  userTurbines: null,
  availableManufacturers: [],
  manufacturerTurbines: {},
  turbineItem: null,
  turbineMaterialBreakdown: null
}

export const turbinesSlice = createSlice({
  name: 'turbines',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setIsUpdating: (state, action: PayloadAction<boolean>) => {
      state.isUpdating = action.payload
    },
    setUserTurbines: (state, action: PayloadAction<TurbineItemViewModel[]>) => {
      state.userTurbines = action.payload
    },
    setTurbines: (
      state,
      action: PayloadAction<PaginatedListOfTurbineItemViewModel | null>
    ) => {
      state.turbines = action.payload

      const items = action?.payload?.items?.length ? action?.payload?.items : []
      const names: string[] = []

      items.forEach(({ isDraft, manufacturerName }) => {
        if (manufacturerName && !isDraft && !names.includes(manufacturerName)) {
          names.push(manufacturerName)
        }
      })

      state.availableManufacturers = names
    },
    setManufacturerTurbines: (state, action: PayloadAction<any>) => {
      state.manufacturerTurbines = {
        ...state.manufacturerTurbines,
        [action.payload?.Manufacturer]: action.payload?.items || []
      }
    },
    setTurbineModel: (
      state,
      action: PayloadAction<TurbineItemViewModel | null>
    ) => {
      state.turbineItem = action.payload
    },
    setTurbineMaterialBreakdown: (
      state,
      action: PayloadAction<MaterialsBreakdownsViewModel | null>
    ) => {
      state.turbineMaterialBreakdown = action.payload
    },
    setTurbineMaterialBreakdownType: (
      state,
      action: PayloadAction<UpdateMaterialsBreakdownWithType>
    ) => {
      const type = action.payload.type
      state.turbineMaterialBreakdown![type] = { ...action.payload.data }
    }
  }
})

export const { actions } = turbinesSlice

export const getTurbines: ActionCreator = () => dispatch => {
  dispatch(actions.setIsLoading(true))
  dispatch(actions.setTurbines(null))
  dispatch(actions.setTurbineModel(null))
  return api.turbines
    .readAll()
    .then(data => dispatch(actions.setTurbines(data || [])))
    .catch(getErrorHandler(dispatch))
    .finally(() => dispatch(actions.setIsLoading(false)))
}

export const getUserTurbines: ActionCreator = () => dispatch => {
  dispatch(actions.setIsLoading(true))
  dispatch(actions.setTurbines(null))
  dispatch(actions.setTurbineModel(null))
  return api.turbines
    .getUserTurbines()
    .then(data => dispatch(actions.setUserTurbines(data || [])))
    .catch(getErrorHandler(dispatch))
    .finally(() => dispatch(actions.setIsLoading(false)))
}

export const getTurbine: ActionCreator = (id: string) => dispatch => {
  dispatch(actions.setIsLoading(true))
  dispatch(actions.setTurbineModel(null))
  return api.turbines
    .read(id)
    .then(data => dispatch(actions.setTurbineModel(data || null)))
    .catch(getErrorHandler(dispatch))
    .finally(() => dispatch(actions.setIsLoading(false)))
}

export const getManufacturerTurbines: ActionCreator =
  (Manufacturer: Manufacturer) => (dispatch, getState) => {
    if (!getState().turbines?.manufacturerTurbines?.[Manufacturer]?.length) {
      dispatch(actions.setIsLoading(true))
      dispatch(actions.setTurbineModel(null))
      return api.turbines
        .readAllbyManufacturer(Manufacturer)
        .then(items =>
          dispatch(actions.setManufacturerTurbines({ Manufacturer, items }))
        )
        .catch(getErrorHandler(dispatch))
        .finally(() => dispatch(actions.setIsLoading(false)))
    }
  }

export const updateProperty =
  (propertyName: string, propertyValue: string | number, turbineId: string) =>
  (dispatch: any) => {
    dispatch(actions.setIsUpdating(true))
    const payload: UpdatePropertyViewModel = { propertyName, propertyValue }
    return api.turbines
      .update(turbineId, payload)
      .then(data => {
        showSuccess('Turbine updated.')
        dispatch(actions.setTurbineModel(data))
      })
      .catch(getErrorHandler(dispatch))
      .finally(() => dispatch(actions.setIsUpdating(false)))
  }

export const getTurbineMaterialBreakdown: ActionCreator =
  (id?: string) => dispatch => {
    if (!isValidGuid(id)) return undefined
    dispatch(actions.setTurbineMaterialBreakdown(null))
    dispatch(actions.setIsUpdating(true))
    return api.turbines
      .readMaterialBreakdown(`${id}`)
      .then(data => dispatch(actions.setTurbineMaterialBreakdown(data || null)))
      .catch(getErrorHandler(dispatch))
      .finally(() => dispatch(actions.setIsUpdating(false)))
  }
