import React, { useEffect } from 'react'
import {
  OButton,
  OCol,
  ORow,
  showInfo
} from '@dnvgl-onefoundation/onedesign-react'
import { useManufacturerOptions, useProject } from '@/hooks'
import { Page, SpinIndicator } from '@/components/layout'
import {
  BreadcrumbsTitle,
  ModificationInfo,
  LockButton,
  PageNotFound,
  SaveButton
} from '@/components/helpers'

import {
  ProjectPageSiteSection,
  ProjectMaterialBreakdownSection,
  ProjectPageDetailsOnshore,
  ProjectPageDetailsOffshoreFixed,
  ProjectPageDetailsOffshoreFloating,
  ProjectPageScenariosSection
} from '@/components/projects'
import { config, helper } from '@/utils'
import { ProjectType } from '@/interfaces'
import { Link } from 'react-router-dom'
import FloatingAlertMessage from '@/components/helpers/FloatingAlertMessage'

const { urls, icons } = config
const { currentDate } = helper

const ProjectPage = () => {
  const {
    isLoading,
    isProjectNotFound,
    isLoadingMaterialBreakdown,
    isUpdating,
    project,
    turbineMaterialBreakdown,
    lngLat,
    turbineOptions,
    projectSubstations,
    addScenario,
    getAllUserTurbines,
    onCountryChange,
    onUpdate,
    onUpdateSubstation,
    onLocationUpdate,
    onRemove,
    onSave,
    onSaveToDrafts,
    onSaveAndClose
  } = useProject()
  const manufacturerOptions = useManufacturerOptions()
  useEffect(() => {
    getAllUserTurbines()
  }, [])
  const onUpdateFoundationSpecification = (
    propertyName?: string,
    propertyValue?: number
  ) => {
    const payload = !propertyName
      ? { steel: 0, concrete: 0, other: 0 }
      : {
          [propertyName]: propertyValue
        }

    onUpdate('foundationSpecification', payload)
  }

  return (
    <Page
      title={
        !isLoading ? (
          <BreadcrumbsTitle
            disabled={isLoading}
            isDraft={project?.isDraft}
            isLocked={project?.isLocked}
            title={project?.name}
            backLink={urls.projects}
            backName="Projects"
          />
        ) : null
      }
      isH1Title={false}
      actions={
        !isLoading &&
        project?.name && (
          <>
            {!project?.isLocked && (
              <>
                <Link to={[urls.settingsProjects, project?.id].join('/')}>
                  <OButton
                    iconClass={icons.calculator}
                    variant="flat"
                    disabled={isLoading}>
                    Open settings
                  </OButton>
                </Link>
                <OButton
                  onClick={onRemove}
                  iconClass={`${icons.delete} text-danger`}
                  variant="flat"
                  disabled={isLoading}>
                  {project?.isDraft ? 'Discard draft' : 'Remove project'}
                </OButton>
              </>
            )}

            <LockButton
              isLoading={isLoading}
              isLocked={project?.isLocked}
              onClick={() => onUpdate('isLocked', !project?.isLocked)}
            />

            {!project?.isLocked && (
              <SaveButton
                onSave={() => {
                  if (project?.turbineId) onSave()
                  else
                    showInfo(
                      project?.name,
                      'Please select a turbine to save the project.'
                    )
                }}
                onSaveAndClose={() => {
                  if (project?.turbineId) onSaveAndClose()
                  else
                    showInfo(
                      project?.name,
                      'Please select a turbine to save the project.'
                    )
                }}
                onSaveToDrafts={onSaveToDrafts}
              />
            )}
          </>
        )
      }>
      {project && (
        <ModificationInfo
          createdAt={project?.createdAt}
          createdBy={project?.createdBy}
          updatedAt={project?.updatedAt}
          updatedBy={project?.updatedBy}
        />
      )}
      <ORow>
        {isLoading ? (
          <OCol md="12">
            <SpinIndicator />
          </OCol>
        ) : (
          <>
            {isProjectNotFound && <PageNotFound />}
            {project?.projectType === ProjectType.OffshoreFloating && (
              <FloatingAlertMessage />
            )}
            <OCol md="6">
              {!!project && (
                <>
                  <ProjectPageSiteSection
                    isEditable={!isLoading}
                    isUpdating={isUpdating}
                    project={project}
                    lngLat={lngLat}
                    onCountryChange={onCountryChange}
                    onUpdate={onUpdate}
                    onLocationUpdate={onLocationUpdate}
                  />
                  {project.projectType === ProjectType.Onshore && (
                    <ProjectPageDetailsOnshore
                      project={project}
                      onUpdate={onUpdate}
                      onUpdateSubstation={onUpdateSubstation}
                      onUpdateFoundationSpecification={
                        onUpdateFoundationSpecification
                      }
                      projectSubstations={projectSubstations}
                      disabled={isUpdating}
                    />
                  )}
                  {project.projectType === ProjectType.OffshoreBottomFixed && (
                    <ProjectPageDetailsOffshoreFixed
                      project={project}
                      onUpdate={onUpdate}
                      onUpdateSubstation={onUpdateSubstation}
                      onUpdateFoundationSpecification={
                        onUpdateFoundationSpecification
                      }
                      projectSubstations={projectSubstations}
                      disabled={isUpdating}
                    />
                  )}
                  {project.projectType === ProjectType.OffshoreFloating && (
                    <ProjectPageDetailsOffshoreFloating
                      project={project}
                      onUpdate={onUpdate}
                      onUpdateSubstation={onUpdateSubstation}
                      projectSubstations={projectSubstations}
                    />
                  )}
                </>
              )}
            </OCol>
            <OCol md="6">
              {!!project && (
                <ProjectMaterialBreakdownSection
                  isUpdating={isUpdating}
                  isLoadingMaterialBreakdown={isLoadingMaterialBreakdown}
                  project={project}
                  manufacturerOptions={manufacturerOptions}
                  turbineOptions={turbineOptions}
                  turbineMaterialBreakdown={turbineMaterialBreakdown}
                  onUpdate={onUpdate}
                />
              )}
              {!!project && !!turbineMaterialBreakdown && !project?.isDraft && (
                <ProjectPageScenariosSection
                  alreadyAvailableNames={project?.scenarios?.map(i => i.name)}
                  scenarios={project?.scenarios}
                  scenarioName={`${project?.name} Scenario [${currentDate}]`}
                  addScenario={addScenario}
                />
              )}
            </OCol>
          </>
        )}
      </ORow>
    </Page>
  )
}

export default React.memo(ProjectPage)
