import React from 'react'
import { OButton, OModal } from '@dnvgl-onefoundation/onedesign-react'
import { config, helper } from '@/utils'
import { useToggle } from '@/hooks'
import { ReportSectionCode } from '@/interfaces'

const { icons } = config
const { print } = window
const { waitaSecond } = helper

const defaultText = 'Print'

interface Props {
  title?: string
  titleText?: string
  iconClass?: string
  okText?: string
  variant?: 'primary' | 'secondary' | 'flat'
  mainSlot?: React.ReactNode
  footerSlot?: React.ReactNode
  printSections?: any[]
  sectionNames?: any
  allSelected?: boolean
  clearAll?: () => void
  selectAll?: () => void
  toggle?: (key: string) => void
  onChange?: (sections?: ReportSectionCode[]) => void
}

const ReportPrint = ({
  title = defaultText,
  titleText = defaultText,
  iconClass = icons.print,
  okText = 'Print report',
  variant = 'flat',
  mainSlot,
  footerSlot,
  printSections,
  sectionNames,
  allSelected,
  clearAll,
  selectAll,
  toggle
}: Props) => {
  const [isVisible, toggleIsVisible] = useToggle()

  const handleOK = () => {
    toggleIsVisible()
    waitaSecond(print)
  }

  return (
    <>
      <OButton
        iconClass={iconClass}
        onClick={toggleIsVisible}
        variant={variant}>
        {title}
      </OButton>
      <OModal
        bodySlot={
          <div>
            <div>
              <p>Please select the sections to be included in the report.</p>
              <div
                className="custom-control custom-checkbox pointer mb-4"
                onClick={selectAll}>
                <input
                  autoComplete="off"
                  className="custom-control-input"
                  type="checkbox"
                  checked={allSelected}
                  onChange={() => null}
                />
                <label className="custom-control-label">Select all</label>
              </div>
              {Object.keys(sectionNames).map((key: string) => (
                <div
                  key={key}
                  className={[
                    'custom-control',
                    'custom-checkbox',
                    'pointer',
                    'mb-2',
                    printSections?.includes(parseInt(key) as ReportSectionCode)
                      ? undefined
                      : 'is-dimmed'
                  ].join(' ')}
                  onClick={() => toggle?.(key)}>
                  <input
                    autoComplete="off"
                    className="custom-control-input"
                    type="checkbox"
                    checked={printSections?.includes(
                      parseInt(key) as ReportSectionCode
                    )}
                    onChange={() => null}
                  />
                  <label className="custom-control-label">
                    {sectionNames[parseInt(key) as ReportSectionCode]}
                  </label>
                </div>
              ))}
              <OButton
                disabled={!printSections?.length}
                variant="flat"
                onClick={clearAll}>
                Clear selection
              </OButton>
            </div>
            {!!mainSlot && (
              <div className="border-top mt-4 pt-2">{mainSlot}</div>
            )}
          </div>
        }
        footerSlot={footerSlot}
        visible={isVisible}
        okText={okText}
        clickOutside={false}
        titleText={titleText}
        onOk={handleOK}
        onCancel={toggleIsVisible}
        onClose={toggleIsVisible}
        okOnly
        className="report-modal"
      />
    </>
  )
}

export default React.memo(ReportPrint)
