import React, { useMemo } from 'react'
import {
  ORow,
  OCol,
  OButton,
  OTabs,
  OTab,
  OToggleSwitch
} from '@dnvgl-onefoundation/onedesign-react'
import { MapBoxWrapper } from '@/components/maps'
import { ProvidersCollection } from '@/components/providers'
import {
  CloseButton,
  InputGroup,
  ProviderPin,
  ProvidersProjectLines,
  TooltipHelpText
} from '@/components/helpers'
import {
  useProviders,
  useScenario,
  useScenarioProviders,
  useSelectProviders
} from '@/hooks'
import { config, helper } from '@/utils'
import SelectProviderControl from './SelectProviderControl'
import { RecycleMethod } from '@/interfaces'

interface Props {
  isNextStepDisabled?: boolean
}

const { icons, missingValueCharacter } = config
const { toYear } = helper

const getButtonVariant = (isActive: boolean) =>
  isActive ? 'secondary' : 'subtle'

const SelectProvidersStep = ({ isNextStepDisabled }: Props) => {
  const { selectedProject, onPropertyUpdate } = useScenario()
  const { allItems, filteredItems } = useProviders(true)
  const {
    scenario,
    filteredProviders,
    activeTab,
    isMapView,
    tabs,
    clickedProvider,
    toggleUseDefaultProviders,
    toggleIsMapView,
    setClickedProvider,
    setMetalProcessingProviderId,
    setBladeProcessingProviderId,
    setConcreteProcessingProviderId,
    setQuery,
    onSelection,
    setActiveTab,
    onProviderSelection
  } = useSelectProviders(allItems, filteredItems, selectedProject)

  const {
    metalProcessingProvider,
    bladeProcessingProvider,
    concreteProcessingProvider
  } = useScenarioProviders(scenario)

  const metalProcessingProviderInfo = useMemo(
    () => scenario?.providers?.metal,
    [scenario]
  )

  const bladeProcessingProviderInfo = useMemo(
    () => scenario?.providers?.blade,
    [scenario]
  )
  const concreteProcessingProviderInfo = useMemo(
    () => scenario?.providers?.concrete,
    [scenario]
  )

  const clickedProviderItem = filteredProviders?.find(
    (i: any) => i.id === clickedProvider
  )

  const updateProvider = (
    propertyName: string,
    payload: { type: RecycleMethod; distance?: number }
  ) => {
    if (payload.type === RecycleMethod.Landfill) {
      onPropertyUpdate(`${propertyName}IsLandfill`, true, scenario?.id ?? '')
      return
    }

    if (payload.type === RecycleMethod.Distance) {
      onPropertyUpdate(
        `${propertyName}Distance`,
        payload.distance || 0,
        scenario?.id ?? ''
      )
    }
  }

  return (
    <ORow>
      <OCol md="9">
        <ORow>
          <OCol
            md="12"
            className="provider-items border border-bottom-0 border-right-0 pb-3 px-2">
            <ORow>
              <OCol md="12" className="mt-2">
                <OTabs
                  selectedTab={activeTab}
                  dropdown={false}
                  onTabSelect={i => {
                    if (i !== undefined) setActiveTab(i)
                    setClickedProvider(null)
                  }}>
                  {tabs.map(tab => (
                    <OTab key={tab} title={tab} />
                  ))}
                </OTabs>
              </OCol>
              <OCol md="4" className="pt-1">
                <InputGroup height="26px" onChange={setQuery} />
              </OCol>
              <OCol md="8" className="text-right mb-1">
                <OButton
                  variant={getButtonVariant(!!isMapView)}
                  onClick={toggleIsMapView}
                  iconClass={icons.providersMap}
                  size="small">
                  Map view
                </OButton>
                <OButton
                  variant={getButtonVariant(!isMapView)}
                  onClick={toggleIsMapView}
                  iconClass="fas fa-list"
                  size="small">
                  List view
                </OButton>
              </OCol>
              {clickedProvider && (
                <div className="provider-info">
                  <div className="text-right">
                    <CloseButton onClick={() => setClickedProvider(null)} />
                  </div>
                  <div>
                    <OButton
                      className="mr-2 w-100"
                      iconClass="fas fa-arrow-right"
                      onClick={() => {
                        onSelection(clickedProviderItem)
                        setClickedProvider(null)
                      }}>
                      Add to selection
                    </OButton>
                  </div>
                  <div className="provider-info-scroll-container">
                    <h2>{clickedProviderItem?.name}</h2>
                    <p>{clickedProviderItem?.countryName}</p>
                    <div>
                      {clickedProviderItem?.providerTypesIcons ||
                        missingValueCharacter}
                    </div>
                    <div className="mt-3">
                      <label>Materials</label>
                      <div>
                        {clickedProviderItem?.materialsList ||
                          missingValueCharacter}
                      </div>
                    </div>
                    <div className="mt-2">
                      <label>Services</label>
                      <div>
                        {clickedProviderItem?.servicesList ||
                          missingValueCharacter}
                      </div>
                    </div>
                    <div className="mt-2">
                      <label>Address</label>
                      <div>
                        {clickedProviderItem?.address || missingValueCharacter}
                      </div>
                    </div>
                    <div className="mt-2">
                      <label>Website</label>
                      <div>
                        <a
                          href={clickedProviderItem?.websiteAddress}
                          rel="noreferrer"
                          target="_blank">
                          {clickedProviderItem?.websiteAddress}
                        </a>
                      </div>
                    </div>
                    <div className="mt-2">
                      <label>Operational since</label>
                      <div>
                        {toYear(clickedProviderItem?.operationalSinceYear) ||
                          missingValueCharacter}
                      </div>
                    </div>
                    <div className="mt-2">
                      <label>Capacity, t/day</label>
                      <div>
                        {clickedProviderItem?.serviceCapacityTDay ||
                          missingValueCharacter}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <OCol md="12">
                <div className="providers-container">
                  {isMapView ? (
                    <MapBoxWrapper height={580}>
                      {filteredProviders?.map((i: any, key: number) => (
                        <ProviderPin
                          key={`${i.id}-${key}`}
                          latitude={i?.location?.latitude}
                          longitude={i?.location?.longitude}
                          iconClass={i?.iconClass}
                          isDraft={i?.isDraft}
                          isActive={i?.id === clickedProvider}
                          color={i?.color}
                          onClick={() => onProviderSelection(i?.id)}
                        />
                      ))}
                      <ProvidersProjectLines
                        selectedProject={selectedProject}
                        metalProcessingProvider={
                          metalProcessingProvider?.provider
                        }
                        bladeProcessingProvider={
                          bladeProcessingProvider?.provider
                        }
                        concreteProcessingProvider={
                          concreteProcessingProvider?.provider
                        }
                        onClick={(providerId: string) =>
                          onProviderSelection(providerId)
                        }
                      />
                    </MapBoxWrapper>
                  ) : (
                    <ProvidersCollection
                      items={filteredProviders}
                      onItemClick={onProviderSelection}
                    />
                  )}
                </div>
              </OCol>
            </ORow>
          </OCol>
        </ORow>
      </OCol>
      {!!scenario?.id && (
        <OCol md="3" className="border border-bottom-0 p-0 m-0">
          <div className="border-bottom bg-light">
            <label className="p-2 pt-3">
              Selected providers
              {isNextStepDisabled && (
                <TooltipHelpText
                  className="ml-2"
                  message="Please select a provider for each process or indicate 'Landfill' if applicable. This step is essential to proceed with defining the scenario's scope."
                />
              )}
            </label>
          </div>
          <SelectProviderControl
            title="Metal processing"
            providerInfo={metalProcessingProviderInfo}
            iconClass={icons.metalProcessingLight}
            isDisabled={scenario?.useDefaultProviders}
            selectButtonVariant={activeTab === 1 ? 'secondary' : 'subtle'}
            onSelectClick={() => setActiveTab(1)}
            onResetClick={() => setMetalProcessingProviderId(null)}
            onChange={payload =>
              updateProvider('metalProcessingProvider', payload)
            }
          />
          <SelectProviderControl
            title="Blade processing"
            providerInfo={bladeProcessingProviderInfo}
            iconClass={icons.bladeProcessingLight}
            isDisabled={scenario?.useDefaultProviders}
            selectButtonVariant={activeTab === 2 ? 'secondary' : 'subtle'}
            onSelectClick={() => setActiveTab(2)}
            onResetClick={() => setBladeProcessingProviderId(null)}
            onChange={payload =>
              updateProvider('bladeProcessingProvider', payload)
            }
          />
          <SelectProviderControl
            title="Concrete processing"
            providerInfo={concreteProcessingProviderInfo}
            iconClass={icons.concreteProcessingLight}
            isDisabled={scenario?.useDefaultProviders}
            selectButtonVariant={activeTab === 3 ? 'secondary' : 'subtle'}
            onSelectClick={() => setActiveTab(3)}
            onResetClick={() => setConcreteProcessingProviderId(null)}
            onChange={payload =>
              updateProvider('concreteProcessingProvider', payload)
            }
          />
          <div className="m-2">
            <p>Not ready to select providers?</p>
            <OToggleSwitch
              textLocation="hidden"
              checked={scenario?.useDefaultProviders}
              onChange={() => toggleUseDefaultProviders()}
              className="pointer">
              Use nearest providers
            </OToggleSwitch>
          </div>
        </OCol>
      )}
    </ORow>
  )
}

export default React.memo(SelectProvidersStep)
