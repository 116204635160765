import React from 'react'
import { OCol } from '@dnvgl-onefoundation/onedesign-react'
import { Input } from '../helpers'
import { ProjectItemViewModel, SubstationWeightsViewModel } from '@/interfaces'

interface Props {
  project?: ProjectItemViewModel | null
  weights?: SubstationWeightsViewModel | null
  onUpdateSubstations: (
    propertyName: string,
    propertyValue: any,
    type: string,
    offshoreType?: string
  ) => void
  offshoreType?: string
}

const ProjectPageSubstationWeights = ({
  weights,
  onUpdateSubstations,
  offshoreType
}: Props) => {
  return (
    <>
      <OCol md="6">
        <div className="font-weight-bold mt-2">
          {!!offshoreType && `Offshore substation ${offshoreType}`}
        </div>
      </OCol>
      <OCol md="6" className="mt-2">
        <Input
          propertyName="steel"
          label="Substation steel weight"
          type="number"
          defaultValue={weights?.steel}
          isValueMissing={weights?.steel === null}
          helpText="tonnes"
          min={0}
          max={1000000}
          onBlur={(n, v) =>
            onUpdateSubstations(
              n,
              v,
              !offshoreType ? 'onshore' : 'offshore',
              offshoreType
            )
          }
        />
        <Input
          propertyName="concrete"
          label="Substation concrete weight"
          type="number"
          defaultValue={weights?.concrete}
          isValueMissing={weights?.concrete === null}
          helpText="tonnes"
          min={0}
          max={1000000}
          onBlur={(n, v) =>
            onUpdateSubstations(
              n,
              v,
              !offshoreType ? 'onshore' : 'offshore',
              offshoreType
            )
          }
        />
        <Input
          propertyName="copper"
          label="Substation copper weight"
          type="number"
          defaultValue={weights?.copper}
          isValueMissing={weights?.copper === null}
          helpText="tonnes"
          min={0}
          max={1000000}
          onBlur={(n, v) =>
            onUpdateSubstations(
              n,
              v,
              !offshoreType ? 'onshore' : 'offshore',
              offshoreType
            )
          }
        />
        <Input
          propertyName="aluminium"
          label="Substation aluminium weight"
          type="number"
          defaultValue={weights?.aluminium}
          isValueMissing={weights?.aluminium === null}
          helpText="tonnes"
          min={0}
          max={1000000}
          onBlur={(n, v) =>
            onUpdateSubstations(
              n,
              v,
              !offshoreType ? 'onshore' : 'offshore',
              offshoreType
            )
          }
        />
        <Input
          propertyName="other"
          label="Substation other weight"
          type="number"
          defaultValue={weights?.other}
          isValueMissing={weights?.other === null}
          helpText="tonnes"
          min={0}
          max={1000000}
          onBlur={(n, v) =>
            onUpdateSubstations(
              n,
              v,
              !offshoreType ? 'onshore' : 'offshore',
              offshoreType
            )
          }
        />
        <label>Substation total weight</label>
        <div className="mt-1 mb-2">
          {(weights?.total && Math.round(weights?.total * 100) / 100) || 0}
          &nbsp;tonnes
        </div>
      </OCol>
    </>
  )
}

export default React.memo(ProjectPageSubstationWeights)
