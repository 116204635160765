import React from 'react'
import { Text } from '@react-pdf/renderer'
import { CurrencyViewModel, ProjectType } from '@/interfaces'
import { helper } from '@/utils'

interface Props {
  projectType?: ProjectType
  styles?: any
  texts?: any
  currency?: CurrencyViewModel
}

const { formatCurrencyMask } = helper
const ReportMethodologyPage: React.FC<Props> = ({
  projectType,
  styles,
  texts,
  currency
}) =>
  // Supporting only Onshore for now
  projectType === ProjectType.Onshore ? (
    <>
      <Text style={styles.subTitle}>Mobilisation and Soft costs</Text>
      {texts?.report.mobilisationAndSoftCosts.Onshore.map((i: any) => (
        <Text key={i} style={styles.paragraph}>
          {i}
        </Text>
      ))}

      <Text style={styles.subTitle}>Turbine Disassembly and Removal costs</Text>
      {texts?.report.turbineDisassemblyAndRemovalCosts.Onshore.map((i: any) => (
        <Text key={i} style={styles.paragraph}>
          {i}
        </Text>
      ))}

      <Text style={styles.subTitle}>Turbine Foundations Removal cost</Text>
      {texts?.report.turbineFoundationsRemovalCost.Onshore.map((i: any) => (
        <Text key={i} style={styles.paragraph}>
          {i}
        </Text>
      ))}

      <Text style={styles.subTitle} break>
        Crane Pad Removal
      </Text>
      {texts?.report.cranePadRemoval.Onshore.map((i: any) => (
        <Text key={i} style={styles.paragraph}>
          {i}
        </Text>
      ))}

      <Text style={styles.subTitle}>Removal of cables</Text>
      {texts?.report.removalOfCables.Onshore.map((i: any) => (
        <Text key={i} style={styles.paragraph}>
          {i}
        </Text>
      ))}

      <Text style={styles.subTitle}>Access road removal</Text>
      {texts?.report.accessRoadRemoval.Onshore.map((i: any) => (
        <Text key={i} style={styles.paragraph}>
          {i}
        </Text>
      ))}

      <Text style={styles.subTitle}>Reinstatement costs</Text>
      {texts?.report.reinstatementCosts.Onshore.map((i: any) => (
        <Text key={i} style={styles.paragraph}>
          {i}
        </Text>
      ))}

      <Text style={styles.subTitle}>Removal of other onsite structures</Text>
      {texts?.report.removalOfOtherOnsiteStructures.Onshore.map((i: any) => (
        <Text key={i} style={styles.paragraph}>
          {i.replace(
            texts?.report.removalOfOtherOnsiteStructures.FixedCost,
            `${formatCurrencyMask(currency?.exchangeRateToDefaultCurrency! * 11450, currency?.currencyInformation!)} + ${formatCurrencyMask(currency?.exchangeRateToDefaultCurrency! * 600, currency?.currencyInformation!)}`
          )}
        </Text>
      ))}
    </>
  ) : null

export default ReportMethodologyPage
