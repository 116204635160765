import React from 'react'
import { OCol } from '@dnvgl-onefoundation/onedesign-react'
import { Input, RadioToggle } from '../helpers'
import { config } from '@/utils'
import {
  ProjectItemViewModel,
  SubstationSubType,
  SubstationViewModel
} from '@/interfaces'
import ProjectPageSubstationWeights from '@/components/projects/ProjectPageSubstationWeights'

interface Props {
  project?: ProjectItemViewModel | null
  projectSubstations: SubstationViewModel | null
  onUpdate: (propertyName: string, propertyValue: any) => void
  onUpdateSubstations: (
    propertyName: string,
    propertyValue: any,
    type: string
  ) => void
}

const { projects } = config

const ProjectPageOffshoreSubstation = ({
  project,
  onUpdate,
  onUpdateSubstations,
  projectSubstations
}: Props) => {
  return (
    <>
      <OCol md="6" className="mt-2">
        <RadioToggle
          label="Offshore substation"
          value={project?.hasOffshoreSubstation}
          onToggle={() => {
            onUpdate('hasOffshoreSubstation', !project?.hasOffshoreSubstation)
          }}
        />
        {project?.hasOffshoreSubstation && (
          <RadioToggle
            className="mt-4"
            label="Offshore substation type"
            value={
              projectSubstations?.offshore?.subType ===
                SubstationSubType.Hvac ||
              projectSubstations?.offshore?.subType === undefined
            }
            noLabel={SubstationSubType[SubstationSubType.Hvac].toUpperCase()}
            yesLabel={SubstationSubType[SubstationSubType.Hvdc].toUpperCase()}
            onToggle={() => {
              onUpdateSubstations(
                'subType',
                projectSubstations?.offshore?.subType === SubstationSubType.Hvac
                  ? SubstationSubType.Hvdc
                  : SubstationSubType.Hvac,
                'offshore'
              )
            }}
          />
        )}
      </OCol>
      {project?.hasOffshoreSubstation && project.projectType ? (
        <>
          <OCol md="6" className="mt-2">
            <Input
              propertyName="count"
              label="Number of offshore substations"
              type="select"
              defaultValue={1}
              value={projectSubstations?.offshore?.count}
              options={projects.selectOptions.numberOfSubstations}
              onChange={(n, v) => onUpdateSubstations(n, v, 'offshore')}
            />
          </OCol>

          <ProjectPageSubstationWeights
            project={project}
            onUpdateSubstations={onUpdateSubstations}
            offshoreType="foundation"
            weights={projectSubstations?.offshore?.foundation}
          />
          <ProjectPageSubstationWeights
            project={project}
            onUpdateSubstations={onUpdateSubstations}
            offshoreType="topside"
            weights={projectSubstations?.offshore?.topSide}
          />
        </>
      ) : (
        <OCol md="6" />
      )}
    </>
  )
}

export default React.memo(ProjectPageOffshoreSubstation)
