import React, { ReactNode } from 'react'
import { ORow, OCol } from '@dnvgl-onefoundation/onedesign-react'
import ContentSection from './ContentSection'
import SpinIndicator from './SpinIndicator'

interface PageProps {
  title?: ReactNode
  actions?: ReactNode
  disabled?: boolean
  isFullWidthTitle?: boolean
  isH1Title?: boolean
  isLoading?: boolean
  className?: string
  showUnderConstruction?: boolean
  children?: ReactNode
}

const Page = ({
  title,
  actions,
  disabled,
  isFullWidthTitle,
  isH1Title = true,
  isLoading,
  className,
  showUnderConstruction,
  children
}: PageProps) => (
  <ORow className={className}>
    {!!title && (
      <OCol col={isFullWidthTitle ? '12' : '5'}>
        {isH1Title ? (
          <h1>{title}</h1>
        ) : (
          <div className="d-flex align-items-center w-100">{title}</div>
        )}
      </OCol>
    )}
    {!!actions && (
      <OCol col={title ? '7' : '12'}>
        <div className="mt-3 d-flex align-items-center float-right no-print ">
          {actions}
        </div>
      </OCol>
    )}
    <OCol col="12">
      <div style={{ minHeight: '3px' }}>{isLoading && <SpinIndicator />}</div>
    </OCol>
    <OCol col="12" className={disabled ? 'is-disabled' : ''}>
      {showUnderConstruction ? (
        <ContentSection title="Under Construction" icon="fal fa-tools">
          <p>This page is not implemented yet.</p>
        </ContentSection>
      ) : (
        children
      )}
    </OCol>
  </ORow>
)

export default React.memo(Page)
