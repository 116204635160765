import React, { ReactNode } from 'react'
import { ORow, OCol, OToggleSwitch } from '@dnvgl-onefoundation/onedesign-react'
import { ContentSection } from '../layout'
import { helper } from '@/utils'
import {
  DecommissionOnshoreScopeViewModel,
  ScenarioItemViewModel
} from '@/interfaces'
import { useCostScenarioBreakdown, useScenarioState } from '@/hooks'

interface Props {
  scenario?: ScenarioItemViewModel | null
  children?: ReactNode
  onScopeUpdate?: (name: string, value?: boolean) => void
  onScenarioUpdate: (payload: any, scenarioId: string) => void
}

const { getCheckedText } = helper

const DefineScopeOnshore = ({
  scenario,
  children,
  onScopeUpdate,
  onScenarioUpdate
}: Props) => {
  const data = scenario?.decommissionScope as DecommissionOnshoreScopeViewModel
  const { isUpdating } = useScenarioState()
  const { isCalculationLoading } = useCostScenarioBreakdown(
    false,
    scenario?.includeScrapValue
  )
  const bulkDisableValues = (...parameters: string[]) => {
    const parametersObject: { [param: string]: boolean } = {}
    for (const param of parameters) {
      parametersObject[param] = false
    }
    onScenarioUpdate(parametersObject, `${scenario?.id}`)
  }

  return (
    <ORow>
      <OCol md={children ? '6' : '12'} className="mb-2">
        <ContentSection title="Decommissioning scope">
          <div className="px-2">
            <label>Access roads to be removed?</label>
            <OToggleSwitch
              className="pointer mb-3"
              disabled={isUpdating || isCalculationLoading}
              textLocation="hidden"
              checked={data?.accessRoadsToBeRemoved}
              onChange={() => {
                data?.accessRoadsToBeRemoved
                  ? bulkDisableValues(
                      'accessRoadsToBeRemoved',
                      'geotextilesBeneathTheRoadToBeRemoved'
                    )
                  : onScopeUpdate?.(
                      'accessRoadsToBeRemoved',
                      !data?.accessRoadsToBeRemoved
                    )
              }}>
              {getCheckedText(!!data?.accessRoadsToBeRemoved)}
            </OToggleSwitch>
            <label>Are there geotextiles beneath the road to be removed?</label>
            <OToggleSwitch
              className="pointer"
              textLocation="hidden"
              checked={
                data?.geotextilesBeneathTheRoadToBeRemoved &&
                data?.accessRoadsToBeRemoved
              }
              disabled={
                !data?.accessRoadsToBeRemoved ||
                isUpdating ||
                isCalculationLoading
              }
              onChange={() =>
                onScopeUpdate?.(
                  'geotextilesBeneathTheRoadToBeRemoved',
                  !data?.geotextilesBeneathTheRoadToBeRemoved
                )
              }>
              {getCheckedText(
                !!data?.geotextilesBeneathTheRoadToBeRemoved &&
                  !!data?.accessRoadsToBeRemoved
              )}
            </OToggleSwitch>
            <div className="border-bottom my-3" />
            <label>Do the foundations need to be removed?</label>
            <OToggleSwitch
              className="pointer"
              textLocation="hidden"
              checked={data?.foundationsNeedToBeRemoved}
              disabled={isUpdating || isCalculationLoading}
              onChange={() =>
                onScopeUpdate?.(
                  'foundationsNeedToBeRemoved',
                  !data?.foundationsNeedToBeRemoved
                )
              }>
              {getCheckedText(!!data?.foundationsNeedToBeRemoved)}
            </OToggleSwitch>
            <div className="border-bottom my-3" />
            <label>
              Are the level crane pads at each turbine base to be removed?
            </label>
            <OToggleSwitch
              className="pointer mb-3"
              textLocation="hidden"
              checked={data?.levelCranePadsAtEachTurbineBaseToBeRemoved}
              disabled={isUpdating || isCalculationLoading}
              onChange={() => {
                data?.levelCranePadsAtEachTurbineBaseToBeRemoved
                  ? bulkDisableValues(
                      'levelCranePadsAtEachTurbineBaseToBeRemoved',
                      'geotextilesBeneathTheCranePadToBeRemoved'
                    )
                  : onScopeUpdate?.(
                      'levelCranePadsAtEachTurbineBaseToBeRemoved',
                      !data?.levelCranePadsAtEachTurbineBaseToBeRemoved
                    )
              }}>
              {getCheckedText(
                !!data?.levelCranePadsAtEachTurbineBaseToBeRemoved
              )}
            </OToggleSwitch>
            <label>
              Are there geotextiles beneath the crane pad to be removed?
            </label>
            <OToggleSwitch
              className="pointer"
              textLocation="hidden"
              checked={data?.geotextilesBeneathTheCranePadToBeRemoved}
              disabled={
                !data?.levelCranePadsAtEachTurbineBaseToBeRemoved ||
                isUpdating ||
                isCalculationLoading
              }
              onChange={() =>
                onScopeUpdate?.(
                  'geotextilesBeneathTheCranePadToBeRemoved',
                  !data?.geotextilesBeneathTheCranePadToBeRemoved
                )
              }>
              {getCheckedText(!!data?.geotextilesBeneathTheCranePadToBeRemoved)}
            </OToggleSwitch>

            <div className="border-bottom my-3" />
            <label>Do the cables need to be removed?</label>
            <OToggleSwitch
              className="pointer"
              textLocation="hidden"
              checked={data?.cablesNeedToBeRemoved}
              disabled={isUpdating || isCalculationLoading}
              onChange={() =>
                onScopeUpdate?.(
                  'cablesNeedToBeRemoved',
                  !data?.cablesNeedToBeRemoved
                )
              }>
              {getCheckedText(!!data?.cablesNeedToBeRemoved)}
            </OToggleSwitch>

            <div className="border-bottom my-3" />
            <label>Is there a top-soil storage area on site possible?</label>
            <OToggleSwitch
              className="pointer"
              textLocation="hidden"
              checked={data?.topSoilStorageAreaOnSitePossible}
              disabled={isUpdating || isCalculationLoading}
              onChange={() =>
                onScopeUpdate?.(
                  'topSoilStorageAreaOnSitePossible',
                  !data?.topSoilStorageAreaOnSitePossible
                )
              }>
              {getCheckedText(!!data?.topSoilStorageAreaOnSitePossible)}
            </OToggleSwitch>

            <div className="border-bottom my-3" />
            <label>Does the HV substation need to be removed?</label>
            <OToggleSwitch
              className="pointer mb-3"
              textLocation="hidden"
              checked={data?.hVsubstationNeedToBeRemoved}
              disabled={isUpdating || isCalculationLoading}
              onChange={() =>
                onScopeUpdate?.(
                  'hVsubstationNeedToBeRemoved',
                  !data?.hVsubstationNeedToBeRemoved
                )
              }>
              {getCheckedText(!!data?.hVsubstationNeedToBeRemoved)}
            </OToggleSwitch>
            <label>Is the met mast to be removed?</label>
            <OToggleSwitch
              className="pointer mb-3"
              textLocation="hidden"
              checked={data?.metMastToBeRemoved}
              disabled={isUpdating || isCalculationLoading}
              onChange={() =>
                onScopeUpdate?.('metMastToBeRemoved', !data?.metMastToBeRemoved)
              }>
              {getCheckedText(!!data?.metMastToBeRemoved)}
            </OToggleSwitch>
            <label>Any other building or constructions on site to be removed?</label>
            <OToggleSwitch
              disabled={
                isUpdating ||
                isCalculationLoading
              }
              className="pointer"
              textLocation="hidden"
              checked={data?.buildingToBeRemoved}
              onChange={() =>
                onScopeUpdate?.(
                  'buildingToBeRemoved',
                  !data?.buildingToBeRemoved
                )
              }>
              {getCheckedText(!!data?.buildingToBeRemoved)}
            </OToggleSwitch>
          </div>
        </ContentSection>
      </OCol>
      {!!children && <OCol md="6">{children}</OCol>}
    </ORow>
  )
}

export default React.memo(DefineScopeOnshore)
