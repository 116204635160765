import React, { useState, useEffect } from 'react'
import {
  OButton,
  OModal,
  OField,
  ORow,
  OCol,
  ModalSize,
  FieldType,
  Option,
  ButtonVariant,
  GridColumns,
  Size,
  OAlert
} from '@dnvgl-onefoundation/onedesign-react'
import { useToggle } from '@/hooks'
import { helper } from '@/utils'
import { CustomField } from '@/interfaces'
import TooltipHelpText from './TooltipHelpText'

interface Props {
  allowSavingDraft?: boolean
  type: string
  fields: CustomField[]
  buttonVariant?: ButtonVariant
  buttonSize?: Size
  className?: string
  disabled?: boolean
  size?: ModalSize
  inputGridColumns?: GridColumns
  label?: string
  iconClass?: string
  okText?: string
  alreadyAvailableNames?: string[]
  onOK?: (payload: any) => void
  onCancel?: (payload: any) => void
}

const { deepClone } = helper

const AddItem = ({
  allowSavingDraft = false,
  type,
  fields,
  buttonVariant,
  buttonSize,
  className,
  disabled = false,
  size = 'md',
  inputGridColumns = '12',
  label,
  iconClass = 'far fa-plus',
  okText = 'Add',
  alreadyAvailableNames = [],
  onOK,
  onCancel
}: Props) => {
  const [isVisible, toggleIsVisible] = useToggle()
  const handleOK = () => {
    toggleIsVisible()
    onOK?.(value)
    setValue({})
  }

  const handleCancel = () => {
    toggleIsVisible()
    onCancel?.(value)
    setValue({})
  }

  const [alreadyUsedName, setAlreadyUsedName] = useState('')
  const [value, setValue] = useState({} as any)

  const update = (id: string, v: string, options?: Option[]) => {
    const newValue = deepClone(value)
    newValue[id] = v
    if (options)
      newValue[id] = options?.find(i => `${i.value}` === `${v}`)?.value || v
    setValue(newValue)
  }

  useEffect(() => {
    const initialValue: any = {}
    fields.forEach(field => {
      initialValue[field.id] = field.defaultValue
      setValue(initialValue)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!alreadyAvailableNames?.length) return undefined

    let foundName = ''

    alreadyAvailableNames.forEach(i => {
      if (
        i?.toLocaleLowerCase()?.trim() ===
        value?.name?.toLocaleLowerCase()?.trim()
      )
        foundName = i
    })

    setAlreadyUsedName(foundName)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, alreadyAvailableNames, isVisible])

  const bodySlot = (
    <ORow>
      {!!alreadyUsedName && (
        <OCol md="12">
          <OAlert
            variant="danger"
            message={`"${alreadyUsedName}" ${type} already exists!`}
            description="Please provide another name."
            dismissable={false}
          />
        </OCol>
      )}

      {fields.map(field => (
        <OCol
          md="12"
          col={inputGridColumns}
          key={`${field.id}-${field.type}-${field.heading}`}>
          {field?.type === 'label' ? (
            <label className="mb-3">{field?.heading}</label>
          ) : (
            <OField
              key={field.id}
              className={
                field?.isRequired && !value[field?.id]
                  ? 'text-danger'
                  : undefined
              }
              value={field.defaultValue as any}
              onChange={e => {
                update(field.id, e.value as string, field.options || [])
                field?.onChange?.(e.value as string)
              }}
              field={
                {
                  name: field.id,
                  heading: field?.tooltipHelpText ? (
                    <>
                      <span>{field.heading}</span>
                      <TooltipHelpText
                        message={field?.tooltipHelpText}
                        placement="right"
                        className="ml-1"
                      />
                    </>
                  ) : (
                    field.heading
                  ),
                  type: field.type || FieldType.Input,
                  inputType: field?.inputType,
                  options: field.options || []
                } as any
              }
            />
          )}
        </OCol>
      ))}
    </ORow>
  )

  return (
    <>
      <OButton
        disabled={disabled}
        iconClass={iconClass}
        className={className}
        onClick={toggleIsVisible}
        variant={buttonVariant}
        size={buttonSize}>
        {label || `Add new ${type?.toLocaleLowerCase()}`}
      </OButton>
      <OModal
        visible={isVisible}
        size={size}
        okText={okText}
        cancelText={allowSavingDraft ? 'Save draft' : 'Cancel'}
        clickOutside={false}
        titleText={label || `Add new ${type?.toLocaleLowerCase()}`}
        bodySlot={bodySlot}
        onOk={handleOK}
        onCancel={handleCancel}
        onClose={toggleIsVisible}
        okDisabled={!!alreadyUsedName?.length}
        cancelDisabled={!!alreadyUsedName?.length}
      />
    </>
  )
}

export default React.memo(AddItem)
