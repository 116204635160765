import {
  OffshoreBottomFixedFoundationSpecificationViewModel,
  ProjectItemViewModel
} from '@/interfaces'
import React from 'react'
import { config, helper } from '@/utils'
import { ContentSectionValue } from '../layout'

interface Props {
  p?: ProjectItemViewModel | null
}

const { getCheckedText } = helper

const { projects } = config

const SummaryStepDetailsOffshoreFixed = ({ p }: Props) => {
  var offshoreFoundations =
    p?.foundationSpecification as OffshoreBottomFixedFoundationSpecificationViewModel
  return (
    <>
      <ContentSectionValue
        className="my-2"
        left="Foundation type"
        right={
          projects.selectOptions.foundationType.find(
            i => i.value === offshoreFoundations?.foundationType
          )?.text
        }
      />
      <ContentSectionValue
        className="my-2"
        left="Foundation total weight"
        right={
          offshoreFoundations?.totalWeight
            ? `${offshoreFoundations?.totalWeight} tonnes`
            : null
        }
      />
      <ContentSectionValue
        className="my-2"
        left="Array cable length"
        right={p?.arrayCableLengthKm ? `${p?.arrayCableLengthKm} km` : null}
      />
      <ContentSectionValue
        className="my-2"
        left="Export cable length"
        right={p?.exportCableLengthKm ? `${p?.exportCableLengthKm} km` : null}
      />
      <ContentSectionValue
        className="my-2"
        left="Offshore substation"
        right={getCheckedText(!!p?.hasOffshoreSubstation)}
      />
    </>
  )
}

export default React.memo(SummaryStepDetailsOffshoreFixed)
