import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  OBadge,
  OButton,
  OCol,
  ORow,
  OTooltip
} from '@dnvgl-onefoundation/onedesign-react'
import {
  ContentSectionFlat,
  ContentSectionTitle,
  ContentSectionValue
} from '@/components/layout'
import { ReportTable } from '@/components/helpers'
import { config, helper } from '@/utils'
import {
  DecommissionOnshoreScopeViewModel,
  MaterialsBreakdownsViewModel,
  ProjectItemViewModel,
  ProjectType,
  ScenarioItemViewModel
} from '@/interfaces'

import SummaryStepDetailsOnshore from './SummaryStepDetailsOnshore'
import SummaryStepDetailsOffshoreFloating from './SummaryStepDetailsOffshoreFloating'
import SummaryStepDetailsOffshoreFixed from './SummaryStepDetailsOffshoreFixed'
import { useCostScenarioBreakdown } from '@/hooks'
import FloatingAlertMessage from '@/components/helpers/FloatingAlertMessage'

const {
  getProjectTypeName,
  toLocaleDate,
  formatPercentage,
  truncateString,
  formatTonnes,
  formatCurrencyMask
} = helper
const { icons, urls } = config

interface Props {
  selectedProject?: ProjectItemViewModel | null
  scenario?: ScenarioItemViewModel | null
  turbineMaterialBreakdown: MaterialsBreakdownsViewModel | null
  providerItems?: any[]
  turbineCircularityRateForScenario?: number | null
}

const SummaryStep = ({
  selectedProject: p,
  scenario: s,
  turbineMaterialBreakdown: mb,
  providerItems: i,
  turbineCircularityRateForScenario
}: Props) => {
  const {
    costScenarioBreakdownTableItems,
    updatedAt,
    getCostScenarioBreakdown
  } = useCostScenarioBreakdown(true, s?.includeScrapValue)

  useEffect(() => {
    if (
      s?.id &&
      p?.projectType !== undefined &&
      p?.projectType !== ProjectType.OffshoreFloating
    )
      getCostScenarioBreakdown(s?.id, p?.projectType)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [p])

  return (
    <ORow>
      <OCol md="5">
        <ContentSectionFlat title={p?.name}>
          <ContentSectionTitle title="Site details" icon="fas fa-map-pin" />
          <ContentSectionValue
            className="my-2"
            left="Type"
            right={getProjectTypeName(p?.projectType)}
          />
          <ContentSectionValue
            className="my-2"
            left="Location"
            right={p?.countryName}
          />
          <ContentSectionValue
            className="my-2"
            left="Installation date"
            right={toLocaleDate(`${p?.installationDate}`)}
          />
          <ContentSectionValue
            className="my-2"
            left="Number of turbines"
            right={p?.numberOfTurbines}
          />
          {p?.projectType === ProjectType.Onshore && (
            <SummaryStepDetailsOnshore
              p={p}
              metMastToBeRemoved={
                !!(s?.decommissionScope as DecommissionOnshoreScopeViewModel)
                  ?.metMastToBeRemoved
              }
            />
          )}
          {p?.projectType === ProjectType.OffshoreFloating && (
            <SummaryStepDetailsOffshoreFloating p={p} />
          )}
          {p?.projectType === ProjectType.OffshoreBottomFixed && (
            <SummaryStepDetailsOffshoreFixed p={p} />
          )}
          {!!p?.id && (
            <div>
              <Link to={`${urls.projects}/${p.id}`}>
                <OTooltip content="Open project page">
                  <OButton variant="flat">
                    More
                    <i className="fal fa-chevron-right ml-2" />
                  </OButton>
                </OTooltip>
              </Link>
            </div>
          )}
          <div className="border-bottom my-3" />
          <ContentSectionTitle
            title="Turbine details"
            icon={icons.windTurbine}
          />
          {!!p && (
            <>
              <ContentSectionValue
                className="my-2"
                left="Name"
                right={
                  <OTooltip content={p?.turbineName} placement="bottom">
                    {truncateString(`${p?.turbineName}`, 30)}
                  </OTooltip>
                }
              />
              {p?.turbineName !== p?.turbineModelNumber && (
                <ContentSectionValue
                  className="my-2"
                  left="Model"
                  right={
                    <OTooltip
                      content={p?.turbineModelNumber}
                      placement="bottom">
                      {truncateString(`${p?.turbineModelNumber}`, 30)}
                    </OTooltip>
                  }
                />
              )}
              <ContentSectionValue
                className="my-2"
                left="Manufacturer"
                right={`${p?.turbineManufacturer}`}
              />
            </>
          )}
          {!!mb && (
            <>
              <ContentSectionValue
                className="my-2"
                left="Blade mass"
                right={mb?.blades?.total ? formatTonnes(mb.blades.total) : null}
              />
              <ContentSectionValue
                className="my-2"
                left="Nacelle mass"
                right={
                  mb?.nacelle?.total ? formatTonnes(mb.nacelle.total) : null
                }
              />
              <ContentSectionValue
                className="my-2"
                left="Hub mass"
                right={mb?.hub?.total ? formatTonnes(mb.hub.total) : null}
              />
              <ContentSectionValue
                className="my-2"
                left="Tower mass"
                right={mb?.tower?.total ? formatTonnes(mb.tower.total) : null}
              />
              <ContentSectionValue
                className="my-2"
                left="Turbine mass"
                right={mb?.total?.total ? formatTonnes(mb.total.total) : null}
              />
            </>
          )}
          {!!p?.turbineId && (
            <Link to={`${urls.turbines}/${p.turbineId}`}>
              <OTooltip content="Open turbine page">
                <OButton variant="flat">
                  More
                  <i className="fal fa-chevron-right ml-2" />
                </OButton>
              </OTooltip>
            </Link>
          )}
        </ContentSectionFlat>
      </OCol>
      <OCol md="7">
        <ContentSectionFlat title="Summary">
          <ORow className="border-bottom pb-3">
            {p?.projectType === ProjectType.OffshoreFloating ? (
              <FloatingAlertMessage />
            ) : (
              !!costScenarioBreakdownTableItems?.length && (
                <OCol md="12" className="mb-4">
                  <ContentSectionValue
                    className="my-2"
                    left={
                      <span className="font-weight-bold">Cost calculation</span>
                    }
                    right={
                      updatedAt ? (
                        <span className="font-weight-normal">
                          Generated {updatedAt}
                        </span>
                      ) : null
                    }
                  />
                  <ReportTable
                    items={costScenarioBreakdownTableItems}
                    className="border mb-2"
                    scenarioCurrency={s?.currency.currencyInformation}
                    valueFormater={formatCurrencyMask}
                    simpleTable
                  />
                  <div className="text-right">
                    <Link to={`${urls.scenarios}/${s?.id}${urls.reports}`}>
                      <OButton variant="subtle" className="mr-1">
                        View full calculation
                      </OButton>
                    </Link>
                  </div>
                </OCol>
              )
            )}
            <OCol md="12" className="border-top">
              <div className="font-weight-bold my-3">Supply chain</div>
            </OCol>
            <OCol md="5">
              <ContentSectionTitle
                title="Metal processing"
                icon={icons.metalProcessingLight}
              />
            </OCol>
            {s?.providers?.metal?.provider?.id ? (
              <>
                <OCol md="7">
                  <ContentSectionValue
                    left="Facility"
                    right={
                      <Link
                        to={`${urls.providers}/${s?.providers?.metal?.provider?.id}`}>
                        {s?.providers?.metal?.provider?.name}
                      </Link>
                    }
                  />
                  <ContentSectionValue
                    className="my-2"
                    left="Process"
                    right={
                      i?.find(
                        provider =>
                          provider.id === s?.providers?.metal?.provider?.id
                      )?.providerTypesList
                    }
                  />
                </OCol>
              </>
            ) : (
              <OCol md="7">Landfill</OCol>
            )}
          </ORow>
          <ORow className="border-bottom py-3">
            <OCol md="5">
              <ContentSectionTitle
                title="Blade processing"
                icon={icons.bladeProcessingLight}
              />
            </OCol>
            {s?.providers?.blade?.provider?.id ? (
              <>
                <OCol md="7">
                  <ContentSectionValue
                    left="Facility"
                    right={
                      <Link
                        to={`${urls.providers}/${s?.providers?.blade?.provider?.id}`}>
                        {s?.providers?.blade?.provider?.name}
                      </Link>
                    }
                  />
                  <ContentSectionValue
                    className="my-2"
                    left="Process"
                    right={
                      i?.find(
                        provider =>
                          provider.id === s?.providers?.blade?.provider?.id
                      )?.providerTypesList
                    }
                  />
                </OCol>
              </>
            ) : (
              <OCol md="7">Landfill</OCol>
            )}
          </ORow>
          <ORow className="border-bottom py-3">
            <OCol md="5">
              <ContentSectionTitle
                title="Concrete processing"
                icon={icons.concreteProcessingLight}
              />
            </OCol>
            {s?.providers?.concrete?.provider?.id ? (
              <>
                <OCol md="7">
                  <ContentSectionValue
                    left="Facility"
                    right={
                      <Link
                        to={`${urls.providers}/${s?.providers?.concrete?.provider?.id}`}>
                        {s?.providers?.concrete?.provider?.name}
                      </Link>
                    }
                  />
                  <ContentSectionValue
                    className="my-2"
                    left="Process"
                    right={
                      i?.find(
                        provider =>
                          provider.id === s?.providers?.concrete?.provider?.id
                      )?.providerTypesList
                    }
                  />
                </OCol>
              </>
            ) : (
              <OCol md="7">Landfill</OCol>
            )}
          </ORow>
          <ORow className="py-3">
            <OCol md="12" className="pr-4">
              {!!turbineCircularityRateForScenario && (
                <ContentSectionValue
                  className="py-1 font-weight-bold"
                  left="Circularity rate"
                  right={
                    <OBadge variant="success">
                      {formatPercentage(turbineCircularityRateForScenario)}
                    </OBadge>
                  }
                />
              )}
            </OCol>
          </ORow>
        </ContentSectionFlat>
      </OCol>
    </ORow>
  )
}

export default React.memo(SummaryStep)
