import {
  OffshoreBottomFixedFoundationSpecificationViewModel,
  OnshoreFoundationSpecificationViewModel,
  ProjectItemViewModel,
  ProjectType,
  ReportSectionCode,
  ScenarioItemViewModel
} from '@/interfaces'
import { Text, View } from '@react-pdf/renderer'
import React from 'react'
import { config, helper } from '@/utils'
import Table from './Table'

interface Props {
  project?: ProjectItemViewModel
  scenario?: ScenarioItemViewModel
  styles?: any
  texts?: any
  setPages?: any
  printSections: number[]
}

const {
  getProjectTypeName,
  formatCoordinates,
  getMooringLineMaterialName,
  getAnchorMaterialName,
  getCheckedText,
  toLocaleDate
} = helper

const { projects, missingValueCharacter } = config

const ReportProjectSitePage: React.FC<Props> = ({
  project,
  scenario,
  styles,
  texts,
  printSections
}) => {
  const general = [
    ['Coordinates', formatCoordinates(project?.location) || ''],
    ['Country', project?.countryName || ''],
    ['Number of turbines', project?.numberOfTurbines?.toString() || ''],
    ['Installation date', toLocaleDate(`${project?.installationDate}`)],
    ['Date of commissioning', toLocaleDate(`${project?.dateOfCommissioning}`)],
    [
      'Assumed end of life date',
      toLocaleDate(`${project?.assumedEndOfLifeDate}`)
    ],
    ['Type', getProjectTypeName(project?.projectType) || ''],
    [
      project?.projectType === ProjectType.Onshore
        ? 'Address'
        : 'Planned installation/decommissioning port',
      project?.address || missingValueCharacter
    ]
  ]

  if (!scenario?.includeAssumedEndOfLifeDate) {
    general.splice(5, 1)
  }

  const commontOffshore = [
    [
      'Distance to port from centre of wind farm (km)',
      project?.distanceToPortKm
    ],
    ['Average water depth (m)', project?.averageWaterDepthM]
  ]

  const offshoreFoundations =
    project?.foundationSpecification as OffshoreBottomFixedFoundationSpecificationViewModel

  const onshoreFoundations =
    project?.foundationSpecification as OnshoreFoundationSpecificationViewModel
  const typeOptions = {
    [ProjectType.OffshoreBottomFixed]: [
      ...commontOffshore,
      [
        'Foundation type',
        projects.selectOptions.foundationType.find(
          i => i.value === offshoreFoundations?.foundationType
        )?.text
      ],
      ['Foundation total weight (tonnes)', offshoreFoundations?.totalWeight],
      ['Foundation steel weight (tonnes)', offshoreFoundations?.steel],
      ['Foundation concrete weight (tonnes)', offshoreFoundations?.concrete],
      ['Foundation other weight (tonnes)', offshoreFoundations?.other]
    ],
    [ProjectType.OffshoreFloating]: [
      ...commontOffshore,
      ['Floater total weight (tonnes)', project?.floaterTotalWeightT],
      ['Floater steel weight (tonnes)', project?.floaterSteelWeightT],
      ['Floater concrete weight (tonnes)', project?.floaterConcreteWeightT],
      ['Floater other weight (tonnes)', project?.floaterOtherWeightT],
      ['Primary mooring line material', project?.primaryMooringLine?.material],
      [
        'Primary mooring line weight (kg/m)',
        project?.primaryMooringLine?.weightKgM
      ],
      [
        'Primary mooring line total length (m/turbine)',
        project?.primaryMooringLine?.lengthM
      ],
      [
        'Secondary mooring line material',
        getMooringLineMaterialName(project?.secondaryMooringLine?.material)
      ],
      [
        'Secondary mooring line weight (kg/m)',
        project?.secondaryMooringLine?.weightKgM
      ],
      [
        'Secondary mooring line total length (m/turbine)',
        project?.secondaryMooringLine?.lengthM
      ],
      ['Anchor material', getAnchorMaterialName(project?.anchor?.material)],
      ['Anchor weight (kg/turbine)', project?.anchor?.weightKg]
    ],
    [ProjectType.Onshore]: [
      ['Access road length (km)', project?.accessRoadLengthKm],
      ['Access road width (m)', project?.accessRoadWidthM],
      [
        'Foundation volume (m³)',
        onshoreFoundations?.foundationVolume?.toFixed?.(2)
      ],
      ['Foundation total weight (tonnes)', onshoreFoundations?.totalWeight],
      ['Foundation steel weight (tonnes)', onshoreFoundations?.steel],
      ['Foundation concrete weight (tonnes)', onshoreFoundations?.concrete],
      ['Foundation other weight (tonnes)', onshoreFoundations?.other],
      ['Crane pad length (m)', project?.cranePadLengthM],
      ['Crane pad width (m)', project?.cranePadWidthM],
      ['Crane pad depth (m)', project?.cranePadDepthM],
      ['On-site substation', getCheckedText(!!project?.hasOnshoreSubstation)],
      ['Met mast number', getCheckedText(!!project?.metMastNumber)]
    ]
  }

  const cables = [
    ['Array cable length (km)', project?.arrayCableLengthKm],
    ['Array cable size (kV)', project?.arrayCableSizeKV],
    [
      'Array cable core material',
      projects.selectOptions.cableMaterial.find(
        i => i.value === project?.arrayCableMaterial
      )?.text
    ],
    ['Export cable length (km)', project?.exportCableLengthKm],
    ['Export cable size (kV)', project?.exportCableSizeKV],
    [
      'Export cable core material',
      projects.selectOptions.cableMaterial.find(
        i => i.value === project?.exportCableMaterial
      )?.text
    ]
  ]

  const typeSpecific =
    project?.projectType !== undefined ? typeOptions[project?.projectType] : []

  return (
    <View>
      <View />
      <Text id="projectsite" style={styles.pageTitle}>
        {printSections
          .sort()
          .findIndex(value => value === ReportSectionCode.Introduction) + 1}
        .{printSections.includes(ReportSectionCode.Introduction) ? '2' : '1'}{' '}
        Project site
      </Text>
      <Text style={styles.paragraph}>{texts?.report.projectSite.intro}</Text>
      <Table
        name="Site Details"
        rows={[...general, ...typeSpecific, ...cables] as any[]}
      />
    </View>
  )
}
export default ReportProjectSitePage
