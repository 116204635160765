import React from 'react'
import { ContentSection } from '@/components/layout'
import { Input, RadioToggle } from '@/components/helpers'
import { config } from '@/utils'
import { OCol, ORow } from '@dnvgl-onefoundation/onedesign-react'
import {
  OffshoreBottomFixedFoundationSpecificationViewModel,
  ProjectItemViewModel,
  SubstationViewModel
} from '@/interfaces'
import {
  ProjectPageOffshoreSubstation,
  ProjectPageOnshoreSubstation
} from '@/components/projects/index'
import FoundationSpecification from './FoundationSpecification'

interface Props {
  project: ProjectItemViewModel | null
  projectSubstations: SubstationViewModel | null
  onUpdate: (
    propertyName: string,
    propertyValue: string | number | boolean
  ) => void
  onUpdateSubstation: (
    propertyName: string,
    propertyValue: any,
    type: string
  ) => void
  onUpdateFoundationSpecification: (
    propertyName?: string,
    propertyValue?: any
  ) => void
  disabled?: boolean
}

const { icons, defaultSelectOption, projects, showNewSubstations } = config

const ProjectPageDetailsOffshoreFixed = ({
  project,
  onUpdate,
  projectSubstations,
  onUpdateSubstation,
  onUpdateFoundationSpecification,
  disabled
}: Props) => {
  const foundationSpecification =
    project?.foundationSpecification as OffshoreBottomFixedFoundationSpecificationViewModel
  return (
    <ContentSection
      title="Site infrastructure"
      padding="0"
      icon={icons.infrastructure}
      contentClassName="p-2"
      underlineTitle>
      <ORow>
        <OCol md="12" className="font-weight-bold">
          General
        </OCol>
        <OCol md="6">
          <Input
            propertyName="distanceToPortKm"
            label="Distance to port"
            helpText="Distance (km) to port from centre of wind farm"
            type="number"
            defaultValue={project?.distanceToPortKm}
            isValueMissing={project?.distanceToPortKm === null}
            min={0}
            onBlur={(n, v) => onUpdate(n, v)}
            disabled={disabled}
          />
        </OCol>
        <OCol md="6">
          <Input
            propertyName="averageWaterDepthM"
            label="Average water depth"
            helpText="m"
            type="number"
            defaultValue={project?.averageWaterDepthM}
            isValueMissing={project?.averageWaterDepthM === null}
            min={0}
            max={10000}
            onBlur={(n, v) => onUpdate(n, v)}
          />
        </OCol>
        <OCol md="12" className="border-bottom mb-2" />
        <FoundationSpecification
          foundationSpecification={foundationSpecification}
          onUpdate={onUpdateFoundationSpecification}
          disabled={disabled}>
          <Input
            propertyName="foundationType"
            label="Foundation type"
            type="select"
            value={foundationSpecification?.foundationType}
            options={
              foundationSpecification.foundationType === null
                ? [
                    defaultSelectOption,
                    ...projects.selectOptions.foundationType
                  ]
                : projects.selectOptions.foundationType
            }
            isValueMissing={foundationSpecification?.foundationType === null}
            onChange={(n, v) => onUpdateFoundationSpecification(n, parseInt(v))}
            disabled={disabled}
          />
        </FoundationSpecification>
        <OCol md="12" className="border-bottom mb-2" />
        <OCol md="12" className="font-weight-bold">
          Cables
        </OCol>
        <OCol md="6">
          <Input
            propertyName="arrayCableLengthKm"
            label="Array cable length"
            helpText="km"
            type="number"
            defaultValue={project?.arrayCableLengthKm}
            isValueMissing={project?.arrayCableLengthKm === null}
            min={0}
            onBlur={(n, v) => onUpdate(n, v)}
          />
        </OCol>
        <OCol md="6">
          <Input
            propertyName="exportCableLengthKm"
            label="Export cable length"
            helpText="km"
            type="number"
            defaultValue={project?.exportCableLengthKm}
            isValueMissing={project?.arrayCableLengthKm === null}
            min={0}
            onBlur={(n, v) => onUpdate(n, v)}
          />
        </OCol>
        <OCol md="6">
          <Input
            propertyName="arrayCableSizeKV"
            label="Array cable size"
            helpText="kV"
            type="select"
            value={project?.arrayCableSizeKV}
            options={projects.selectOptions.cableSize}
            onChange={(n, v) => onUpdate(n, parseInt(v))}
          />
        </OCol>
        <OCol md="6">
          <Input
            propertyName="exportCableSizeKV"
            label="Export cable size"
            helpText="kV"
            type="select"
            value={project?.exportCableSizeKV}
            options={projects.selectOptions.cableSize}
            onChange={(n, v) => onUpdate(n, parseInt(v))}
          />
        </OCol>
        <OCol md="6">
          <Input
            propertyName="arrayCableMaterial"
            label="Array cable core material"
            type="select"
            value={project?.arrayCableMaterial}
            options={
              project?.arrayCableMaterial === null
                ? [defaultSelectOption, ...projects.selectOptions.cableMaterial]
                : projects.selectOptions.cableMaterial
            }
            onChange={(n, v) => onUpdate(n, parseInt(v))}
          />
        </OCol>
        <OCol md="6">
          <Input
            propertyName="exportCableMaterial"
            label="Export cable core material"
            type="select"
            value={project?.exportCableMaterial}
            options={
              project?.exportCableMaterial === null
                ? [defaultSelectOption, ...projects.selectOptions.cableMaterial]
                : projects.selectOptions.cableMaterial
            }
            onChange={(n, v) => onUpdate(n, parseInt(v))}
          />
        </OCol>
        <OCol md="12" className="border-bottom mb-2" />
        <OCol md="12" className="font-weight-bold">
          Substations
        </OCol>
        {showNewSubstations ? (
          <>
            <ProjectPageOffshoreSubstation
              project={project}
              onUpdate={onUpdate}
              onUpdateSubstations={onUpdateSubstation}
              projectSubstations={projectSubstations}
            />
            <ProjectPageOnshoreSubstation
              project={project}
              onUpdate={onUpdate}
              onUpdateSubstations={onUpdateSubstation}
              projectSubstations={projectSubstations}
            />
          </>
        ) : (
          <OCol md="12">
            <RadioToggle
              label="Offshore substation"
              value={project?.hasOffshoreSubstation}
              onToggle={() => {
                onUpdate(
                  'hasOffshoreSubstation',
                  !project?.hasOffshoreSubstation
                )
              }}
            />
          </OCol>
        )}
      </ORow>
    </ContentSection>
  )
}

export default React.memo(ProjectPageDetailsOffshoreFixed)
