import { OButton } from '@dnvgl-onefoundation/onedesign-react'
import React from 'react'

interface Props {
  isLocked?: boolean
  isLoading?: boolean
  onClick?: () => void
}

const LockButton = ({ onClick, isLocked, isLoading }: Props) => (
  <OButton
    onClick={onClick}
    iconClass={`fas ${isLocked ? 'fa-lock-open' : 'fa-lock'}`}
    variant="flat"
    disabled={isLoading}>
    {isLocked ? 'Unlock' : 'Lock'}
  </OButton>
)

export default React.memo(LockButton)
