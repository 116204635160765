import React, { ReactNode } from 'react'
import {
  ORow,
  OCol,
  ODetails,
  Option,
  OButton,
  OTooltip
} from '@dnvgl-onefoundation/onedesign-react'
import { Input } from '@/components/helpers'
import { config, helper, doc } from '@/utils'
import { SpinIndicator } from '../layout'
import { ProjectItemViewModel } from '@/interfaces'

const { simulateDomClick } = doc

interface Props {
  items?: ProjectItemViewModel[]
  isLoading?: boolean
  isChangingProjectDisabled?: boolean
  selectedProject?: ProjectItemViewModel | null
  countryOptions?: Option[]
  manufacturers?: Option[]
  turbines?: Option[]
  children?: ReactNode
  onUpdateSelectedProject?: (id?: string) => void
}

const fields = {
  turbines: [
    {
      name: 'turbineName',
      heading: 'Name'
    },
    {
      name: 'turbineManufacturer',
      heading: 'Manufacturer'
    },
    {
      name: 'turbineModelNumber',
      heading: 'Model'
    },
    {
      name: 'installationDate',
      heading: 'Installation date'
    }
  ],
  site: [
    {
      name: 'projectTypeName',
      heading: 'Type'
    },
    {
      name: 'numberOfTurbines',
      heading: 'Number of turbines'
    },
    {
      name: 'countryName',
      heading: 'Location'
    }
  ]
}

const { defaultSelectOption, urls, missingValueCharacter } = config
const { toLocaleDate, truncateString } = helper

const ChooseProjectStep = ({
  items = [],
  isLoading,
  isChangingProjectDisabled,
  selectedProject,
  children,
  onUpdateSelectedProject
}: Props) => {
  const options = items.map(p => ({
    text: `${p?.name}${!p?.turbineId ? ` [Missing turbine]` : ''}`,
    value: p?.id,
    disabled: !p?.turbineId
  }))

  return isLoading ? (
    <SpinIndicator />
  ) : (
    <ORow>
      <OCol md="12">
        <ORow>
          <OCol md="6" className="mt-3">
            <Input
              disabled={isChangingProjectDisabled}
              propertyName="project"
              label="Project"
              type="select"
              options={
                selectedProject?.id
                  ? options
                  : [{ ...defaultSelectOption, value: 0 }, ...options]
              }
              value={selectedProject?.id || 0}
              onChange={(_key, value) => {
                onUpdateSelectedProject?.(items.find(i => i.id === value)?.id)
              }}
            />
          </OCol>
          <OCol md="6"></OCol>
          {!!selectedProject?.id ? (
            <>
              <OCol md="6" className="mt-3">
                <div className="border-bottom py-2">
                  <i className="fas fa-wind-turbine ml-3 mr-2" />
                  <strong>Turbines</strong>
                </div>
                <ODetails
                  fields={fields.turbines}
                  value={
                    {
                      turbineManufacturer:
                        selectedProject?.turbineManufacturer ||
                        missingValueCharacter,
                      turbineName: selectedProject?.turbineName ? (
                        <OTooltip
                          content={selectedProject?.turbineName}
                          placement="right">
                          {truncateString(selectedProject.turbineName, 40)}
                        </OTooltip>
                      ) : (
                        missingValueCharacter
                      ),
                      installationDate: toLocaleDate(
                        `${selectedProject?.installationDate}`
                      ),
                      turbineModelNumber:
                        selectedProject?.turbineModelNumber ||
                        missingValueCharacter
                    } as any
                  }
                  className="w-100"
                  boldValues
                />
              </OCol>
              <OCol md="6" className="mt-3">
                <div className="border-bottom py-2">
                  <i className="fas fa-map-pin ml-3 mr-2" />
                  <strong>Site</strong>
                </div>
                <ODetails
                  fields={fields.site}
                  value={
                    {
                      projectTypeName: (selectedProject as any)
                        ?.projectTypeName,
                      countryName: selectedProject?.countryName,
                      numberOfTurbines: selectedProject?.numberOfTurbines
                    } as unknown as any
                  }
                  className="w-100"
                  boldValues
                />
              </OCol>
            </>
          ) : (
            <OCol md="12">
              <OButton
                onClick={() => {
                  simulateDomClick(`a[href="${urls.projects}"]`)
                }}
                iconClass="fas fa-plus"
                variant="subtle"
                className="mt-4">
                Add new project
              </OButton>
            </OCol>
          )}
        </ORow>
        <ORow>
          <OCol md="6" className="mt-3">
            {children}
          </OCol>
          <OCol md="6" className="mt-3"></OCol>
        </ORow>
      </OCol>
    </ORow>
  )
}

export default React.memo(ChooseProjectStep)
