import React from 'react'
import { OCol, ORow } from '@dnvgl-onefoundation/onedesign-react'
import { ContentSection } from '../layout'
import {
  CoordinatesValue,
  Input,
  InputValue,
  TooltipHelpText
} from '../helpers'
import { MapBoxPin, MapBoxWrapper } from '@/components/maps'
import { useCountries } from '@/hooks'
import { helper, projects } from '@/utils'
import { ProjectItemViewModel, LatLng, ProjectType } from '@/interfaces'

interface Props {
  isEditable?: boolean
  isUpdating?: boolean
  project?: ProjectItemViewModel | null
  lngLat: LatLng
  onCountryChange: (e: any) => void
  onUpdate: (propertyName: string, propertyValue: any) => void
  onLocationUpdate: (e: any) => void
}

const { addPluralS, toHtmlDate, numberToText } = helper
const { projectTypeOptions } = projects

const ProjectPageSiteSection = ({
  isEditable,
  isUpdating,
  project,
  lngLat,
  onCountryChange,
  onUpdate,
  onLocationUpdate
}: Props) => {
  const { countryOptions } = useCountries()

  const location = {
    latitude: lngLat?.lat,
    longitude: lngLat?.lng
  }

  const isProjectWithScenarios = !!project?.scenarios?.length

  return (
    <ContentSection
      title="Site"
      padding="0"
      icon="fas fa-map-pin"
      contentClassName="p-0"
      isLoading={isUpdating}
      underlineTitle>
      <div className="px-2 py-1">
        <ORow>
          <OCol md="6">
            <Input
              propertyName="name"
              label="Project name"
              defaultValue={project?.name}
              disabled={isUpdating}
              onBlur={(n, v) => onUpdate(n, v)}
            />
          </OCol>
          <OCol md="6">
            <InputValue
              label="Type"
              value={
                projectTypeOptions?.find(i => i.value === project?.projectType)
                  ?.text
              }
            />
          </OCol>
          <OCol md="6">
            {isProjectWithScenarios ? (
              <InputValue
                label={
                  <>
                    Country&nbsp;
                    <TooltipHelpText
                      message={`This project already contains ${numberToText(
                        project?.scenarios?.length
                      )} scenario${addPluralS(
                        project?.scenarios?.length
                      )} specific to its current country setting. To ensure data consistency and integrity, the country setting cannot be modified once scenarios are added. If your work requires scenarios in a different country, please create a new project for that country.`}
                    />
                  </>
                }
                value={
                  countryOptions.find(i => i.value === project.countryId)?.text
                }
              />
            ) : (
              <Input
                propertyName="countryId"
                label="Country"
                value={project?.countryId}
                type="select"
                options={countryOptions}
                disabled={isUpdating}
                onChange={(_n, v) => onCountryChange(v)}
              />
            )}
          </OCol>
          <OCol md="6">
            <CoordinatesValue
              lngLat={lngLat}
              isEditable={isEditable}
              onLocationUpdate={onLocationUpdate}
            />
          </OCol>
          <OCol md="6">
            <Input
              propertyName="dateOfCommissioning"
              label="Date of commissioning"
              value={toHtmlDate(`${project?.dateOfCommissioning}`)}
              type="date"
              disabled={isUpdating}
              onBlur={(n, v) => onUpdate(n, v)}
              min="1980-01-01"
              max={`${new Date().getFullYear() + 100}-12-31`}
            />
          </OCol>
          <OCol md="6">
            <Input
              propertyName="assumedEndOfLifeDate"
              label="Assumed end of life date"
              value={toHtmlDate(`${project?.assumedEndOfLifeDate}`)}
              type="date"
              disabled={isUpdating}
              onBlur={(n, v) => onUpdate(n, v)}
              min={
                project?.dateOfCommissioning
                  ? toHtmlDate(`${project?.dateOfCommissioning}`)
                  : '1980-01-01'
              }
              max={`${new Date().getFullYear() + 100}-12-31`}
            />
          </OCol>
          <OCol md="12">
            <Input
              propertyName="address"
              label={
                project?.projectType === ProjectType.Onshore
                  ? 'Address'
                  : 'Planned installation/decommissioning port'
              }
              defaultValue={project?.address ?? ''}
              disabled={isUpdating}
              onBlur={(n, v) => onUpdate(n, v)}
            />
          </OCol>
        </ORow>
      </div>
      {!!project && location?.latitude && location?.longitude && (
        <MapBoxWrapper height={230} zoom={4} {...location}>
          {!!lngLat?.lat && !!lngLat.lng && (
            <MapBoxPin
              draggable={!isUpdating}
              onDragEnd={onLocationUpdate}
              {...location}
            />
          )}
        </MapBoxWrapper>
      )}
    </ContentSection>
  )
}

export default React.memo(ProjectPageSiteSection)
