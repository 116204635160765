import { config, helper } from '@/utils'
import {
  OffshoreBottomFixedFoundationSpecificationViewModel,
  OnshoreFoundationSpecificationViewModel,
  ProjectItemViewModel,
  ProjectType,
  ScenarioItemViewModel
} from '@/interfaces'
import { getProjectTypeName } from '@/utils/helper'
import { SuperscriptUnit } from '@/components/helpers'

const {
  getCheckedText,
  formatCoordinates,
  getMooringLineMaterialName,
  getAnchorMaterialName,
  toLocaleDate
} = helper
const { projects, missingValueCharacter } = config

const useReportDetails = (
  selectedProject?: ProjectItemViewModel,
  scenario?: ScenarioItemViewModel | null
) => {
  const generalSiteDetails = [
    {
      text: 'Coordinates',
      value: formatCoordinates(selectedProject?.location)
    },
    {
      text: 'Country',
      value: selectedProject?.countryName
    },
    {
      text: 'Number of turbines',
      value: selectedProject?.numberOfTurbines
    },
    {
      text: 'Installation date',
      value: toLocaleDate(`${selectedProject?.installationDate}`)
    },
    {
      text: 'Date of commissioning',
      value: toLocaleDate(`${selectedProject?.dateOfCommissioning}`)
    },
    {
      text: 'Assumed end of life date',
      value: toLocaleDate(`${selectedProject?.assumedEndOfLifeDate}`)
    },
    {
      text: 'Type',
      value: getProjectTypeName(selectedProject?.projectType)
    },
    {
      text:
        selectedProject?.projectType === ProjectType.Onshore
          ? 'Address'
          : 'Planned installation/decommissioning port',
      value: selectedProject?.address || missingValueCharacter
    }
  ]

  if (!scenario?.includeAssumedEndOfLifeDate) {
    generalSiteDetails.splice(5, 1)
  }

  const commonOffshoreDetails = [
    {
      text: 'Distance to port from centre of wind farm (km)',
      value: selectedProject?.distanceToPortKm
    },
    {
      text: 'Average water depth (m)',
      value: selectedProject?.averageWaterDepthM
    }
  ]

  const typeSpecificDetails = {
    [ProjectType.OffshoreBottomFixed]: [
      ...commonOffshoreDetails,
      {
        text: 'Foundation type',
        value: projects.selectOptions.foundationType.find(
          i =>
            i.value ===
            (
              selectedProject?.foundationSpecification as OffshoreBottomFixedFoundationSpecificationViewModel
            )?.foundationType
        )?.text
      },
      {
        text: 'Foundation total weight (tonnes)',
        value: selectedProject?.foundationSpecification?.totalWeight
      },
      {
        text: 'Foundation steel weight (tonnes)',
        value: selectedProject?.foundationSpecification?.steel
      },
      {
        text: 'Foundation concrete weight (tonnes)',
        value: selectedProject?.foundationSpecification?.concrete
      },
      {
        text: 'Foundation other weight (tonnes)',
        value: selectedProject?.foundationSpecification?.other
      }
    ],
    [ProjectType.OffshoreFloating]: [
      ...commonOffshoreDetails,
      {
        text: 'Floater total weight (tonnes)',
        value: selectedProject?.floaterTotalWeightT
      },
      {
        text: 'Floater steel weight (tonnes)',
        value: selectedProject?.floaterSteelWeightT
      },
      {
        text: 'Floater concrete weight (tonnes)',
        value: selectedProject?.floaterConcreteWeightT
      },
      {
        text: 'Floater other weight (tonnes)',
        value: selectedProject?.floaterOtherWeightT
      },
      {
        text: 'Primary mooring line material',
        value: getMooringLineMaterialName(
          selectedProject?.primaryMooringLine?.material
        )
      },
      {
        text: 'Primary mooring line weight (kg/m)',
        value: selectedProject?.primaryMooringLine?.weightKgM
      },
      {
        text: 'Primary mooring line total length (m/turbine)',
        value: selectedProject?.primaryMooringLine?.lengthM
      },
      {
        text: 'Secondary mooring line material',
        value: getMooringLineMaterialName(
          selectedProject?.secondaryMooringLine?.material
        )
      },
      {
        text: 'Secondary mooring line weight (kg/m)',
        value: selectedProject?.secondaryMooringLine?.weightKgM
      },
      {
        text: 'Secondary mooring line total length (m/turbine)',
        value: selectedProject?.secondaryMooringLine?.lengthM
      },
      {
        text: 'Anchor material',
        value: getAnchorMaterialName(selectedProject?.anchor?.material)
      },
      {
        text: 'Anchor weight (kg/turbine)',
        value: selectedProject?.anchor?.weightKg
      }
    ],
    [ProjectType.Onshore]: [
      {
        text: 'Access road length (km)',
        value: selectedProject?.accessRoadLengthKm
      },
      {
        text: 'Access road width (m)',
        value: selectedProject?.accessRoadWidthM
      },
      {
        text: (
          <span>
            Foundation volume ({<SuperscriptUnit unit="m" power={3} />})
          </span>
        ),
        value: (
          selectedProject?.foundationSpecification as OnshoreFoundationSpecificationViewModel
        )?.foundationVolume?.toFixed?.(2)
      },
      {
        text: 'Foundation total weight (tonnes)',
        value: selectedProject?.foundationSpecification?.totalWeight
      },
      {
        text: 'Foundation steel weight (tonnes)',
        value: selectedProject?.foundationSpecification?.steel
      },
      {
        text: 'Foundation concrete weight (tonnes)',
        value: selectedProject?.foundationSpecification?.concrete
      },
      {
        text: 'Foundation other weight (tonnes)',
        value: selectedProject?.foundationSpecification?.other
      },
      {
        text: 'Crane pad length (m)',
        value: selectedProject?.cranePadLengthM
      },
      {
        text: 'Crane pad width (m)',
        value: selectedProject?.cranePadWidthM
      },
      {
        text: 'Crane pad depth (m)',
        value: selectedProject?.cranePadDepthM
      },
      {
        text: 'On-site substation',
        value: getCheckedText(!!selectedProject?.hasOnshoreSubstation)
      },
      {
        text: 'Met mast number',
        value: selectedProject?.metMastNumber
      }
    ]
  }

  const cableDetails = [
    {
      text: 'Array cable length (km)',
      value: selectedProject?.arrayCableLengthKm
    },
    {
      text: 'Array cable size (kV)',
      value: selectedProject?.arrayCableSizeKV
    },
    {
      text: 'Array cable core material',
      value: projects.selectOptions.cableMaterial.find(
        i => i.value === selectedProject?.arrayCableMaterial
      )?.text
    },
    {
      text: 'Export cable length (km)',
      value: selectedProject?.exportCableLengthKm
    },
    {
      text: 'Export cable size (kV)',
      value: selectedProject?.exportCableSizeKV
    },
    {
      text: 'Export cable core material',
      value: projects.selectOptions.cableMaterial.find(
        i => i.value === selectedProject?.exportCableMaterial
      )?.text
    }
  ]

  return {
    generalSiteDetails,
    commonOffshoreDetails,
    typeSpecificDetails,
    cableDetails
  }
}

export default useReportDetails
