import React, { useEffect, useRef } from 'react'
import { Link, useParams } from 'react-router-dom'
import {
  OButton,
  OCol,
  OLogo,
  ORow
} from '@dnvgl-onefoundation/onedesign-react'
import { PDFDownloadLink } from '@react-pdf/renderer'
import {
  useCircularityRateData,
  useCostScenarioBreakdown,
  useDecommissioningScope,
  useReportDetails,
  useScenario,
  useScenarioProviders,
  useTexts,
  useScenarioCostCalcationConfiguration,
  useReportTurbineDetails,
  useReportCaptureImages,
  useReportPrintSections
} from '@/hooks'
import { ContentSection, Page, SpinIndicator } from '@/components/layout'
import { config, helper } from '@/utils'
import { PrintPageBreak } from '@/components/print'
import {
  BalanceOfPlant,
  CircularityTable,
  InputValue,
  ProviderInfoSection,
  MaterialComposition,
  PageNotFound,
  ProjectProvidersMap,
  ReportBalanceOfPlant,
  ReportCostAssumptions,
  ReportCostScenarioBreakdown,
  ReportCostText,
  ReportMethodologyTexts,
  ReportPrint,
  ReportSection,
  ReportTable,
  SimpleList,
  TurbineSvg,
  WindFarmTotalMaterialBreakdown
} from '@/components/helpers'
import { ReportPDF } from '@/components/pdf'
import { MapBoxPin, MapBoxWrapper } from '@/components/maps'
import { PlantWeightsViewModel, ProjectType } from '@/interfaces'
import { ScrollToElement } from '@/components/helpers/ScrollToEmelent'

const { currentDate, formatTonnes, isValidGuid } = helper
const { appName, icons, urls, staticAssetsUrlBlob } = config
const leftColumnWidth = '320px'

const ReportPage = () => {
  const { id } = useParams()
  const {
    isLoading,
    isNotFound: isScenarioNotFound,
    selectedProject,
    turbineItem,
    turbineMaterialBreakdown,
    scenario
  } = useScenario()

  const {
    scenarioCostCalcationConfiguration,
    getScenarioCostCalcationConfiguration
  } = useScenarioCostCalcationConfiguration()

  const scenarioUrl = isValidGuid(id) ? `${urls.scenarios}/${id}` : null

  const {
    allSelected,
    sectionNames,
    printSections,
    setPrintSections,
    clearAll,
    toggle,
    selectAll,
    isPdfReady
  } = useReportPrintSections(scenario?.id)

  const { generalSiteDetails, typeSpecificDetails, cableDetails } =
    useReportDetails(selectedProject, scenario)

  const {
    metalProcessingProvider,
    bladeProcessingProvider,
    concreteProcessingProvider
  } = useScenarioProviders(scenario)

  const { scenarioCircularityRate, getScenarioCircularityRateData } =
    useCircularityRateData()

  const {
    costScenarioBreakdownTableItems,
    costScenarioBreakdownChartData,
    getCostScenarioBreakdown,
    costScenarioBreakdownSummaryData
  } = useCostScenarioBreakdown(false, scenario?.includeScrapValue)

  const { turbineDetails } = useReportTurbineDetails(
    turbineItem,
    turbineMaterialBreakdown
  )

  const {
    isDelayed: isCaptureImagesDelayed,
    projectSiteSrc,
    matrialCompositionSrc,
    supplyChainMapSrc,
    calcBreakdownSrc
  } = useReportCaptureImages()

  const texts = useTexts()

  useEffect(() => {
    if (scenario?.id) {
      getScenarioCircularityRateData(scenario.id)
      getScenarioCostCalcationConfiguration(scenario.id)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scenario])

  useEffect(() => {
    if (scenario?.id && selectedProject?.projectType !== undefined)
      getCostScenarioBreakdown(scenario?.id, selectedProject?.projectType)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject])

  const [decommissioningScope] = useDecommissioningScope(
    selectedProject,
    scenario
  )
  const introductionRef = useRef<HTMLDivElement>(null)
  const projectSiteRef = useRef<HTMLDivElement>(null)
  const materialCompositionRef = useRef<HTMLDivElement>(null)
  const balanceOfPlantRef = useRef<HTMLDivElement>(null)
  const circularityRef = useRef<HTMLDivElement>(null)
  const supplyChainMapRef = useRef<HTMLDivElement>(null)
  const windFarmCircularityRef = useRef<HTMLDivElement>(null)
  const decommissioningCostsRef = useRef<HTMLDivElement>(null)

  return (
    <Page
      className="print-report-text"
      actions={
        !isScenarioNotFound &&
        !isLoading && (
          <>
            {!!scenarioUrl && (
              <Link to={scenarioUrl}>
                <OButton variant="flat" iconClass={icons.scenario}>
                  Open scenario
                </OButton>
              </Link>
            )}
            {!!scenario?.id && !!selectedProject && (
              <>
                <ReportPrint
                  title="Export to PDF"
                  titleText="Export report to PDF"
                  iconClass={icons.pdf}
                  onChange={data => setPrintSections(data || [])}
                  printSections={printSections}
                  sectionNames={sectionNames}
                  allSelected={allSelected}
                  clearAll={clearAll}
                  selectAll={selectAll}
                  toggle={toggle}
                  footerSlot={
                    <div className="report">
                      <SpinIndicator />
                      <PDFDownloadLink
                        document={
                          <ReportPDF
                            project={selectedProject}
                            scenario={scenario}
                            texts={texts}
                            turbineDetails={turbineDetails}
                            turbineMaterialBreakdown={turbineMaterialBreakdown}
                            turbineRatedPower={turbineItem?.ratedPower}
                            scenarioCircularityRate={scenarioCircularityRate}
                            windfarmMaterialBreakdown={
                              scenarioCircularityRate?.totalWindFarmWeights
                            }
                            metalProcessingProvider={metalProcessingProvider}
                            bladeProcessingProvider={bladeProcessingProvider}
                            concreteProcessingProvider={
                              concreteProcessingProvider
                            }
                            decommissioningScope={decommissioningScope}
                            scenarioCostCalcationConfiguration={
                              scenarioCostCalcationConfiguration
                            }
                            costScenarioBreakdownTableItems={
                              costScenarioBreakdownTableItems
                            }
                            costScenarioBreakdownSummaryData={
                              costScenarioBreakdownSummaryData
                            }
                            printSections={printSections}
                            projectSiteSrc={projectSiteSrc}
                            matrialCompositionSrc={matrialCompositionSrc}
                            supplyChainMapSrc={supplyChainMapSrc}
                            calcBreakdownSrc={calcBreakdownSrc}
                          />
                        }
                        fileName={`${texts.report.title} - ${scenario?.name}.pdf`}>
                        {i => {
                          return (
                            !i.loading &&
                            !isCaptureImagesDelayed &&
                            isPdfReady && (
                              <OButton className="mr-1" iconClass={icons.pdf}>
                                Download PDF
                              </OButton>
                            )
                          )
                        }}
                      </PDFDownloadLink>
                    </div>
                  }
                />
              </>
            )}
          </>
        )
      }>
      <ORow>
        {isLoading ? (
          <OCol md="12">
            <SpinIndicator />
          </OCol>
        ) : isScenarioNotFound ? (
          <PageNotFound />
        ) : (
          <OCol md="12">
            <ContentSection>
              <div
                className="home-hero home-hero-report fg-primary-dark report-top-row pt-3 mb-3"
                style={{
                  display: 'flex',
                  backgroundImage: `url('${staticAssetsUrlBlob}/images/rewind-home.png')`
                }}>
                <OCol md="6">
                  <div
                    className="d-flex align-items-center pl-3"
                    style={{ minHeight: 260 }}>
                    <div className="p-3">
                      <h2>{texts?.report.title}</h2>
                      <div>
                        <OLogo className="logo logo-sm d-inline-block" />
                        <h3 className="dnv-display-regular d-inline-block ml-2">
                          {appName}
                        </h3>
                      </div>
                    </div>
                  </div>
                </OCol>
                <OCol md="6">
                  <div className="d-flex align-items-center">
                    <div className="pl-4">
                      <div>&nbsp;</div>
                      <InputValue
                        label="Project"
                        value={selectedProject?.name || '  '}
                      />
                      <InputValue
                        label="Customer"
                        value={selectedProject?.companyName || '  '}
                      />
                      <InputValue
                        label="Report generated"
                        value={currentDate}
                      />
                    </div>
                  </div>
                </OCol>
              </div>
              <ORow className="no-print">
                <OCol md="6">
                  <ul>
                    <li>
                      <ScrollToElement elementRef={introductionRef}>
                        Introduction
                      </ScrollToElement>
                    </li>
                    <li>
                      <ScrollToElement elementRef={projectSiteRef}>
                        Project Site
                      </ScrollToElement>
                    </li>
                    <li>
                      <ScrollToElement elementRef={materialCompositionRef}>
                        Material composition
                      </ScrollToElement>
                    </li>
                    <li>
                      <ScrollToElement elementRef={balanceOfPlantRef}>
                        Balance of plant
                      </ScrollToElement>
                    </li>
                  </ul>
                </OCol>
                <OCol md="6">
                  <ul>
                    <li>
                      <ScrollToElement elementRef={circularityRef}>
                        Circularity
                      </ScrollToElement>
                    </li>
                    <li>
                      <ScrollToElement elementRef={supplyChainMapRef}>
                        Supply chain map
                      </ScrollToElement>
                    </li>
                    <li>
                      <ScrollToElement elementRef={windFarmCircularityRef}>
                        Wind farm circularity
                      </ScrollToElement>
                    </li>
                    <li>
                      <ScrollToElement elementRef={decommissioningCostsRef}>
                        Decommissioning costs
                      </ScrollToElement>
                    </li>
                  </ul>
                </OCol>
              </ORow>
              <ORow className="flex-column">
                <OCol md="12">
                  <h2 id="introduction" ref={introductionRef}>
                    Introduction
                  </h2>
                  <p>
                    “{selectedProject?.companyName}” (the “Customer”) has used
                    DNV's ReWind software to generate this decommissioning cost
                    and circularity report. The Customer has used ReWind model
                    the material breakdown, circularity and decommissioning
                    costs the “{selectedProject?.name}” Wind Farm (the
                    “Project”).
                  </p>
                  {texts?.report.intro.map((i: any, key: number) =>
                    typeof i === 'string' ? (
                      <p key={i}>{i}</p>
                    ) : (
                      <SimpleList key={key} items={i as string[]} />
                    )
                  )}
                  <p>{texts?.report.intro.top}</p>
                  <SimpleList items={texts?.report.intro.items} />
                  <p>{texts?.report.intro.bottom}</p>
                </OCol>
              </ORow>
              <PrintPageBreak />
              <ORow className="flex-column">
                <OCol md="12" className="mt-3">
                  <h2 id="project-site" ref={projectSiteRef}>
                    Project site
                  </h2>
                  <p>{texts?.report.projectSite.intro}</p>
                </OCol>
                <OCol md="12" className="mt-3">
                  <ReportSection titleLeft="Site details" id="siteDetails">
                    <ORow>
                      <OCol md="12">
                        <ReportTable
                          items={(generalSiteDetails as any[]) || []}
                          leftColumnWidth={leftColumnWidth}
                          className="border-bottom"
                        />
                      </OCol>
                    </ORow>
                    <ORow>
                      <OCol md="6" className="pr-0">
                        <ReportTable
                          items={[
                            ...((typeSpecificDetails as any)?.[
                              selectedProject?.projectType
                            ] || []),
                            ...cableDetails
                          ]}
                          leftColumnWidth={leftColumnWidth}
                          className="border-right"
                        />
                      </OCol>
                      <OCol md="6" className="pl-0 pr-3">
                        {!!selectedProject?.location?.latitude && (
                          <MapBoxWrapper
                            id="projectSiteSrc"
                            latitude={selectedProject?.location?.latitude}
                            longitude={selectedProject?.location?.longitude}
                            zoom={6}
                            height="100%">
                            <MapBoxPin
                              draggable={false}
                              latitude={selectedProject?.location?.latitude}
                              longitude={selectedProject?.location?.longitude}
                              displayPopup>
                              <div className="p-2 text-center">
                                <div>
                                  <strong>Project</strong>
                                </div>
                                <div>{selectedProject?.name}</div>
                              </div>
                            </MapBoxPin>
                          </MapBoxWrapper>
                        )}
                      </OCol>
                    </ORow>
                  </ReportSection>
                </OCol>
              </ORow>
              <ORow className="flex-column">
                <PrintPageBreak />
                <OCol md="12" className="mb-3">
                  <span id="material-composition" ref={materialCompositionRef}>
                    &nbsp;
                  </span>
                  <h2>Material composition</h2>
                  <p>{texts?.report.materialComposition.top}</p>
                  <p>{texts?.report.materialComposition.bottom}</p>
                </OCol>
                <OCol md="12" className="mb-4">
                  <ReportSection
                    titleLeft="Component weights"
                    titleRight={selectedProject?.turbineName}>
                    <div className="mt-4 ml-3 mb-4 d-flex">
                      <TurbineSvg
                        blades={formatTonnes(
                          turbineMaterialBreakdown?.blades?.total
                        )}
                        nacelle={formatTonnes(
                          turbineMaterialBreakdown?.nacelle?.total
                        )}
                        hub={formatTonnes(turbineMaterialBreakdown?.hub?.total)}
                        tower={formatTonnes(
                          turbineMaterialBreakdown?.tower?.total
                        )}
                      />
                    </div>
                  </ReportSection>
                </OCol>
                <div className="mb-2">&nbsp;</div>
                <PrintPageBreak />
                <div className="mt-1">&nbsp;</div>
                <OCol md="12">
                  <ReportSection
                    id="turbineDetails"
                    titleLeft="Turbine details"
                    titleRight={selectedProject?.turbineName}>
                    <ReportTable
                      leftColumnWidth={leftColumnWidth}
                      isPrintTextSmaller
                      items={turbineDetails}
                      total={{
                        text: 'Total turbine',
                        value: formatTonnes(
                          turbineMaterialBreakdown?.total?.total
                        )
                      }}
                    />
                  </ReportSection>
                </OCol>
              </ORow>
              <ORow className="flex-column">
                <OCol md="12">
                  <div className="mb-2">&nbsp;</div>
                  <PrintPageBreak />
                  <div className="mt-1">&nbsp;</div>
                </OCol>
                <OCol md="12">
                  <ReportSection
                    titleLeft="Material composition"
                    titleRight={selectedProject?.turbineName}>
                    <MaterialComposition data={turbineMaterialBreakdown} />
                  </ReportSection>
                </OCol>
                <OCol md="12">
                  <PrintPageBreak />
                </OCol>
              </ORow>
              <ORow className="flex-column">
                <OCol md="12" className="mb-3">
                  <span id="balance-of-plant" ref={balanceOfPlantRef}>
                    &nbsp;
                  </span>
                  <ReportBalanceOfPlant
                    projectType={selectedProject?.projectType}
                  />
                </OCol>
                <OCol md="12">
                  <ReportSection titleLeft="Balance of plant">
                    <BalanceOfPlant
                      data={
                        scenarioCircularityRate as unknown as PlantWeightsViewModel
                      }
                      project={selectedProject}
                    />
                  </ReportSection>
                  <p className="mt-3 mb-4">
                    {texts?.report.balanceOfPlant.bottom}
                  </p>
                </OCol>
                <OCol className="md-12">
                  <div className="mb-2">&nbsp;</div>
                  <PrintPageBreak />
                  <div className="mt-1">&nbsp;</div>
                </OCol>
                <OCol md="12">
                  <ReportSection titleLeft="Wind farm total material breakdown ">
                    <WindFarmTotalMaterialBreakdown
                      height={400}
                      width={400}
                      data={scenarioCircularityRate?.totalWindFarmWeights}
                    />
                  </ReportSection>
                  <p className="mt-3">{texts?.report.totalWindFarm}</p>
                </OCol>
                <OCol md="12">
                  <PrintPageBreak />
                </OCol>
              </ORow>
              <ORow className="flex-column">
                <OCol md="12">
                  <span id="circularity" ref={circularityRef}>
                    &nbsp;
                  </span>
                  <h2>Circularity</h2>
                </OCol>
                <OCol md="12">
                  <h3>Material Processing</h3>
                </OCol>
                <OCol md="12">
                  <div className="mb-3">
                    <p>{texts?.report.materialProcessing.intro}</p>
                  </div>
                  <ProviderInfoSection
                    providerInfo={metalProcessingProvider}
                    leftColumnWidth={leftColumnWidth}
                  />
                  <div className="mb-2">&nbsp;</div>
                  <PrintPageBreak />
                  <div className="mt-1">&nbsp;</div>
                  <ProviderInfoSection
                    providerInfo={bladeProcessingProvider}
                    leftColumnWidth={leftColumnWidth}
                  />
                  <div className="mb-2">&nbsp;</div>
                  <PrintPageBreak />
                  <div className="mt-1">&nbsp;</div>
                  <ProviderInfoSection
                    providerInfo={concreteProcessingProvider}
                    leftColumnWidth={leftColumnWidth}
                  />
                </OCol>
                <div className="mb-2">&nbsp;</div>
                <PrintPageBreak />
              </ORow>
              <ORow className="flex-column">
                <div className="mt-1">&nbsp;</div>
                <OCol md="12">
                  <span id="supply-chain-map" ref={supplyChainMapRef}>
                    &nbsp;
                  </span>
                  <h2>Supply chain map</h2>
                </OCol>
                <OCol md="12">
                  <div className="mb-3">
                    <p>{texts?.report.supplyChainMap.intro}</p>
                  </div>
                  <ReportSection
                    icon={icons.providersMap}
                    titleLeft="Supply chain map">
                    <ProjectProvidersMap
                      selectedProject={selectedProject}
                      metalProcessingProvider={
                        metalProcessingProvider?.provider
                      }
                      bladeProcessingProvider={
                        bladeProcessingProvider?.provider
                      }
                      concreteProcessingProvider={
                        concreteProcessingProvider?.provider
                      }
                    />
                  </ReportSection>
                </OCol>
                <OCol md="12">
                  <PrintPageBreak />
                </OCol>
              </ORow>
              <ORow className="flex-column">
                <OCol md="12">
                  <span id="wind-farm-circularity" ref={windFarmCircularityRef}>
                    &nbsp;
                  </span>
                  <h2>Wind farm circularity</h2>
                </OCol>
                <OCol md="12">
                  {texts?.report.windFarmCircularity.map(
                    (i: any, key: number) =>
                      typeof i === 'string' ? (
                        <p key={i}>{i}</p>
                      ) : (
                        <SimpleList key={key} items={i as string[]} />
                      )
                  )}
                  <p className="text-center">
                    <img
                      src={`${staticAssetsUrlBlob}/images/circularity-formula.png`}
                      alt="Circularity"
                      title="Circularity"
                    />
                  </p>
                  <p>Where:</p>
                  <SimpleList items={texts?.report.circularityFormula.items} />
                  <p>
                    {texts?.report.circularityFormula.link}
                    <a
                      className="ml-1"
                      title="LCA of Electricity Production from an onshore EnVentus V162-6.2.pdf.coredownload.inline.pdf (vestas.com)"
                      href="https://www.vestas.com/content/dam/vestas-com/global/en/sustainability/reports-and-ratings/lcas/LCA%20of%20Electricity%20Production%20from%20an%20onshore%20EnVentus%20V162-6.2.pdf.coredownload.inline.pdf"
                      target="_blank"
                      rel="noopener noreferrer">
                      [1]
                    </a>
                    &nbsp;
                    <a
                      title="2015 Cost of Wind Energy Review (nrel.gov)"
                      href="https://www.nrel.gov/docs/fy17osti/66861.pdf"
                      target="_blank"
                      rel="noopener noreferrer">
                      [2]
                    </a>
                    .
                  </p>
                  <p>{texts?.report.circularityFormula.bottom}</p>
                </OCol>
                <OCol md="12">
                  <PrintPageBreak />
                </OCol>
                <OCol md="12" className="mt-3">
                  <ReportSection titleLeft="Circularity">
                    <CircularityTable
                      data={scenarioCircularityRate}
                      project={selectedProject}
                    />
                  </ReportSection>
                </OCol>
                <OCol md="12">
                  <PrintPageBreak />
                </OCol>
              </ORow>
              <ORow className="flex-column">
                <OCol md="12">
                  <span
                    id="decommissioning-costs"
                    ref={decommissioningCostsRef}>
                    &nbsp;
                  </span>
                  <h2>Decommissioning costs</h2>
                  {selectedProject?.projectType === ProjectType.Onshore && (
                    <p>{texts?.report.decommissioningCostsIntro.Onshore}</p>
                  )}
                </OCol>
                <OCol md="12">
                  <ReportCostText
                    projectType={selectedProject?.projectType}
                    includeScrap={scenario?.includeScrapValue}
                  />
                </OCol>
                <OCol md="12" className="mt-3">
                  <ReportSection titleLeft="Decommissioning scope">
                    <ReportTable items={decommissioningScope} />
                  </ReportSection>
                </OCol>
                <OCol md="12" className="mt-2">
                  <ReportCostAssumptions
                    includeScrap={scenario?.includeScrapValue}
                    scenarioCostCalcationConfiguration={
                      scenarioCostCalcationConfiguration
                    }
                    projectType={selectedProject?.projectType}
                  />
                </OCol>
                <OCol md="12" className="mt-2">
                  <ReportMethodologyTexts
                    projectType={selectedProject?.projectType}
                    currency={scenario?.currency}
                  />
                </OCol>
                <OCol md="12">
                  <h3>Results</h3>
                  {texts?.report.costCalculationResults.map((i: string) => (
                    <p key={i}>{i}</p>
                  ))}
                </OCol>
                <OCol md="12" className="mt-2">
                  <ReportCostScenarioBreakdown
                    projectName={selectedProject?.name}
                    projectType={selectedProject?.projectType}
                    numberOfTurbines={selectedProject?.numberOfTurbines}
                    turbineRatedPower={turbineItem?.ratedPower}
                    turbineName={turbineItem?.name}
                    costScenarioBreakdownTableItems={
                      costScenarioBreakdownTableItems
                    }
                    costScenarioBreakdownChartData={
                      costScenarioBreakdownChartData
                    }
                    scenarioCurrency={scenario?.currency.currencyInformation}
                  />
                </OCol>
                <OCol md="12">
                  <PrintPageBreak />
                </OCol>
              </ORow>
              <ORow>
                <OCol md="12">
                  <h2>Important notice and disclaimer</h2>
                  <SimpleList items={texts?.report.disclaimer} />
                </OCol>
                <OCol md="12" className="my-5 text-center no-print">
                  <a href="#top-of-the-page">
                    <OButton iconClass="fa-arrow-up">
                      {texts?.report.goto}
                    </OButton>
                  </a>
                </OCol>
              </ORow>
            </ContentSection>
          </OCol>
        )}
      </ORow>
    </Page>
  )
}

export default React.memo(ReportPage)
