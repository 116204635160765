import React from 'react'
import { helper } from '@/utils'
import { PrintPageBreak } from '../print'
import { CurrencyViewModel, ProjectType } from '@/interfaces'
import { useTexts } from '@/hooks'

interface Props {
  projectType: ProjectType
  currency?: CurrencyViewModel
}

const { formatCurrencyMask } = helper

const ReportMethodologyTexts = ({ projectType, currency }: Props) => {
  const texts = useTexts()
  // Supporting only Onshore for now
  return projectType === ProjectType.Onshore ? (
    <>
      <h3>Mobilisation and Soft costs</h3>
      {texts?.report.mobilisationAndSoftCosts.Onshore.map((i: any) => (
        <p key={i}>{i}</p>
      ))}
      <h3>Turbine Disassembly and Removal costs</h3>
      {texts?.report.turbineDisassemblyAndRemovalCosts.Onshore.map((i: any) => (
        <p key={i}>{i}</p>
      ))}
      <h3>Turbine Foundations Removal cost</h3>
      {texts?.report.turbineFoundationsRemovalCost.Onshore.map((i: any) => (
        <p key={i}>{i}</p>
      ))}
      <h3>Crane Pad Removal</h3>
      {texts?.report.cranePadRemoval.Onshore.map((i: any) => (
        <p key={i}>{i}</p>
      ))}
      <h3>Removal of cables</h3>
      {texts?.report.removalOfCables.Onshore.map((i: any) => (
        <p key={i}>{i}</p>
      ))}
      <h3>Access road removal</h3>
      {texts?.report.accessRoadRemoval.Onshore.map((i: any) => (
        <p key={i}>{i}</p>
      ))}
      <h3>Reinstatement costs</h3>
      {texts?.report.reinstatementCosts.Onshore.map((i: any) => (
        <p key={i}>{i}</p>
      ))}
      <h3>Removal of other onsite structures</h3>
      {texts?.report.removalOfOtherOnsiteStructures.Onshore.map((i: any) => (
        <p key={i}>
          {i.replace(
            texts?.report.removalOfOtherOnsiteStructures.FixedCost,
            `${formatCurrencyMask(currency?.exchangeRateToDefaultCurrency! * 11450, currency?.currencyInformation!)} + ${formatCurrencyMask(currency?.exchangeRateToDefaultCurrency! * 600, currency?.currencyInformation!)}`
          )}
        </p>
      ))}
      <PrintPageBreak />
    </>
  ) : null
}
export default React.memo(ReportMethodologyTexts)
