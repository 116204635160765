import React, { ReactNode } from 'react'
import { ORow, OCol, OToggleSwitch } from '@dnvgl-onefoundation/onedesign-react'
import { ContentSection } from '../layout'
import { helper } from '@/utils'
import {
  DecommissionOffshoreFloatingScopeViewModel,
  ProjectItemViewModel,
  ScenarioItemViewModel
} from '@/interfaces'
import { useCostScenarioBreakdown, useScenarioState } from '@/hooks'

interface Props {
  scenario?: ScenarioItemViewModel | null
  children?: ReactNode
  onScopeUpdate?: (name: string, value?: boolean) => void
  project: ProjectItemViewModel | null
}

const { getCheckedText } = helper

const DefineScopeOffshoreFloating = ({
  scenario,
  children,
  onScopeUpdate,
  project
}: Props) => {
  const data =
    scenario?.decommissionScope as DecommissionOffshoreFloatingScopeViewModel
  const existingSubstation = project?.hasOffshoreSubstation
  const { isUpdating } = useScenarioState()
  const { isCalculationLoading } = useCostScenarioBreakdown(
    false,
    scenario?.includeScrapValue
  )
  return (
    <ORow>
      <OCol md={children ? '6' : '12'}>
        <ContentSection title="Decommissioning scope">
          <div className="p-2">
            <label>Floater to be removed?</label>
            <OToggleSwitch
              className="pointer mb-3"
              textLocation="hidden"
              checked={data?.floaterToBeRemoved}
              disabled={isUpdating || isCalculationLoading}
              onChange={() =>
                onScopeUpdate?.('floaterToBeRemoved', !data?.floaterToBeRemoved)
              }>
              {getCheckedText(!!data?.floaterToBeRemoved)}
            </OToggleSwitch>
            <label>Mooring lines to be removed?</label>
            <OToggleSwitch
              className="pointer mb-3"
              textLocation="hidden"
              checked={data?.mooringLinesToBeRemoved}
              disabled={isUpdating || isCalculationLoading}
              onChange={() =>
                onScopeUpdate?.(
                  'mooringLinesToBeRemoved',
                  !data?.mooringLinesToBeRemoved
                )
              }>
              {getCheckedText(!!data?.mooringLinesToBeRemoved)}
            </OToggleSwitch>
            <div className="border-bottom mb-3" />
            <label>Sub sea array cables to be removed?</label>
            <OToggleSwitch
              className="pointer mb-3"
              textLocation="hidden"
              checked={data?.subSeaArrayCablesToBeRemoved}
              disabled={isUpdating || isCalculationLoading}
              onChange={() =>
                onScopeUpdate?.(
                  'subSeaArrayCablesToBeRemoved',
                  !data?.subSeaArrayCablesToBeRemoved
                )
              }>
              {getCheckedText(!!data?.subSeaArrayCablesToBeRemoved)}
            </OToggleSwitch>
            <label>Export cables to be removed?</label>
            <OToggleSwitch
              className="pointer mb-3"
              textLocation="hidden"
              checked={data?.exportCablesToBeRemoved}
              disabled={isUpdating || isCalculationLoading}
              onChange={() =>
                onScopeUpdate?.(
                  'exportCablesToBeRemoved',
                  !data?.exportCablesToBeRemoved
                )
              }>
              {getCheckedText(!!data?.exportCablesToBeRemoved)}
            </OToggleSwitch>
            <label>Cable crossing protection to be removed?</label>
            <OToggleSwitch
              className="pointer mb-3"
              textLocation="hidden"
              checked={data?.cableCrossingProtectionToBeRemoved}
              disabled={isUpdating || isCalculationLoading}
              onChange={() =>
                onScopeUpdate?.(
                  'cableCrossingProtectionToBeRemoved',
                  !data?.cableCrossingProtectionToBeRemoved
                )
              }>
              {getCheckedText(!!data?.cableCrossingProtectionToBeRemoved)}
            </OToggleSwitch>
            <label>Offshore substation to be removed?</label>
            <OToggleSwitch
              className="pointer mb-3"
              textLocation="hidden"
              checked={
                existingSubstation ? data?.offshoreSubstationToBeRemoved : false
              }
              onChange={() =>
                onScopeUpdate?.(
                  'offshoreSubstationToBeRemoved',
                  !data?.offshoreSubstationToBeRemoved
                )
              }
              disabled={
                isUpdating || !existingSubstation || isCalculationLoading
              }>
              {getCheckedText(
                existingSubstation
                  ? !!data?.offshoreSubstationToBeRemoved
                  : false
              )}
            </OToggleSwitch>
          </div>
        </ContentSection>
      </OCol>
      {!!children && <OCol md="6">{children}</OCol>}
    </ORow>
  )
}

export default React.memo(DefineScopeOffshoreFloating)
