import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FacilityTypeIndicator } from '@/components/helpers'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { createProvider, getProviders } from '@/store/slices/providers'
import { config, enumFlags, helper, providers } from '@/utils'
import {
  CreateProviderViewModel,
  ProjectType,
  ProviderItemViewModel,
  ProviderMaterial,
  ProviderService,
  ProviderStatus,
  ProviderType
} from '@/interfaces'
import useCountries from './useCountries'
import useLocalStorage from './useLocalStorage'
import { UnknownAction } from '@reduxjs/toolkit'

const { iconCodes, pinColors, facilityTypeDisplayNames } = providers

const filterKeys = ['name', 'countryName']

const { icons } = config
const { deepClone, genericErrorHandler, isSuperAdministrator, isValidGuid } =
  helper

const {
  providerTypeNames,
  providerMaterialsEnumNames,
  providerServiceNames,
  getEnumFlagValues
} = enumFlags

const toggleValue = (collection: any[], value?: any) =>
  collection.includes(value)
    ? collection?.filter(i => i !== value)
    : [...collection, value]

const useProviders = (isScenario?: boolean) => {
  const state = useAppSelector(s => s)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { countryOptions, defaultCountry, setDefaultCountry } = useCountries()

  const [query, setQuery] = useState<string>('')
  const [filteredItems, setFilteredItems] = useState<any[]>([])
  const onSearchChange = (value: string) => setQuery(value)
  const onFilterToggle = (
    section: 'type' | 'materials' | 'services',
    value: any
  ) => {
    if (section === 'type')
      setSelectedTypes(toggleValue(selectedTypes as any[], value))
    if (section === 'materials')
      setSelectedMaterials(toggleValue(selectedMatrials as any[], value))
    if (section === 'services')
      setSelectedServices(toggleValue(selectedServices as any[], value))
  }

  const onClearSelection = (section: 'type' | 'materials' | 'services') => {
    if (section === 'type') setSelectedTypes([])
    if (section === 'materials') setSelectedMaterials([])
    if (section === 'services') setSelectedServices([])
  }

  const [selectedTypes, setSelectedTypes] = useLocalStorage<ProviderType[]>(
    'useProviders-selectedTypes',
    []
  )
  const [selectedMatrials, setSelectedMaterials] = useLocalStorage<
    ProviderMaterial[]
  >('useProviders-selectedMatrials', [])
  const [selectedServices, setSelectedServices] = useLocalStorage<
    ProviderService[]
  >('useProviders-selectedServices', [])

  const create = (data: any) => {
    let providerTypes = 0

    Object.keys(providerTypeNames).forEach(key => {
      const name = (providerTypeNames as any)?.[key]
      const value = parseInt(key)
      if (typeof value === 'number' && `${(data as any)?.[name]}` === 'true')
        providerTypes = providerTypes + value
    })

    const payload: CreateProviderViewModel = {
      name: data.name,
      countryId: data.countryId,
      providerTypes,
      services: ProviderService.None,
      materials: ProviderMaterial.None,
      status: ProviderStatus.Operating,
      websiteAddress: data.websiteAddress,
      isDraft: true
    }

    dispatch(createProvider(payload))
      ?.then((id?: string | void) => isValidGuid(id) && navigate(`./${id}`))
      .catch(genericErrorHandler)
  }

  const { isLoading, providers } = state.providers
  const { currentUser } = state.users
  const { scenario } = state.scenarios

  useEffect(
    () => {
      const updatedItems = updateItems(providers?.items || [])
      let items = filterItems(query, updatedItems)

      items = items
        ?.filter?.((i: any) => {
          if (!selectedTypes?.length) return true
          let found = false
          i.providerTypesValues.forEach(
            (i: any) => (found = selectedTypes?.includes(i) || found)
          )
          return found
        })
        ?.filter?.((i: any) => {
          if (!selectedMatrials?.length) return true
          let found = false
          i.materialsValues.forEach(
            (i: any) => (found = selectedMatrials?.includes(i) || found)
          )
          return found
        })
        ?.filter?.((i: any) => {
          if (!selectedServices?.length) return true
          let found = false
          i.servicesValues.forEach(
            (i: any) => (found = selectedServices?.includes(i) || found)
          )
          return found
        })
        ?.filter?.((i: any) => {
          return !(
            i.providerTypesValues.includes(ProviderType.Port) &&
            scenario?.project?.projectType === ProjectType.Onshore &&
            isScenario
          )
        })

      setFilteredItems(items)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      query,
      providers,
      selectedTypes,
      selectedMatrials,
      selectedServices,
      scenario?.project?.projectType
    ]
  )

  const get = () => dispatch(getProviders() as unknown as UnknownAction)

  useEffect(() => {
    get()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const addProvider = (data: any) => data?.name?.length && create(data)

  function updateItems(items: ProviderItemViewModel[] = []) {
    if (!items?.length) return []
    const clone = deepClone(items)

    const getIconClass = (providerTypes?: ProviderType) => {
      const values = getEnumFlagValues(providerTypes)
      return values?.length > 1
        ? icons.multiple
        : (iconCodes as any)?.[values?.[0]]
    }
    const getPinColor = (providerTypes?: ProviderType) => {
      const values = getEnumFlagValues(providerTypes)
      return values?.length > 1 ? undefined : (pinColors as any)?.[values?.[0]]
    }

    return clone?.map?.((i: any) => {
      i.iconClass = getIconClass(i?.providerTypes)
      i.color = getPinColor(i?.providerTypes)
      i.providerTypesValues = getEnumFlagValues(i?.providerTypes)
      i.providerTypesIcons = i.providerTypesValues.map((i: any) => (
        <FacilityTypeIndicator key={i} type={i} className="mr-1" />
      ))
      i.providerTypesIconsTableView = i.providerTypesValues
        ?.map((i: any) => (facilityTypeDisplayNames as any)[i])
        ?.join(', ')
      i.providerTypesList = i.providerTypesValues
        .map((i: any) => (providerTypeNames as any)[i])
        .join(', ')
      i.materialsValues = getEnumFlagValues(i?.materials)
      i.materialsList = i.materialsValues
        .map((i: any) => (providerMaterialsEnumNames as any)[i])
        .join(', ')
      i.servicesValues = getEnumFlagValues(i?.services)
      i.servicesList = i.servicesValues
        .map((i: any) => (providerServiceNames as any)[i])
        .join(', ')
      return i
    })
  }

  function filterItems(
    filterValue: string,
    items: ProviderItemViewModel[] = []
  ) {
    if (!filterValue) return items
    const query = filterValue.toLocaleLowerCase()
    return items.filter(f => {
      let found = false
      filterKeys.forEach((i: any) => {
        if (`${(f as any)?.[i]}`?.toLocaleLowerCase()?.includes(query))
          found = true
      })
      return found
    })
  }

  return {
    isLoading,
    allItems: providers?.items || [],
    filteredItems,
    isEditable: isSuperAdministrator(currentUser),
    countryOptions,
    defaultCountry,
    providerTypeNames,
    selectedTypes,
    selectedMatrials,
    selectedServices,
    setDefaultCountry,
    addProvider,
    onSearchChange,
    onFilterToggle,
    onClearSelection
  }
}

export default useProviders
