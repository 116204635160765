import React, { useState } from 'react'
import { OButton } from '@dnvgl-onefoundation/onedesign-react'

interface Props {
  isDisabled?: boolean
  className?: string
  onSave?: () => void
  onSaveToDrafts?: () => void
  onSaveAndClose?: () => void
}

const SaveButton = ({
  isDisabled,
  className,
  onSave,
  onSaveToDrafts,
  onSaveAndClose
}: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)

  const hideMenu = () => setIsMenuOpen(false)

  const handleSave = () => {
    hideMenu()
    onSave?.()
  }

  const handleSaveToDrafts = () => {
    hideMenu()
    onSaveToDrafts?.()
  }

  const handleSaveAndClose = () => {
    hideMenu()
    onSaveAndClose?.()
  }

  return (
    <div className="d-inline position-relative">
      <OButton className={className} onClick={handleSave} disabled={isDisabled}>
        Save
      </OButton>
      <OButton
        className="bg-sea-blue"
        iconClass={`far fa-chevron-${isMenuOpen ? 'up' : 'down'}`}
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      />
      {isMenuOpen && (
        <div
          className="position-absolute"
          style={{ right: 0, bottom: -75, width: 170, zIndex: 10000 }}>
          <OButton
            disabled={isDisabled}
            variant="subtle"
            className="w-100"
            onClick={handleSaveAndClose}>
            Save and close
          </OButton>
          <OButton
            variant="subtle"
            className="w-100"
            onClick={handleSaveToDrafts}>
            Save to drafts
          </OButton>
        </div>
      )}
    </div>
  )
}

export default React.memo(SaveButton)
